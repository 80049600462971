import axios from "axios";

const deletePatientFile = async (baseUrl, token, patientId, fileId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let deleteResponse = null

    await axios.delete(baseUrl + `/patient/` + patientId + "/file/" + fileId, config)
        .then((res) => {
            //console.log("res.data: ", res.data)
            deleteResponse = {
                status: res.data.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                deleteResponse = {
                    status: error.response.status,
                    result:  error.response.status
                }
            }
        });
    return deleteResponse
}

export default deletePatientFile;