import React, { useState } from "react";
import { observer, inject } from "mobx-react";

import { TextField, Grid, Button } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { professionals } from "../../common/List";

export const TopSearchMenu = inject("store")(
  observer((props) => {
    const [searchTextInput, setSearchTextInput] = useState("");
    const [kategori, setKategori] = useState("doktor");

    const onChangeSearchTextInputValue = (event) => {
      setSearchTextInput(event.target.value);
    };

    const handleKategoriChange = (event) => {
      setKategori(event.target.value);
    };

    const onClickSearchButton = () => {
      props.history.push(
        "/danisan/doktor-arama?kategori=" + kategori + "&q=" + searchTextInput
      );
    };

    const onKeyPress = (e) => {
      if (e.keyCode === 13) {
        props.history.push(
          "/danisan/doktor-arama?kategori=" + kategori + "&q=" + searchTextInput
        );
      }
    };

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <TextField
            InputProps={{
              startAdornment: (
                <FormControl
                  variant="filled"
                  style={{ width: "150px", minWidth: "150px" }}
                >
                  <Select
                    style={{
                      width: "150px",
                      minWidth: "150px",
                      marginLeft: "-10px",
                      borderRadius: "0",
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={kategori}
                    onChange={handleKategoriChange}
                  >
                    {professionals.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.slug}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ),
            }}
            value={searchTextInput}
            onChange={onChangeSearchTextInputValue}
            onKeyDown={onKeyPress}
            style={{
              width: "calc(100% - 60px)",
              borderRadius: "10px",
              backgroundColor: "rgb(242, 242, 242)",
              border: "1px solid #ffab40",
            }}
            variant="outlined"
            focused
            placeholder="Adı ve soyadı bilgilerinden arama yapabilirsiniz"
          />

          <Button
            variant="contained"
            style={{
              borderRadius: "0",
              height: "58px",
              marginLeft: "-10px",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
            onClick={() => onClickSearchButton()}
          >
            Ara
          </Button>
        </Grid>
      </Grid>
    );
  })
);
