export const termsConditions = (
  <>
    WHOLEMEDICS - DANIŞAN ODAKLI E-SAĞLIK PLATFORMU VE WEB/MOBİL UYGULAMALARI
    PLATFORMU KULLANIM HÜKÜM VE KOŞULLARI v.1
    <br />
    1. TARAFLAR VE KONU
    <br />
    Bu WholeMedics - Danışan Odaklı e-Sağlık Platformu ve Web/Mobil Uygulamaları
    Yazılımı Kullanım Hüküm ve Koşulları (“Kullanım Hüküm ve Koşulları” olarak
    anılacaktır); WholeMedics Sağlık Hizmetleri ve Ticaret A.Ş. ("WHOLEMEDICS"
    olarak anılacaktır) tarafından hazırlanmıştır. Bu Kullanım Hüküm ve
    Koşulları, Saglikcim.net - Danışan Odaklı e-Sağlık Platformu ve Web/Mobil
    Uygulamaları Yazılımı‘na ("Platform” veya “Yazılım” olarak anılacaktır)
    erişiminizi düzenler ve Platform’u uzaktan sağlık hizmeti almak, randevu
    oluşturmak ve/veya Yazılım’da yer alan içeriklerden faydalanmak üzere
    kullanan gerçek kişi/ler olan (“Saglikcim.net Kullanıcısı” olarak
    anılacaktır) sizleri kapsar. Platform ve içeriklerini kullanımınız, bu
    Kullanım Hüküm ve Koşulları’na tabidir.
    <br />
    2. KABUL
    <br />
    Lütfen Platform’u kullanmaya başlamadan önce Kullanım Hüküm ve Koşullarını
    dikkatlice okuyunuz. Şayet herhangi bir koşulu kabul etmiyorsanız, lütfen
    Platform’a erişiminizi sonlandırınız. Platform’u indirmek veya kullanmakla,
    koşulsuz ve kısıtlamasız olarak, işbu Kullanım Hüküm ve Koşullarını kabul
    etmiş olursunuz.
    <br />
    Saglikcim.net, Platform üzerinden herhangi bir tıbbi tavsiye vermediğini ve
    vermeyeceğini, tıbbi tavsiye vermeyi amaçlamadığını, herhangi bir tanı,
    teşhis veya tedavi konusunda yönlendirme veya tavsiyelerde bulunmadığını
    lütfen unutmayınız.
    <br />
    3. GENEL KOŞULLAR
    <br />
    3.1. Platform’a üye olmak isteyen kişiler, Platform’u kullanmaya başlamadan
    önce ilgili Üyelik Sözleşmesi’ni onaylayarak kişisel ve diğer sair
    bilgilerini doğru olarak sunmalı ve üye olduktan sonra bu bilgileri güncel
    tutmalıdır.
    <br />
    3.2.Saglikcim.net Kullanıcısı; başkası adına üye olamaz, başkasına ait
    herhangi bir veriyi önceden yazılı izin almaksızın Platform ile paylaşamaz,
    hukuka aykırı hiçbir veriyi hiçbir surette Platform ile paylaşamaz.
    <br />
    3.3.Saglikcim.net Kullanıcısı, Platform’a erişim sağlarken kullandığı
    şifresini bizzat kendisi kullanmalıdır, başka kişi ya da kuruluşlara
    veremez.
    <br />
    3.4. Saglikcim.net Kullanıcısı, Platform’da argo, hakaret içeren, ırkçı,
    ayrımcı, saldırgan, pornografik, tacizkar, şiddet içerikli, herhangi bir
    ürün veya hizmete ilişkin reklam ve tanıtım içerikli ve hukuka aykırı
    ifadeler kullanamaz.
    <br />
    3.5. Platform hiçbir şekilde ve koşulda kamu düzenini bozucu, genel ahlaka
    aykırı, başkalarını rahatsız ve taciz edici şekilde, yasalara aykırı bir
    amaç için, başkalarının fikri ve telif haklarına tecavüz edecek şekilde
    kullanılamaz ve başkalarının Platform’u kullanmasını önleyici veya
    zorlaştırıcı faaliyet davranışlarda bulunulamaz.
    <br />
    3.6.Saglikcim.net Kullanıcısı tarafından beyan edilen, yazılan, yüklenen,
    kullanılan düşünce ve içerikler tamamen kendi kişisel görüşleridir ve
    yalnızca ilgili kişiyi bağlamaktadır. Bu nedenle üçüncü kişilerin
    uğrayabileceği zararlardan dolayı WHOLEMEDICS’in herhangi bir sorumluluğu
    bulunmamaktadır.
    <br />
    3.7.Saglikcim.net Kullanıcısı, diğer kişilerin kendilerine açık olmayan
    verilerine ve bilgilerine izinsiz olarak ulaşamaz ve bunları kullanamaz.
    Aksi takdirde, bundan doğacak hukuki ve cezai sorumluluklar tamamen ve
    münhasıran ilgili Saglikcim.net Kullancısı’na aittir.
    <br />
    3.8. WHOLEMEDICS, karar tamamen kendisine ait olmak üzere Platform ile
    ilgili yazılım, yazılım güncellemeleri, hizmet sürümleri veya bakım
    hizmetleri için herhangi bir zamanda haber vermeden ve verilere herhangi bir
    erişim veya kullanım kaybı için olanlar sınırlama olmaksızın dahil olmak
    üzere sorumluluğu bulunmadan reddetme, teklif etme, sunma, kesme, askıya
    alma veya geri çekme hakkını saklı tutar.
    <br />
    3.9. Platform’u ziyaret ederken ve/veya Platform’a üye olurken gerçek dışı
    ve/veya hatalı bilgiler verilmesi, haksız yere Platform’u kötüleyecek
    beyanlarda bulunulması, Platform’un gerçek dışı beyanlarla ve/veya olmayan
    özelliklerle herhangi bir mecrada paylaşılması, Platform’un veya
    içeriklerinin değiştirilmesi, kısmen veya tamamen çoğaltılması, sunulması,
    saklanması, herhangi bir mecrada izinsiz paylaşılması veya yayınlanması ve
    herhangi bir şekilde ticari amaçlarla kullanılması kesinlikle yasaktır.
    <br />
    3.10. Platform’un hukuka aykırı, amacı dışında, hileli veya uygunsuz bir
    amaç için kullanımı veya bunları yapması için herhangi bir üçüncü şahsa izin
    verilmesi, bu yöndeki bir fiile katılmak veya teşvik etmek, belirli
    zamanlarda revize edildiği şekliyle işbu Kullanım Hüküm ve Koşulları’nı
    ihlal edecek bir şekilde hareket etmek ya da bu eylemleri yapması için
    herhangi bir üçüncü şahsa izin vermek veya onu teşvik etmek yasaktır.
    <br />
    3.11. Saglikcim.net kullanıcısı/kullanıcılar, Platform’u kullanırken; ilgili
    sözleşme hükümlerine, Platform’un mevcut ve/veya daha sonra geçerlilik
    kazanacak diğer tüm kullanım koşullarına ve uygulanabilir geçerli yasal
    mevzuata uygun davranmalıdır, aksi halde meydana gelecek tüm zararlardan
    münhasıran sorumludur.
    <br />
    3.12. Bu Kullanım Hüküm ve Koşullarını ihlal ettiğiniz takdirde WHOLEMEDICS
    Platform’a erişiminizi engelleme hakkına sahiptir.
    <br />
    4. FİKRİ MÜLKİYET
    <br />
    4.1. Platform, münhasıran WHOLEMEDICS mülkiyetindedir. Platform’da yer alan
    bilgiler, yazılar, resimler, logolar, videolar, markalar, slogan ve diğer
    işaretler, Platform’un tasarımı, işleyişi ve sunumu, Platform’da kullanılan
    programlar, kodlar ve bunlarla sınırlı olmaksızın sınaî ve fikri mülkiyet
    haklarına konu olabilecek sair tüm nevi WHOLEMEDICS’in ve/veya
    WHOLEMEDICS’in izin veya lisans aldığı üçüncü tarafların mülkiyetindedir.
    <br />
    4.2. Platform’un veya içeriklerinin değiştirilmesi, kısmen veya tamamen
    çoğaltılması, sunulması, saklanması, herhangi bir mecrada izinsiz
    paylaşılması veya yayınlanması ve herhangi bir şekilde ticari amaçlarla
    kullanılması yasaktır.
    <br />
    4.3. Saglikcim.net kullanıcısı/kullanıcılar, Platform’da bulunan fikirlerin
    ve bunların ifadelerinin WHOLEMEDICS’in hem tescilli bilgisi hem de ticari
    sırları olduğunu kabul eder. Evital Kullanıcısı şunlara neden olmayacak veya
    izin vermeyecektir; (i) Platform’un değiştirilmesi, uyarlanması,
    dönüştürülmesi, parçalarına ayrılması, kaynak koda dönüştürme, tersine
    mühendisliği veya diğer şekillerde insan tarafından algılanabilir bir şekle
    dönüştürülmesi; (ii) Platform’un tamamen veya kısmen herhangi bir üçüncü bir
    tarafa ifşa edilmesi, kopyalanması, görüntülenmesi, ödünç verilmesi,
    yayınlanması, devri (satma, değişim, hediye, yasa gereği veya başka
    şekillerde) veya başka şekilde yayılması (lisanslı kullanıcılar olan üçüncü
    taraflar hariç); ve (iii) WHOLEMEDICS’in önceden yazılı izni olmadan
    Platform’un türev eserlerinin oluşturulması.
    <br />
    5. ÜÇÜNCÜ TARAF WEB SİTESİ VE İÇERİĞİ
    <br />
    Platform’da üçüncü kişilere ait başka internet sitelerine ("Üçüncü Taraf Web
    Siteleri") link verilebilir veya üçüncü taraflara ait olan veya bunlardan
    kaynaklanan diğer içerik veya öğeler yer alabilir ("Üçüncü Taraf İçeriği").
    WHOLEMEDICS link verilen internet sitelerinde yer alan içerikten, Üçüncü
    Taraf Web Siteleri’nden ve Üçüncü Taraf İçeriği’nden sorumlu değildir ve
    bunların doğruluğunu, kalitesini yahut güncelliğini kontrol etme yükümlülüğü
    bulunmamaktadır. Saglikcim.net Kullanıcısı, bu site ve içeriklere olası
    risklerini kendileri üstlenmek suretiyle erişir.
    <br />
    6. GARANTİ REDDİ VE SORUMLULUĞUN SINIRLANDIRILMASI
    <br />
    6.1. WHOLEMEDICS, Platform’da bulunan bilgilerin doğruluğu, geçerliliği ve
    uygunluğu ile ilgili olarak herhangi bir garanti ya da taahhüt vermez.
    Platform ve içeriği "olduğu gibi" sağlanmaktadır, teknik hatalar ya da başka
    aksaklıklar veya eksikler söz konusu olabilir.
    <br />
    6.2. Hiçbir durumda, WHOLEMEDICS ya da ilgili yöneticileri, çalışanları ya
    da aracıları, yaralanma, kar kaybı, veri kaybı ya da gecikme, hizmet
    kesintisi, virüsler, dosyaların ya da elektronik iletişimlerin silinmesi,
    Platform içeriğindeki ihmaller ya da diğer hatalar da dahil olmak ve sadece
    bunlarla sınırlı olmamak üzere, herhangi bir özel, dolaylı, ceza gerektiren,
    tesadüfi, cezai ya da sonuçta ortaya çıkan hasarlar da dahil olmak üzere,
    herhangi bir garanti ihlali veya patentler, telif hakları, ticari markalar,
    ticari sırlar ve diğer fikri mülkiyetin yazılım, donanım veya kullanımları
    tarafından ihlali veya iddia edilen ihlali dahil Platform içeriğinin ya da
    bağlantı verilen sitelerde ya da bu siteler aracılığıyla sunulan hizmetlerin
    kullanılması ya da kullanılamamasından kaynaklanan ya da bu durumlarla
    bağlantılı olarak ortaya çıkan herhangi bir hasardan, ihmali olsun veya
    olmasın ve bu gibi hasarların ortaya çıkma olasılığı konusunda uyarılmış
    olsun veya olmasın, herhangi bir şekilde sorumlu değildir.
    <br />
    6.3. Saglikcim.net Kullanıcısı Platform’un kullanılması konusunda tüm
    sorumluluğun ve tüm risklerin kendilerine ait olduğunu kabul eder.
    <br />
    6.4. WHOLEMEDICS istediği zaman ve önceden haber vermeksizin, Platform’da
    bulunan bilgilere ve sağlanan hizmetlere ekleme yapma, bilgileri silme ya da
    bilgilerde değişiklik yapma hakkını saklı tutar. Bu hakkın kullanımı sonucu
    meydana gelebilecek doğrudan ve/veya dolaylı hiçbir talepten/zarardan
    WHOLEMEDICS sorumlu değildir.
    <br />
    7. SON HÜKÜMLER
    <br />
    7.1. Kullanım Hüküm ve Koşulları, Türkiye Cumhuriyeti’nin yürürlükteki
    mevzuatına göre yorumlanır ve uygulanır.
    <br />
    7.2. Kullanım Hüküm ve Koşulları ile ilgili uyuşmazlıklar için münhasıran
    İstanbul Merkez (Çağlayan) Mahkemeleri ve İcra Daireleri yetkilidir.
    <br />
    7.3. Yetkili bir mahkeme bu Kullanım Hüküm ve Koşulları'nın herhangi bir
    maddesinin geçersiz ya da uygulanamaz olduğuna karar verirse, Kullanım Hüküm
    ve Koşulları'nın diğer maddeleri yürürlükte ve geçerli kalacaktır.
  </>
);

export const healthPermission = (
  <>
    Sağlıkçım.net kullanımınız esnasında test talepleriniz, test sonuçlarınız,
    sağlık kuruluşlarından aldığınız randevulara dair kayıtlar gibi sağlık
    durumunuza dair bilgiler Wholemedics A.Ş. tarafından işlenecek ve uygulamayı
    kullanırken talep ettiğiniz hizmetler doğrultusunda üçüncü taraf sağlık
    kuruluşlarına aktarılabilecek ya da üçüncü taraf sağlık kuruluşları
    tarafından uygulamaya yüklenebilecektir. Sağlık durumuma ilişkin özel
    nitelikli kişisel verilerimin Wholemedics A.Ş. tarafından Sağlıkçım.net ve
    içerisindeki servisleri tarafıma sunumu amacıyla sınırlı olarak işlenmesini
    ve hizmet aldığınız üçüncü taraf sağlık kuruluşu iş ortaklarınız tarafından
    uygulamaya yüklenmesini kabul ediyorum.
  </>
);
