import React from 'react';
import { observer, inject } from "mobx-react"

import Countdown from 'react-countdown';

export const CountDown = inject("store")(observer((props) => {

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            props.store.setOdemeSuresiDolduMenuDisplay(true)
            props.store.setOdemeBilgilendirmeMenuDisplay(false)
            return <span>Süre Doldu.</span>;
        } else {
            // Render a countdown
            return <span>{minutes}:{seconds}</span>;
        }
    };

    return (
        <>
            <span><b>Kalan Süre:</b> <Countdown date={new Date(props.date).getTime() + Number(props.time)} renderer={renderer} /> </span>
        </>
    )
}))