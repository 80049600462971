import axios from "axios";

const searchDoctor = async (baseUrl, token, patientId, body) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    await axios.put(baseUrl + `/patient/` + patientId + "/doctor", body, config)
        .then((res) => {
            putResponse = {
                status: res.data.status,
                result: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    result: error.response.status
                }
            }
        });
    return putResponse
}

export default searchDoctor;