import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"
import { toJS } from 'mobx';

import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DatePicker } from '@mui/x-date-pickers';
import { IMaskInput } from 'react-imask';

import 'dayjs/locale/tr';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import getIlListesi from '../../../utils/api/common/GetIlListesi'
import getIlceListesi from '../../../utils/api/common/GetIlceListesi'
import getMahalleListesi from '../../../utils/api/common/GetMahalleListesi'

import patientUpdate from '../../../utils/api/patient/PatientUpdate'
import getPatientInsuranceInfo from '../../../utils/api/patient/Insurance/GetPatientInsuranceInfo'
import postPatientInsurance from '../../../utils/api/patient/Insurance/PostPatientInsurance';
import deletePatientInsuranceInfo from '../../../utils/api/patient/DeletePatientInsurance'
import putPatientForgot from '../../../utils/api/patient/PutPatientForgot'
import putPatientForgotPasswordUpdate from '../../../utils/api/patient/PutPatientForgotPasswordUpdate'

import { CountDown } from '../../common/CountDown';
import { Insurance } from '../../../model/Patient/Insurance';

function stringAvatar(name) {
    return (name !== "") ? {
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    } : { children: "" };
}

const sigortaSirketListesi = [
    "Acnturk Sigorta AŞ",
    "AKSigorta AŞ",
    "Allianz Sigorta AŞ",
    "Ana Sigorta Anonim Şirketi",
    "Anadolu Anonim Türk Sigorta Şirketi",
    "Ankara Anonim Türk Sigorta Şirketi",
    "Arex Sigorta AŞ",
    "S.S. Atlas Sigorta Kooperatifi",
    "Aveon Global Sigorta AŞ",
    "Axa Sigorta AŞ",
    "Bereket Sigorta AŞ",
    "Bereket Katılım Hayat AŞ",
    "Bereket Katılım Sigorta AŞ",
    "BNP Paribas Cardif Hayat AŞ",
    "BNP Paribas Cardif Sigorta AŞ",
    "BUPA Acıbadem Sigorta AŞ",
    "Coface Sigorta AŞ",
    "Corpus Sigorta AŞ",
    "Demir Sağlık ve Hayat Sigorta AŞ",
    "Doga Sigorta AŞ",
    "Dubai Sigorta AŞ",
    "Emaa Sigorta AŞ",
    "Ethica Sigorta Anonim Şirketi",
    "Euler Hermes Sigorta AŞ",
    "Eureko Sigorta AŞ",
    "Generali Sigorta AŞ",
    "GRI Sigorta AŞ",
    "Groupama Hayat AŞ",
    "Groupama Sigorta AŞ",
    "Gulf Sigorta AŞ",
    "HDI Sigorta AŞ",
    "Hepiyi Sigorta AŞ",
    "Koru Sigorta AŞ",
    "Magdeburger Sigorta AŞ",
    "Mapfre Sigorta AŞ",
    "Mapfre Yaşam Sigorta AŞ",
    "Neova Katılım Sigorta AŞ",
    "Orient Sigorta AŞ",
    "Quick Sigorta AŞ",
    "Prive Sigorta AŞ",
    "Ray Sigorta Anonim Şirketi",
    "Sompo Sigorta AŞ",
    "Şeker Sigorta AŞ",
    "Türk Nippon Sigorta AŞ",
    "Türk P ve I Sigorta AŞ",
    "Türkiye Sigorta AŞ",
    "Unico Sigorta AŞ",
    "Zurich Sigorta AŞ"
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(#00)000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

export const ProfileMenu = inject("store")(observer((props) => {

    const [ilListesi, setIlListesi] = useState([])
    const [ilceListesi, setIlceListesi] = useState([])
    const [mahalleListesi, setMahalleListesi] = useState([])

    const [patientInsuranceList, setPatientInsuranceList] = useState([])
    const [sigortaBilgiMenuDisplay, setSigortaBilgiMenuDisplay] = useState(false)

    const [patientInsurance, setPatientInsurance] = useState(Insurance.create({}))

    const [parolamiUnuttumDialogDisplay, setParolamiUnuttumDialogDisplay] = useState(false)
    const [parolamiUnuttumDisplay, setParolamiUnuttumDisplay] = useState(false)
    const [parolamiUnuttumTckno, setParolamiUnuttumTckno] = useState("")
    const [parolamiUnuttumSmsCode, setParolamiUnuttumSmsCode] = useState(0)
    const [newPassword, setNewPassword] = useState("")
    const [reNewpassword, setReNewpassword] = useState("")
    const [passwordHelperTitle, setPasswordHelperTitle] = useState("")
    const [passwordErrorStatus, setPasswordErrorStatus] = useState(false)
    const [repasswordHelperTitle, setRepasswordHelperTitle] = useState("")
    const [repasswordErrorStatus, setRepasswordErrorStatus] = useState(false)
    const [repasswordShowStatus, setRepasswordShowStatus] = useState(false)
    const [passwordShowStatus, setPasswordShowStatus] = useState(false)

    const [countInputDate, setCountInputDate] = useState(new Date())

    useEffect(() => {
        // Update the document title using the browser API
        getIlListesiReq()
        if (props.store.patient[0] !== undefined) {
            getIlceListesiReq(props.store.patient[0].cityId)
            getMahalleListesiReq(props.store.patient[0].districtId)
            getPatientInsuranceInfoReq(props.store.patient[0].id)
        }
        // eslint-disable-next-line
    }, []);

    const getIlListesiReq = async () => {
        let ilListesiResponse = await getIlListesi(props.store.baseUrl)
        //console.log("ilListesiResponse: ", ilListesiResponse)
        if (ilListesiResponse !== null && ilListesiResponse !== undefined) {
            if (ilListesiResponse.status === 200) {
                setIlListesi(ilListesiResponse.result)
            } else if (ilListesiResponse.status === 401) {
                props.store.setUserLogout()
                props.history.push("/danisan/kullanici-girisi")
            }
        }
    }

    const getIlceListesiReq = async (cityId) => {
        let ilceListesiResponse = await getIlceListesi(props.store.baseUrl, props.store.token, cityId)
        //console.log("ilceListesiResponse: ", ilceListesiResponse)
        if (ilceListesiResponse !== null && ilceListesiResponse !== undefined) {
            if (ilceListesiResponse.status === 200) {
                setIlceListesi(ilceListesiResponse.result)
            }
        }
    }

    const getMahalleListesiReq = async (districtId) => {
        let mahalleListesiResponse = await getMahalleListesi(props.store.baseUrl, props.store.token, districtId)
        //console.log("mahalleListesiResponse: ", mahalleListesiResponse)
        if (mahalleListesiResponse !== null && mahalleListesiResponse !== undefined) {
            if (mahalleListesiResponse.status === 200) {
                setMahalleListesi(mahalleListesiResponse.result)
            }
        }
    }

    const getPatientInsuranceInfoReq = async (patientId) => {
        let patientInsuranceIfoResponse = await getPatientInsuranceInfo(props.store.baseUrl, props.store.token, patientId)
        if (patientInsuranceIfoResponse !== null && patientInsuranceIfoResponse !== undefined) {
            if (patientInsuranceIfoResponse.status === 200) {
                if (patientInsuranceIfoResponse.result.length > 0) {
                    setPatientInsuranceList(patientInsuranceIfoResponse.result)
                }
            } else if (patientInsuranceIfoResponse.status === 401) {
                props.store.setUserLogout()
                props.history.push("/danisan/kullanici-girisi")
            }
        }
    }

    const onChangeSelectIlMenu = async (event) => {
        await getIlceListesiReq(event.target.value)
        let patient = toJS(props.store.patient[0])
        patient.cityId = Number(event.target.value)
        props.store.setPatient(patient)
    }

    const onChangeSelectIlceMenu = async (event) => {
        await getMahalleListesiReq(event.target.value)
        let patient = toJS(props.store.patient[0])
        patient.districtId = Number(event.target.value)
        props.store.setPatient(patient)
    }

    const onChangeSelectMahalleMenu = (event) => {
        let patient = toJS(props.store.patient[0])
        patient.neighbourhoodId = Number(event.target.value)
        props.store.setPatient(patient)
    }

    const onChangeTextInput = (event) => {
        if (props.store.isLogin) {
            let patient = toJS(props.store.patient[0])
            //console.log("event: ", Number(event.target.value))
            if (event.target.value !== "") {
                if (event.target.name === "address") {
                    patient[event.target.name] = event.target.value
                    props.store.setPatient(patient)
                } else {
                    if (isNaN(Number(event.target.value))) {
                        if (event.target.name === "mobilePhone") {
                            // eslint-disable-next-line
                            let phoneNumber = event.target.value.replace(/[\(\)]/g, "").replace("-", "")
                            patient[event.target.name] = Number(phoneNumber)
                            props.store.setPatient(patient)
                        } else {
                            patient[event.target.name] = event.target.value
                            props.store.setPatient(patient)
                        }
                    } else {
                        patient[event.target.name] = Number(event.target.value)
                        props.store.setPatient(patient)
                    }
                }
            } else {
                patient[event.target.name] = event.target.value
                props.store.setPatient(patient)
            }
        }
    }

    const updatePatient = async () => {
        props.store.setLoadingBarStatus(false)
        let patient = toJS(props.store.patient[0])
        let patientResponse = await patientUpdate(props.store.baseUrl, props.store.token, props.store.patient[0].id, patient)
        if (patientResponse !== null && patientResponse !== undefined) {
            if (patientResponse.status === 200) {
                props.store.setMessage("Bilgileriniz başarıyla güncellendi.", true, "success")
                /*if (patientInsurance.firmName !== "" && patientInsurance.customerNo !== "" && patientInsurance.policyNo !== "") {
                    let postPatientInsuranceResponse = await putPatientInsurance(props.store.baseUrl, props.store.token,
                        props.store.patient[0].id, patientInsurance)
                    console.log("postPatientInsuranceResponse: ", postPatientInsuranceResponse)
                }*/
            } else {
                props.store.setMessage("Bilgileriniz güncellenirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Bilgileriniz güncellenirken hata oluştu.", true, "error")
        }
        props.store.setLoadingBarStatus(true)
    }

    const onClickOpenSigortaMenu = () => {
        setSigortaBilgiMenuDisplay(true)
    }

    const onClickCloseSigortaMenu = () => {
        setSigortaBilgiMenuDisplay(false)
    }

    const saveSigortaBilgisi = async () => {
        props.store.setLoadingBarStatus(false)

        let patientInsuranceData = toJS(patientInsurance)
        patientInsuranceData.patientId = props.store.patient[0].id

        let postPatientInsuranceResponse = await postPatientInsurance(props.store.baseUrl, props.store.token, props.store.patient[0].id, patientInsuranceData)
        if (postPatientInsuranceResponse !== null && postPatientInsuranceResponse !== undefined) {
            if (postPatientInsuranceResponse.status === 200) {
                props.store.setMessage("Danışan sigorta bilgileri başarıyla kaydedildi. ", true, "success")
                setSigortaBilgiMenuDisplay(false)
                await getPatientInsuranceInfoReq(props.store.patient[0].id)
            } else {
                props.store.setMessage("Danışan sigorta bilgileri kaydedilirken hata oluştu. ", true, "error")
            }
        } else {
            props.store.setMessage("Danışan sigorta bilgileri kaydedilirken hata oluştu. ", true, "error")
        }

        props.store.setLoadingBarStatus(true)

    }

    const onChangeSigortaSirketSelectMenu = (event) => {
        setPatientInsurance({ ...patientInsurance, firmName: event.target.value })
    }

    const onChangeSigortaTextInput = (event) => {
        setPatientInsurance({ ...patientInsurance, [event.target.name]: event.target.value })
    }

    const onChangeInsuranceSigortaStartDate = (event) => {
        setPatientInsurance({ ...patientInsurance, startDate: event })
    }

    const onChangeInsuranceSigortaEndDate = (event) => {
        setPatientInsurance({ ...patientInsurance, endDate: event })
    }

    const deletePatientInsurance = async (insuranceId) => {
        props.store.setLoadingBarStatus(false)
        let deletePatientInsuranceResponse = await deletePatientInsuranceInfo(props.store.baseUrl, props.store.token, props.store.patient[0].id,
            insuranceId)

        if (deletePatientInsuranceResponse !== null && deletePatientInsuranceResponse !== undefined) {
            if (deletePatientInsuranceResponse.status === 200) {
                props.store.setMessage("Sağlık sigorta bilgisi başarıyla silindi.", true, "success")
            } else {
                props.store.setMessage("Sağlık sigorta bilgisi silinirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Sağlık sigorta bilgisi silinirken hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const onClickOpenParolamiUnuttum = () => {
        setParolamiUnuttumDialogDisplay(true)
    }

    const handleCloseParolamiUnuttum = () => {
        setParolamiUnuttumDialogDisplay(false)
    }

    const onChangeParolamiUnuttumTcknoTextfield = (event) => {
        setParolamiUnuttumTckno(event.target.value)
    }

    const onClickForgotPassword = async () => {
        props.store.setLoadingBarStatus(false)
        let passwordForgotResponse = await putPatientForgot(props.store.baseUrl, props.store.token, parolamiUnuttumTckno)
        if (passwordForgotResponse !== null && passwordForgotResponse !== undefined) {
            if (passwordForgotResponse.status === 200) {
                setParolamiUnuttumDisplay(true)
                setCountInputDate(new Date())
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const onNewPasswordTextFieldTextChange = (event) => {
        let passwordText = event.target.value
        if (passwordText.length < 8 && !isUpper(passwordText)) {
            setPasswordHelperTitle("*Şifreniz en az 8 karakter uzunluğunda olmalıdır. En az 1 tane büyük harf içermelidir.")
            setPasswordErrorStatus(true)
        } else {
            setPasswordHelperTitle("")
            setPasswordErrorStatus(false)
        }
        setNewPassword(event.target.value)
    }

    const isUpper = (str) => {
        return !/[a-z]/.test(str) && /[A-Z]/.test(str);
    }

    const onRegisterRePasswordTextFieldTextChange = (event) => {
        //console.log("event.target.value: ", event.target.value)
        let passwordText = event.target.value
        if (passwordText !== newPassword) {
            setRepasswordHelperTitle("*Şifreler uyuşmuyor.")
            setRepasswordErrorStatus(true)
        } else {
            setRepasswordHelperTitle("")
            setRepasswordErrorStatus(false)
        }
        setReNewpassword(event.target.value)
    }

    const onClickPasswordUpdate = async () => {
        props.store.setLoadingBarStatus(false)
        if (!repasswordErrorStatus) {
            let passwordUpdateResponse = await putPatientForgotPasswordUpdate(props.store.baseUrl, props.store.token, parolamiUnuttumSmsCode, reNewpassword)
            //console.log("passwordUpdateResponse: ", passwordUpdateResponse)
            if (passwordUpdateResponse !== null && passwordUpdateResponse !== undefined) {
                if (passwordUpdateResponse.status === 200) {
                    props.store.setMessage("Şifreniz başarıyla değiştirildi.", true, "success")
                    setParolamiUnuttumDialogDisplay(false)
                    props.store.setUserLogout()
                    props.history.push("/danisan/kullanici-girisi")
                }
            }
        } else {
            props.store.setMessage("Şifreler uyuşmadığı için güncelleme işlemi yapamazsınız.", true, "error")
        }
        props.store.setLoadingBarStatus(true)
    }

    const handleMouseDownPassword = () => {
        setPasswordShowStatus(false)
    }

    const handleClickShowPassword = () => {
        setPasswordShowStatus(true)
    }

    const handleClickShowRepassword = () => {
        setRepasswordShowStatus(true)
    }

    const handleMouseDownRepassword = () => {
        setRepasswordShowStatus(false)
    }

    const onChangeParolamiUnuttumSmsCode = (event) => {
        setParolamiUnuttumSmsCode(event.target.value)
    }

    return (
        <>
            <Grid container spacing={3} style={{ marginTop: "10px" }}>
                <Grid item lg={2} sm={12} xl={2} xs={12}>
                    <Avatar sx={{ width: 100, height: 100 }} {...stringAvatar((props.store.user[0] !== null && props.store.user[0] !== undefined) ? props.store.user[0].name + " " + props.store.user[0].surname : "")} color="primary" style={{ paddingTop: "3px", fontSize: "45px" }} />
                </Grid>
                <Grid item lg={10} sm={12} xl={10} xs={12}>
                    <Grid container spacing={3}>
                        <Grid item lg={6} sm={6} xl={6} xs={12}>
                            <FormControl fullWidth>
                                <TextField name="tckno" value={(props.store.patient[0] !== undefined) ? props.store.patient[0].tckno : ""} variant='outlined'
                                    label="T.C. Kimlik No" style={{ width: "100%" }} onChange={onChangeTextInput} disabled></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item lg={6} sm={6} xl={6} xs={12}>
                            <FormControl fullWidth>
                                <TextField name="name" value={(props.store.patient[0] !== undefined) ? props.store.patient[0].name : ""} variant='outlined'
                                    label="Adı" style={{ width: "100%" }} onChange={onChangeTextInput}></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item lg={6} sm={6} xl={6} xs={12}>
                            <FormControl fullWidth>
                                <TextField name="surname" value={(props.store.patient[0] !== undefined) ? props.store.patient[0].surname : ""} variant='outlined'
                                    label="Soyadı" style={{ width: "100%" }} onChange={onChangeTextInput}></TextField>
                            </FormControl>
                        </Grid>

                        <Grid item lg={6} sm={6} xl={6} xs={12}>
                            <FormControl fullWidth>
                                <TextField name="email" value={(props.store.patient[0] !== undefined) ? props.store.patient[0].email : ""} variant='outlined'
                                    label="E-posta adresi" style={{ width: "100%" }} onChange={onChangeTextInput}></TextField>
                            </FormControl>
                        </Grid>

                        <Grid item lg={6} sm={6} xl={6} xs={12}>
                            <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined" name="mobilePhone"
                                    value={(props.store.patient[0] !== undefined) ? props.store.patient[0].mobilePhone.toString() : ""}
                                    type='text'
                                    InputProps={{
                                        inputComponent: TextMaskCustom,
                                    }}
                                    label="Cep Telefon Numarası" onChange={onChangeTextInput} />
                            </FormControl>

                        </Grid>
                        <Grid item lg={6} sm={6} xl={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="city-select-label">İl Bilgisi</InputLabel>
                                <Select labelId="city-select-label" label="İl Bilgisi"
                                    value={(props.store.patient[0] !== undefined) ? props.store.patient[0].cityId : 0} id="city-select" style={{ background: "#fff" }}
                                    onChange={onChangeSelectIlMenu} MenuProps={MenuProps}>
                                    <MenuItem key={"emptyil"} value={0}>İl Seçiniz</MenuItem>
                                    {ilListesi.map((il, idx) => (
                                        <MenuItem key={idx} value={il.cityId}>{il.cityName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={6} sm={6} xl={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="city-select-label">İlçe Bilgisi</InputLabel>
                                <Select labelId="city-select-label" label="İlçe Bilgisi"
                                    value={(props.store.patient[0] !== undefined) ? props.store.patient[0].districtId : 0} id="city-select" style={{ background: "#fff" }}
                                    onChange={onChangeSelectIlceMenu}>
                                    <MenuItem key={"emptyilce"} value={0}>İlçe Seçiniz</MenuItem>
                                    {ilceListesi.map((ilce, idx) => (
                                        <MenuItem key={idx} value={ilce.districtId}>{ilce.districtName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item lg={6} sm={6} xl={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="city-select-label">Mahalle Bilgisi</InputLabel>
                                <Select labelId="city-select-label" label="Mahalle Bilgisi"
                                    value={(props.store.patient[0] !== undefined) ? props.store.patient[0].neighbourhoodId : 0} id="city-select" style={{ background: "#fff" }}
                                    onChange={onChangeSelectMahalleMenu}>
                                    <MenuItem key={"emptymahalle"} value={0}>Mahalle Seçiniz</MenuItem>
                                    {mahalleListesi.map((mahalle, idx) => (
                                        <MenuItem key={idx} value={mahalle.neighbourhoodId}>{mahalle.neighbourhoodName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item lg={6} sm={6} xl={6} xs={12}>
                            <FormControl fullWidth>
                                <TextField name="address" value={(props.store.patient[0] !== undefined) ? props.store.patient[0].address : ""} variant='outlined'
                                    label="Adres Bilgisi" style={{ width: "100%" }} onChange={onChangeTextInput}></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item lg={6} sm={6} xl={6} xs={12}>
                            <FormControl fullWidth>
                                <TextField name="professional" value={(props.store.patient[0] !== undefined) ? props.store.patient[0].professional : ""} variant='outlined'
                                    label="Meslek Bilgisi" style={{ width: "100%" }} onChange={onChangeTextInput}></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item lg={12} sm={12} xl={12} xs={12}>

                            <Button variant="contained" color="primary" style={{ float: "right", marginTop: "0", marginLeft: "20px" }}
                                onClick={updatePatient}>
                                <UpdateIcon style={{ marginRight: "10px" }} /> Güncelle
                            </Button>
                            <Button variant="contained" color="primary" style={{ float: "right", marginTop: "0" }}
                                onClick={onClickOpenParolamiUnuttum}>
                                <LockResetIcon style={{ marginRight: "20px" }} /> Şifre Güncelle
                            </Button>
                        </ Grid>
                        <Grid item xs={6}>
                            <b>Sağlık Sigorta Bilgileriniz</b>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant='contained' color="primary" style={{ float: "right" }}
                                onClick={onClickOpenSigortaMenu}>
                                <AddIcon /> Yeni Sağlık Sigorta Bilgisi Ekle
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer style={{ width: "100%" }}>
                                <Table aria-label="simple table" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">SİGORTA ŞİRKET BİLGİSİ</TableCell>
                                            <TableCell align="left">POLİÇE NO</TableCell>
                                            <TableCell align="left">MÜŞTERİ NO</TableCell>
                                            <TableCell align="right">İŞLEMLER</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {patientInsuranceList.map((insurance, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell align='left'>{insurance.firmName}</TableCell>
                                                <TableCell align='left'>
                                                    {insurance.policyNo}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {insurance.customerNo}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Grid item xs={12}>
                                                        <Tooltip title="Özel Sağlık Sigortası Sil">
                                                            <IconButton color="error" aria-label="delete insurance" component="label"
                                                                onClick={() => deletePatientInsurance(insurance.insuranceId)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={sigortaBilgiMenuDisplay}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>Sağlık Sigorta Bilgisi</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3} style={{ marginTop: "20px" }}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="city-select-label">Sigorta Şirket Bilgisi</InputLabel>
                                <Select labelId="city-select-label" label="Sigorta Şirket Bilgisi"
                                    value={patientInsurance.firmName} id="city-select" style={{ background: "#fff" }}
                                    onChange={onChangeSigortaSirketSelectMenu} MenuProps={MenuProps}>
                                    <MenuItem key={"default"} value={""}>Sigorta Şirketi Seçiniz</MenuItem>
                                    {sigortaSirketListesi.map((sigortaSirket, idx) => (
                                        <MenuItem key={idx} value={sigortaSirket}>{sigortaSirket}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="policyNo" variant='outlined' label="Poliçe No" style={{ width: "100%" }}
                                value={patientInsurance.policyNo} onChange={onChangeSigortaTextInput}></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="customerNo" variant='outlined' label="Müşteri No" style={{ width: "100%" }}
                                value={patientInsurance.customerNo} onChange={onChangeSigortaTextInput}></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth style={{ marginBottom: "15px" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} style={{ width: "calc(100% - 100px)" }}>
                                    <DatePicker label="Sigorta Başlangıç Tarihi" value={patientInsurance.startDate}
                                        onChange={(newValue) => onChangeInsuranceSigortaStartDate(newValue)}
                                        renderInput={(params) => <TextField {...params} />} resizable
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth style={{ marginBottom: "15px" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} style={{ width: "calc(100% - 100px)" }}>
                                    <DatePicker label="Sigorta Başlangıç Tarihi" value={patientInsurance.endDate}
                                        onChange={(newValue) => onChangeInsuranceSigortaEndDate(newValue)}
                                        renderInput={(params) => <TextField {...params} />} resizable
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onClickCloseSigortaMenu}>
                        <Typography color="primary">Kapat</Typography>
                    </Button>
                    <Button /*component={Link} to=""*/ variant='contained' onClick={saveSigortaBilgisi}>
                        Sağlık Sigorta Bilgisi Kaydet
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={parolamiUnuttumDialogDisplay} maxWidth={"sm"} fullWidth>
                <AppBar sx={{ position: 'relative', background: "#ffab40" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseParolamiUnuttum}
                            aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Parolamı Unuttum
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{ position: "absolute", height: "5px", width: "100%", zIndex: "5000" }}>
                    <LinearProgress style={{ display: (props.store.loadingBarStatus) ? "none" : "block" }} color="primary" />
                </div>
                <DialogContent style={{ padding: "30px" }}>
                    <Paper elevation={0} hidden={parolamiUnuttumDisplay} style={{ width: "100%", minHeight: "100px" }}>
                        <Grid item xs={12}>
                            <TextField value={parolamiUnuttumTckno} onChange={onChangeParolamiUnuttumTcknoTextfield}
                                label="T.C. Kimlik Numarası" style={{ width: "100%" }} />
                        </Grid>
                    </Paper>
                    <Paper elevation={0} hidden={!parolamiUnuttumDisplay} style={{ width: "100%", minHeight: "100px" }}>
                        <Grid item xs={12}>
                            <TextField value={parolamiUnuttumSmsCode} onChange={onChangeParolamiUnuttumSmsCode}
                                label="Telefonunuza gelen SMS kodunu giriniz" style={{ width: "100%" }} />
                            <CountDown date={countInputDate} time={180000} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="outlined-basic" variant="outlined" name="password"
                                type={passwordShowStatus ? "text" : "password"} value={newPassword}
                                style={{ width: "100%", marginBottom: "10px", borderRadius: "10px", marginTop: "10px" }}
                                label="Şifreniz*" onChange={onNewPasswordTextFieldTextChange} helperText={passwordHelperTitle} error={passwordErrorStatus}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end">
                                                {passwordShowStatus ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="outlined-basic" variant="outlined" name="repassword"
                                type={repasswordShowStatus ? 'text' : 'password'} value={reNewpassword}
                                style={{ width: "100%", marginBottom: "10px", borderRadius: "10px", marginTop: "10px" }}
                                label="Şifreniz (tekrar)*" onChange={onRegisterRePasswordTextFieldTextChange} helperText={repasswordHelperTitle} error={repasswordErrorStatus}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowRepassword}
                                                onMouseDown={handleMouseDownRepassword}
                                                edge="end">
                                                {repasswordShowStatus ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }} />
                        </Grid>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    {(!parolamiUnuttumDisplay) ?
                        <Button variant='contained' color="primary" onClick={onClickForgotPassword}
                            style={{ height: "55px", float: "right" }}>
                            Parolamı Sıfırla
                        </Button> :
                        <Button variant='contained' color="primary" onClick={onClickPasswordUpdate}>
                            Parolamı Değiştir
                        </Button>}
                </DialogActions>
            </Dialog>
        </>
    )
}))