import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"
import { Link } from 'react-router-dom';

import { Button, Card, Grid, TextField, Pagination, Tooltip } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextEditor from '../../common/TextEditor'

import add from 'date-fns/add'
import dayjs from 'dayjs'

import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import "./overrides.css"

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorder from '@mui/icons-material/FavoriteBorder'
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info'

import getIlListesi from '../../../utils/api/common/GetIlListesi'
import searchDoctor from '../../../utils/api/patient/SearchDoctor'
import doctorFavoritePost from '../../../utils/api/patient/FavoriteDoctor/DoctorFavoritePost'
import getDoctorUzmanlikList from '../../../utils/api/patient/GetDoctorUzmanlikList'
import getLanguageListesi from '../../../utils/api/common/GetLanguageListesi'
import { getPatientPromos } from "../../../utils/api/patient/Promos";
import { professionals } from '../../common/List';

const CryptoJS = require('crypto-js');

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const SearchMenu = inject("store")(observer((props) => {

    const [ilListesi, setIlListesi] = useState([])
    const [selectedIl, setSelectedIl] = useState(0)
    const [uzmanlikAlanListesi, setUzmanlikAlanListesi] = useState([])
    const [selectedUzmanlikAlan, setSelectedUzmanlikAlan] = useState(0)

    const [aktifKategori, setAktifKategori] = useState(null)

    const [selectedDate, setSelectedDate] = useState([null, null]);
    const [checkedToday, setCheckedToday] = useState(false);
    const [checkedTomorrow, setCheckedTomorrow] = useState(false);
    const [checkedOneWeek, setCheckedOneWeek] = useState(false)

    const [searchTextInput, setSearchTextInput] = useState("")
    const [queryResultList, setQueryResultList] = useState([])
    const [searchTotalResultSize, setSearchTotalResultSize] = useState(0)
    const [searchPageSize, setSearchPageSize] = useState(0)
    const [activeSearchPage, setActiveSearchPage] = useState(0)

    const [queryBody, setQueryBody] = useState(null)

    const [selectedLangId, setSelectedLangId] = useState(0)
    const [languageList, setLanguageList] = useState([])

    const [doktorBilgiMenuDisplay, setDoktorBilgiMenuDisplay] = useState(false)
    const [selectedDoctor, setSelectedDoctor] = useState(null)

    const [promoDoctors, setPromoDoctors] = useState(null)

    useEffect(() => {
        if (queryBody !== null) {
            searchDoctorReq(queryBody)
        }
        // eslint-disable-next-line
    }, [queryBody]);

    useEffect(() => {
        // Update the document title using the browser API
        getIlListesiReq();
        getDoctorUzmanlikListReq();
        getLanguageListReq();
        getPatPromos();

        let queryParamsObject = {
            patientId: props.store.patient[0].id,
            page: 0,
            departmentId: null,
            cityId: 0,
            titleId: 0,
            langId: 1,
            professionalId: 1,
            doctorNameSurname: searchTextInput,
            startDate: selectedDate[0],
            endDate: selectedDate[1]
        }

        if (props.queryParam === null || props.queryParam.kategori === undefined) {
            setAktifKategori("doktor")
            queryParamsObject.professionalId = 1
            queryParamsObject.page = 0
            setQueryBody(queryParamsObject)
        } else {
            setAktifKategori(props.queryParam.kategori)
            if (props.queryParam.q !== undefined && props.queryParam.q !== "") {
                setSearchTextInput(props.queryParam.q)
                queryParamsObject.doctorNameSurname = props.queryParam.q
                const profession = professionals.find((item) => item.slug === props.queryParam.kategori)
                queryParamsObject.professionalId = (profession !== undefined) ? profession.id : 1
                queryParamsObject.page = 0
                setQueryBody(queryParamsObject)
                } else {
                const profession = professionals.find((item) => item.slug === props.queryParam.kategori)
                queryParamsObject.professionalId = (profession !== undefined) ? profession.id : 1
                queryParamsObject.page = 0
                setQueryBody(queryParamsObject)
            }
        }
        // eslint-disable-next-line
    }, []);

    const getPatPromos = async () => {
        props.store.setLoadingBarStatus(false);
        let getPromoListRes = await getPatientPromos(
            props.store.baseUrl,
            props.store.token,
            props.store.patient[0].id
        );
        if (getPromoListRes !== null && getPromoListRes !== undefined) {
            if (getPromoListRes.status === 200) {
                if (getPromoListRes.data !== null) {
                    setPromoDoctors(getPromoListRes.data.map((promo) => promo.count > 0 && promo.doctorId));
                } else {
                    setPromoDoctors([]);
                }
            }
        }
        props.store.setLoadingBarStatus(true);
    };

    const getLanguageListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let langListResponse = await getLanguageListesi(props.store.baseUrl, props.store.token)
        if (langListResponse !== null && langListResponse !== undefined) {
            if (langListResponse.status === 200) {
                setLanguageList(langListResponse.data)
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const searchDoctorReq = async (queryBody) => {
        props.store.setLoadingBarStatus(false)
        let searchDoctorResponse = await searchDoctor(props.store.baseUrl, props.store.token, props.store.patient[0].id, queryBody)
        //console.log("searchDoctorResponse: ", searchDoctorResponse)
        if (searchDoctorResponse !== null && searchDoctorResponse !== undefined) {
            if (searchDoctorResponse.status === 200) {
                setQueryResultList(searchDoctorResponse.result.data)
                setSearchTotalResultSize(searchDoctorResponse.result.totalRow)
                setSearchPageSize(searchDoctorResponse.result.pageSize)
                setActiveSearchPage(searchDoctorResponse.result.pageNo)
            } else if (searchDoctorResponse.status === 401) {
                props.store.setUserLogout()
                props.history.push("/danisan/kullanici-girisi")
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const onClickKategoriButton = (kategoriIsmi) => {
        setAktifKategori(kategoriIsmi)
        //console.log("history:", props.history.location.search)

        let queryString = props.history.location.search

        let splittedQueryStr = queryString.split("&")
        splittedQueryStr[0] = "?kategori=" + kategoriIsmi

        let updatedQueryString = splittedQueryStr[0]
        let queryNameSurname = (splittedQueryStr[1] !== undefined) ? "&" + splittedQueryStr[1] : ""
        updatedQueryString = updatedQueryString + queryNameSurname

        //console.log("props.queryParam.kategori: ", props.queryParam.kategori)
        const professionalId = professionals.find((item) => item.slug === kategoriIsmi)?.id

        setQueryBody({ ...queryBody, professionalId })

        props.history.push(updatedQueryString)
    }

    const getIlListesiReq = async () => {
        let ilListesiResponse = await getIlListesi(props.store.baseUrl, props.store.token)
        //console.log("ilListesiResponse: ", ilListesiResponse)
        if (ilListesiResponse !== null && ilListesiResponse !== undefined) {
            if (ilListesiResponse.status === 200) {
                setIlListesi(ilListesiResponse.result)
            } else if (ilListesiResponse.status === 401) {
                props.store.setUserLogout()
                props.history.push("/danisan/kullanici-girisi")
            }
        }
    }

    const getDoctorUzmanlikListReq = async () => {
        let doctorUzmanlikResponse = await getDoctorUzmanlikList(props.store.baseUrl, props.store.token)
        if (doctorUzmanlikResponse !== null && doctorUzmanlikResponse !== undefined) {
            if (doctorUzmanlikResponse.status === 200) {
                setUzmanlikAlanListesi(doctorUzmanlikResponse.result)
            }
        }
    }

    const handleDateSelectionChange = (event) => {
        if (event.target.name === "today") {
            if (event.target.checked) {
                setCheckedToday(event.target.checked)
                setSelectedDate([new Date(), new Date()])
                setCheckedTomorrow(false)
                setCheckedOneWeek(false)
                setQueryBody({ ...queryBody, startDate: new Date(), endDate: new Date(), page: 0 })
            } else {
                setSelectedDate([null, null])
                setCheckedToday(false)
                setQueryBody({ ...queryBody, startDate: null, endDate: null, page: 0 })
            }
        } else if (event.target.name === "tomorrow") {
            if (event.target.checked) {
                setCheckedToday(false)
                setCheckedTomorrow(event.target.checked)
                setCheckedOneWeek(false)
                setSelectedDate([new Date(), add(new Date(), { days: 1 })])
                setQueryBody({ ...queryBody, startDate: new Date(), endDate: add(new Date(), { days: 1 }), page: 0 })
            } else {
                setSelectedDate([null, null])
                setCheckedTomorrow(false)
                setQueryBody({ ...queryBody, startDate: null, endDate: null, page: 0 })
            }
        } else {
            if (event.target.checked) {
                setCheckedToday(false)
                setCheckedTomorrow(false)
                setCheckedOneWeek(event.target.checked)
                setSelectedDate([new Date(), add(new Date(), { weeks: 1 })])
                setQueryBody({ ...queryBody, startDate: new Date(), endDate: add(new Date(), { weeks: 1 }), page: 0 })
            } else {
                setSelectedDate([null, null])
                setCheckedOneWeek(false)
                setQueryBody({ ...queryBody, startDate: null, endDate: null, page: 0 })
            }
        }
    };

    const handleDataRangePickerChange = (event) => {
        //console.log("event: ", event)
        setSelectedDate(event)
        if (event !== null) {
            setQueryBody({ ...queryBody, startDate: event[0], endDate: event[1], page: 0 })
        } else {
            setQueryBody({ ...queryBody, startDate: null, endDate: null, page: 0 })
        }
    }

    const onChangeSearchTextInputValue = (event) => {
        setActiveSearchPage(1)
        setSearchTextInput(event.target.value)
        setQueryBody({ ...queryBody, doctorNameSurname: event.target.value, page: 1  })
    }

    const onChangeSelectIlMenu = (event) => {
        setSelectedIl(event.target.value)
        setQueryBody({ ...queryBody, cityId: event.target.value, page: 0 })
    }

    const onChangeSelectedLanguage = (event) => {
        setSelectedLangId(event.target.value)
        setQueryBody({ ...queryBody, langId: event.target.value, page: 0 })
    }

    const onChangeSelectedUzmanlikAlani = (event) => {
        setSelectedUzmanlikAlan(event.target.value)
        if (event.target.value === 0) {
            setQueryBody({ ...queryBody, departmentId: null, page: 0 })
        } else {
            setQueryBody({ ...queryBody, departmentId: event.target.value, page: 0 })
        }
    }

    const onClickDoctorFav = async (doctorId) => {
        //console.log("doctor id: ", doctorId)
        props.store.setLoadingBarStatus(false)
        let doctorFavResponse = await doctorFavoritePost(props.store.baseUrl, props.store.token, props.store.patient[0].id, doctorId)
        if (doctorFavResponse !== null && doctorFavResponse !== undefined) {
            if (doctorFavResponse.status === 200) {
                props.store.setMessage("Seçtiğiniz doktor başarıyla favorilerinize eklendi.", true, "success")
            } else {
                props.store.setMessage("Seçtiğiniz doktor favorilerinize eklenirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Seçtiğiniz doktor favorilerinize eklenirken hata oluştu.", true, "error")
        }
        props.store.setLoadingBarStatus(true)
    }

    const onClickPagination = (event, value) => {
        setQueryBody({ ...queryBody, page: value })
        setActiveSearchPage(value)
    };

    const onClickDeleteFilter = () => {
        setQueryBody({
            patientId: props.store.patient[0].id,
            page: 0,
            departmentId: null,
            cityId: 0,
            titleId: 0,
            langId: 1,
            professionalId: 1,
            doctorNameSurname: searchTextInput,
            startDate: selectedDate[0],
            endDate: selectedDate[1]
        })
        setSelectedIl(0)
        setSelectedUzmanlikAlan(0)
        setSelectedDate([null, null]);
        setCheckedToday(false);
        setCheckedTomorrow(false);
        setCheckedOneWeek(false)
        setAktifKategori("doktor")
        setSelectedLangId(0)
    }

    const handleDoktorBilgiMenuClose = () => {
        setDoktorBilgiMenuDisplay(false)
    }

    const handleDoktorBilgiMenuOpen = (doktor) => {
        let longDesc = decryptWithAES(doktor.longDesc)
        doktor.longDesc = longDesc
        setSelectedDoctor(doktor)
        setDoktorBilgiMenuDisplay(true)
    }

    const decryptWithAES = (ciphertext) => {
        if (ciphertext !== null) {
            const passphrase = '123';
            const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);

            let originalText = ""
            try {
                originalText = bytes.toString(CryptoJS.enc.Utf8);
            } catch (error) {
            }
            return originalText;
        }else{
            return ""
        }
    };

    return (
        <>
            <Grid container spacing={0}>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Card style={{ height: "auto", background: "#fff", borderRadius: "0", marginLeft: "15px", boxShadow: "none" }}>
                        <CardContent style={{ background: "#fff9ef", borderRadius: "25px" }}>
                            <Typography variant='h6'> Filtre Menüsü</Typography>
                            <Typography style={{ marginTop: "20px", marginBottom: "5px", fontWeight: "bold" }}> Kategori</Typography>
                            <Grid container spacing={0} >
                                {professionals.map((item) => {
                                    return (
                                        <Grid item xs={4} key={item.id}>
                                            <Button variant={"contained"} color={(aktifKategori !== "") ? (aktifKategori === item.slug) ? "primary" : "secondary" : "secondary"}
                                                style={{ width: "100%", height: "100%", borderRadius: "0", border: "1px solid rgb(255, 249, 239)" }}
                                                onClick={() => onClickKategoriButton(item.slug)}>
                                                {item.name}
                                            </Button>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <Typography style={{ marginTop: "30px", marginBottom: "5px", fontWeight: "bold" }}> Uzmanlık Alanı</Typography>
                                    <FormControl fullWidth>
                                        <InputLabel id="uzmanlik-select-label">Seçilen Uzmanlık Bilgisi</InputLabel>
                                        <Select labelId="uzmanlik-select-label" label="Seçilen Uzmanlık Bilgisi"
                                            id="uzmanlik-select" style={{ background: "#fff" }} value={selectedUzmanlikAlan}
                                            onChange={onChangeSelectedUzmanlikAlani} disabled={!((aktifKategori === "doktor") || (aktifKategori === "psikolog"))}
                                            MenuProps={MenuProps}>
                                            <MenuItem value={0}>Uzmanlık Alanı Seç</MenuItem>
                                            {uzmanlikAlanListesi.map((uzmanlikAlan, idx) => (
                                                <MenuItem key={idx} value={uzmanlikAlan.id}>{uzmanlikAlan.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{ marginTop: "0px", marginBottom: "5px", fontWeight: "bold" }}>Şehir</Typography>
                                    <FormControl fullWidth>
                                        <InputLabel id="city-select-label">Seçilen Şehir Bilgisi</InputLabel>
                                        <Select labelId="city-select-label" label="Seçilen Şehir Bilgisi"
                                            value={selectedIl} id="city-select" style={{ background: "#fff" }}
                                            onChange={onChangeSelectIlMenu} MenuProps={MenuProps}>
                                            <MenuItem key={"default"} value={0}>İl Seçiniz</MenuItem>
                                            {ilListesi.map((il, idx) => (
                                                <MenuItem key={idx} value={il.cityId}>{il.cityName}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography style={{ marginTop: "0px", marginBottom: "5px", fontWeight: "bold" }}>Dil</Typography>
                                    <FormControl fullWidth>
                                        <InputLabel id="lang-select-label">Konuşabildiği Dil</InputLabel>
                                        <Select labelId="lang-select-label" label="Seçilen Dil Bilgisi"
                                            value={selectedLangId} id="city-select" style={{ background: "#fff" }}
                                            onChange={onChangeSelectedLanguage} MenuProps={MenuProps}>
                                            <MenuItem key={"default"} value={0}>Dil Seçiniz</MenuItem>
                                            {languageList.map((language, idx) => (
                                                <MenuItem key={idx} value={language.langId}>{language.langName}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography style={{
                                        marginTop: "0px"
                                        , marginBottom: "5px", fontWeight: "bold"
                                    }}> Tarih</Typography>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox name="today" checked={checkedToday} onChange={handleDateSelectionChange} />} label="Bugün" />
                                        <FormControlLabel control={<Checkbox name="tomorrow" checked={checkedTomorrow} onChange={handleDateSelectionChange} />} label="Yarın" />
                                        <FormControlLabel control={<Checkbox name="oneweek" checked={checkedOneWeek} onChange={handleDateSelectionChange} />} label="Bir hafta içerisinde" />
                                    </FormGroup>
                                    <DateRangePicker placeholder="Randevu Aralığını Seçiniz"
                                        showOneCalendar value={selectedDate} onChange={handleDataRangePickerChange}
                                        style={{ width: "100%", border: "1px solid #ffab40", borderRadius: "7px" }} size="lg" placement="topStart" />
                                </Grid>
                                <Grid item xs={12}>

                                    <Button variant='contained' onClick={onClickDeleteFilter} style={{ float: "right" }}>
                                        <DeleteIcon style={{ marginRight: "10px" }} /> Filtre Temizle
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={8} sm={6} xl={8} xs={12}>
                    <Card style={{ boxShadow: "none", background: "#fff", borderRadius: "0", marginLeft: "10px" }}>
                        <CardContent style={{ padding: "10px" }}>
                            <TextField value={searchTextInput} onChange={onChangeSearchTextInputValue}
                                style={{ width: "calc(100% - 60px)", borderRadius: "10px", backgroundColor: "rgb(242, 242, 242)", border: "1px solid #ffab40" }}
                                variant="outlined" focused placeholder="Adı ve soyadı bilgilerinden arama yapabilirsiniz" />

                            <Button variant="contained" style={{ borderRadius: "0", height: "58px", marginLeft: "-10px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }}>
                                Ara
                            </Button>

                            <Typography style={{
                                padding: "0", marginTop: "5px", marginRight: "10px", textAlign: "right"
                            }}>{searchTotalResultSize} adet sonuç bulundu</Typography>

                            <div style={{ height: "auto", overflowY: "hidden" }}>
                                {((searchTotalResultSize / searchPageSize) > 1) ?
                                    <Pagination count={(isNaN(searchTotalResultSize / searchPageSize) || (searchTotalResultSize / searchPageSize) === Infinity) ? 0 : Math.ceil(searchTotalResultSize / searchPageSize)}
                                        page={activeSearchPage} color="primary" onChange={onClickPagination} style={{ float: "right" }} size="large" />
                                    : null}
                                <Grid container spacing={0} style={{ padding: "0", marginTop: "0px" }}>
                                    {(queryResultList !== null) ? queryResultList.map((query, idx) => (
                                        (true/*query.doctorId !== 1174 && query.doctorId !== 1175 && query.doctorId !== 1172*/) ?
                                            <Grid key={idx} item lg={12} sm={12} xl={12} xs={12} style={{ padding: "5px" }}>
                                                <Card className='doctor-result' sx={{ marginTop: "10px", boxShadow: "none", border: "0px solid #fff925", background: "#fff9ef" }}>
                                                    <CardContent style={{ padding: "5px" }}>
                                                        <Grid container spacing={0}>
                                                            <Grid item lg={1} sm={1} xl={1} xs={1}>
                                                                <CardMedia
                                                                    component="img"
                                                                    sx={{ height: "60px", width: "60px", borderRadius: "60px", marginTop: "5px", background: "#f4f4f4", marginLeft: "5px" }}
                                                                    image={(query.avatar === null || query.avatar === "") ? "/assets/images/PersonIcon.png" : props.store.baseUrl + query.avatar}
                                                                    alt="Avatar"
                                                                />
                                                            </Grid>
                                                            <Grid item lg={7} sm={12} xl={7} xs={12} style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                                                                <Typography component="div" variant="body1" style={{ fontWeight: "bold" }}>
                                                                    <span style={{ fontSize: "16px" }}>{query.titleName ? query.titleName : ""}  {query.professionalName}</span> {query.name}  {query.surname}
                                                                </Typography>
                                                                <Typography variant="body2" component="div">
                                                                    {(query.departmentName !== null) ? query.departmentName : ""}  
                                                                </Typography>
                                                                <Typography variant="body2" component="div">
                                                                    <b>Hakkımda: </b>{query.shortDesc}
                                                                </Typography>
                                                                <Typography variant="body2" component="div">
                                                                    <b>Muayene Ücreti: {(query.examinationPrice !== 0) ? query.examinationPrice + " TL" : "-"} </b>
                                                                </Typography>
                                                                <Typography variant="body2" component="div">
                                                                    <b>Şehir: </b> {query.cityName}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item lg={4} sm={12} xl={4} xs={12}>
                                                                <Grid container>
                                                                    <Grid item xs={5} style={{ paddingTop: "15px", textAlign: "right" }}>
                                                                        <Tooltip title="Favorilere al">
                                                                            <Checkbox checked={(query.doctorFavCount > 0) ? true : false} icon={<FavoriteBorder />} checkedIcon={<FavoriteIcon />} onClick={() => onClickDoctorFav(query.doctorId)} />
                                                                        </Tooltip>
                                                                        <span style={{ marginTop: "15px", fontSize: "14px", marginRight: "0px" }}>{query.doctorFavCount}</span>
                                                                    </Grid>
                                                                    <Grid item xs={7} style={{ textAlign: "right" }}>
                                                                        <Tooltip title="Randevu Al">
                                                                            <Button variant="contained" style={{ height: "50px", marginTop: "5px", width: "120px", /*border: "2px solid #000",*/ paddingLeft: "0", paddingRight: "0" }}
                                                                                component={Link} to={promoDoctors?.includes(query.doctorId) ? "/danisan/randevu-olustur/" + query.doctorId + "?paket" : "/danisan/randevu-olustur/" + query.doctorId}>
                                                                                <CalendarMonthIcon style={{ color: "#000", marginRight: "10px" }} /> <span style={{ marginTop: "3px", color: "#000" }}>Randevu</span>
                                                                            </Button>

                                                                        </Tooltip>
                                                                        <Tooltip title="Hakkında Bilgi Al">
                                                                            <Button variant="contained" style={{ height: "50px", marginTop: "5px", width: "120px", border: "0px solid #000", background: "#e0e0e0", paddingLeft: "0", paddingRight: "0" }}
                                                                                onClick={() => handleDoktorBilgiMenuOpen(query)}>
                                                                                <InfoIcon style={{ color: "#000", marginRight: "10px" }} /> <span style={{ marginTop: "3px", color: "#000" }}>Hakkında</span>
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid> : null
                                    )) : null}


                                </Grid>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Dialog
                open={doktorBilgiMenuDisplay}
                onClose={handleDoktorBilgiMenuClose}>
                <DialogTitle id="alert-dialog-title">
                    {"Doktor Bilgisi"}
                </DialogTitle>
                <DialogContent style={{ paddingTop: "0px", paddingRight: "45px" }}>
                    <Grid container spacing={1} style={{ marginTop: "0px" }}>
                        <Grid item xs={3}>
                            <CardMedia
                                component="img"
                                sx={{ width: "100%", marginTop: "13px", borderRadius: "5px" }}
                                image={(selectedDoctor?.avatar !== null) ? props.store.baseUrl + selectedDoctor?.avatar : "/assets/images/PersonIcon.png"}
                                alt="Avatar"
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container spacing={1} style={{ marginTop: "0px", marginLeft: "10px" }}>
                                <Grid item xs={6}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>
                                        Adı Soyadı
                                    </Typography>
                                    <Typography variant='body2'>
                                        {(selectedDoctor?.titleName ? (selectedDoctor.titleName + " ") : "") + selectedDoctor?.professionalName
                                            + " " + selectedDoctor?.name + " " + selectedDoctor?.surname}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>
                                        Uzmanlık Alanı
                                    </Typography>
                                    <Typography variant='body2'>
                                        {selectedDoctor?.departmentName}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>
                                        Muayene Ücreti
                                    </Typography>
                                    <Typography variant='body2'>
                                        {selectedDoctor?.examinationPrice} TL
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>
                                        E-posta Adresi:
                                    </Typography>
                                    <Typography variant='body2'>
                                        {selectedDoctor?.email}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>
                                        Telefon Numarası:
                                    </Typography>
                                    <Typography variant='body2'>
                                        {selectedDoctor?.mobilePhone}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>
                                        Doğum Tarihi:
                                    </Typography>
                                    <Typography variant='body2'>
                                        {(selectedDoctor?.birthDate !== undefined) ? dayjs(new Date(selectedDoctor?.birthDate)).format("DD.MM.YYYY") : ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body2' style={{ fontWeight: "bold", marginTop: "25px" }}>ÖZGEÇMİŞ</Typography>
                                    <TextEditor value={(selectedDoctor?.longDesc !== undefined) ? (selectedDoctor?.longDesc !== null) ? selectedDoctor?.longDesc : "" : ""} />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant='contained' onClick={handleDoktorBilgiMenuClose} style={{ background: "#e0e0e0", color: "#000" }}>Kapat</Button>
                    <Button color="primary" variant='contained' onClick={handleDoktorBilgiMenuClose} style={{ color: "#000" }}>
                        <CalendarMonthIcon style={{ color: "#000", marginRight: "10px" }} />
                        Randevu Al</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}))