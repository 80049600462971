import axios from "axios";

const getPatientDoctorInfo = async (baseUrl, token, patientId, doctorId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + "/patient/" + patientId + "/doctor/" + doctorId, config)
        .then((res) => {
            getResponse = {
                status: res.data.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    result: error.response.status
                }
            }
        });
    return getResponse
}

export default getPatientDoctorInfo;