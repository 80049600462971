export const professionals = [
  {
    id: 1,
    slug: "doktor",
    name: "Doktor",
    img: "/assets/images/stethoscope.png",
  },
  {
    id: 3,
    slug: "diyetisyen",
    name: "Diyetisyen",
    img: "/assets/images/nutrition.png",
  },
  {
    id: 2,
    slug: "psikolog",
    name: "Psikolog",
    img: "/assets/images/divan.png",
  },
  {
    id: 4,
    slug: "fizyoterapist",
    name: "Fizyoterapist",
    img: "/assets/images/physio.svg",
  },
  {
    id: 5,
    slug: "antrenor",
    name: "Kişisel Antrenör",
    img: "/assets/images/pt.svg",
  },
  {
    id: 6,
    slug: "egitmen",
    name: "Eğitmen",
    img: "/assets/images/teacher.svg",
  },
  {
    id: 7,
    slug: "danisman",
    name: "Danışman",
    img: "/assets/images/communication.svg",
  },
  {
    id: 8,
    slug: "odyolog",
    name: "Odyolog",
    img: "/assets/images/ear.svg",
  },
];
