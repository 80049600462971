import * as React from 'react';
import { observer, inject } from "mobx-react"

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Card, CardContent, Grid, LinearProgress, Link } from '@mui/material';

import MapissoAlert from './MapissoAlert'

import { FiInbox } from 'react-icons/fi'
import { BiSearch, BiFile, BiPackage } from 'react-icons/bi'
import { BsFileMedical } from 'react-icons/bs'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FavoriteIcon from '@mui/icons-material/Favorite'

const drawerWidth = 235;
const drawerWidthMobile = 80;

export const LeftMenu = inject("store")(observer((props) => {

    const pages = [
        {
            name: "Ana Sayfa",
            url: "/danisan",
            icon: <FiInbox aria-hidden style={{ fontSize: "20px" }} />
        },
        {
            name: "Arama Sayfası",
            url: "/danisan/doktor-arama?kategori=doktor",
            icon: <BiSearch aria-hidden style={{ fontSize: "20px" }} />
        },
        {
            name: "Randevularım",
            url: "/danisan/randevularim/0",
            icon: <CalendarMonthIcon aria-hidden style={{ fontSize: "20px" }} />
        },
        {
            name: "Sağlıkçım",
            url: "/danisan/doktorlarim",
            icon: <FavoriteIcon aria-hidden style={{ fontSize: "20px" }} />
        },
        {
            name: "Medikal Bilgilerim",
            url: "/danisan/medikal-bilgilerim",
            icon: <BsFileMedical aria-hidden style={{ fontSize: "20px" }} />
        },
        {
            name: "Paketlerim",
            url: "/danisan/paketlerim",
            icon: <BiPackage aria-hidden style={{ fontSize: "20px" }} />
        },
        {
            name: "Tıbbi Belgelerim",
            url: "/danisan/belgelerim",
            icon: <BiFile aria-hidden style={{ fontSize: "20px" }} />
        }

    ]

    return (
        <Box >
            <div style={{ position: "absolute", height: "5px", width: "100%", zIndex: "5000" }}>
                <LinearProgress style={{ display: (props.store.loadingBarStatus) ? "none" : "block" }} color="primary" />
            </div>
            <Drawer sx={{
                width: { xs: drawerWidthMobile, md: drawerWidth },
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: { xs: drawerWidthMobile, md: drawerWidth },
                    boxSizing: 'border-box',
                    padding: "15px",
                    paddingTop: "0"
                }
            }}
                className="drawer-menu"
                variant="permanent"
                anchor="left">
                <Grid container spacing={0} style={{ marginTop: "15px", marginBottom: "10px" }}>
                    <Grid item xs={12} sx={{ display: { xs: "none", md: "block" } }}>
                        <a href="/danisan">
                            <img src='/assets/images/saglikcim-logo.jpg' alt="wholemedics" style={{ width: "100%", marginTop: "10px", marginLeft: "5px", marginBottom: "10px" }} />
                        </a>
                    </Grid>
                    <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
                        <a href="/danisan">
                            <img src='/assets/images/saglikcim-logo-mobile.jpg' alt="wholemedics" style={{ width: "40px", marginTop: "10px", marginLeft: "5px", marginBottom: "10px" }} />
                        </a>
                    </Grid>
                </Grid>

                <List key="main-pages" disablePadding style={{ marginTop: "0px" }}>
                    {pages.map((page, index) => (
                        (props.activePage === page.name) ?
                            <Link key={index} href={page.url}>
                                <ListItem disableGutters disablePadding style={{ background: "rgb(255, 249, 239)", borderRadius: "10px", marginTop: "5px" }}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {page.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={page.name} sx={{ color: "#000", fontWeight: "bold", display:{ xs: "none", md: "block" }}} />
                                    </ListItemButton>
                                </ListItem>
                            </Link> :
                            <Link key={index} href={page.url}>
                                <ListItem disableGutters disablePadding style={{ borderRadius: "10px", marginTop: "5px" }}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {page.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={page.name} sx={{ color: "#000", fontWeight: "400", display:{ xs: "none", md: "block" } }} />
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                    ))}
                </List>
                <Grid item xs={12} sx={{ display: { xs: "none", md: "block" } }}>
                    <a href="https://eczanem.saglikcim.net/" target="_blank" style={{ marginTop: "10px" }}>
                        <img src="/assets/images/eczanem.png" style={{ height: "40px", marginInlineStart: "15px", marginTop: '20px',  }} ></img>
                    </a>
                </Grid>
                <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
                    <a href="https://eczanem.saglikcim.net/" target="_blank">
                        <img src="/assets/images/eczanem-mobile.png" style={{ marginInlineStart: "8px", marginTop: '20px', height: "40px" }} ></img>
                    </a>
                </Grid>

            </Drawer>
            <MapissoAlert />
        </Box>
    );
}))
