import axios from "axios";

export const getDoctorPromos = async (baseUrl, token, patientId, doctorId) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null;

    await axios.get(baseUrl + "/patient/" + patientId + "/promos/" + doctorId, config)
        .then((res) => {
            getResponse = {
                status: res.status,
                data: res.data.data
            };
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        });

    return getResponse;
};

export const getPatientPromos = async (baseUrl, token, patientId) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null;

    await axios.get(baseUrl + "/patient/" + patientId + "/promos", config)
        .then((res) => {
            getResponse = {
                status: res.status,
                data: res.data.data
            };
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        });

    return getResponse;
};

export const insertPatientPromo = async (baseUrl, token, patientId, promoid) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null;

    await axios.post(baseUrl + "/patient/" + patientId + "/promo/" + promoid, {}, config)
        .then((res) => {
            getResponse = {
                status: res.status,
                data: res.data.data
            };
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        });

    return getResponse;
};