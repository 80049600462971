import axios from "axios";

const userRegisterNoCode = async (baseUrl, body) => {
    let userResponse = null
    await axios.post(baseUrl + `/patient/register-no-code`, body)
        .then((res) => {
            if (res.data.data !== null) {
                userResponse = {
                    status: res.data.status,
                    data: res.data.data
                }
            } else {
                userResponse = {
                    status: res.data.status,
                    data: res.data.message
                }
            }

        }).catch((error) => {
            if (error.response !== undefined) {
                userResponse = {
                    status: error.response.status
                }
            }
        });

    return userResponse
}

export default userRegisterNoCode;
