import { types } from "mobx-state-tree"

export const PatientAppointment = types
    .model({
        appointmentId: types.optional(types.integer, 0),
        doctorId: types.optional(types.number, 0),
        doctorName: types.optional(types.string, ""),
        doctorSurname: types.optional(types.string, ""),
        doctorTitle: types.optional(types.string, ""),
        doctorDepartment: types.optional(types.string, ""),
        doctorProfessional: types.optional(types.string, ""),
        address: types.optional(types.string, ""),
        appointmentTitle: types.optional(types.string, ""),
        appointmentDescription: types.optional(types.string, ""),
        appointmentDate: types.optional(types.Date, new Date()),
        status: types.optional(types.number, 0),
        barcode: types.optional(types.string, "")
    }).actions(self => ({

    }))
