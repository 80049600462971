import React, { useState, useEffect, useRef } from 'react';
import { observer, inject } from "mobx-react"
import dayjs from 'dayjs'

import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

import { Button, Grid, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IconButton, Tooltip } from '@mui/material';

import getPatientAppointmentExamination from '../../../utils/api/patient/Appointment/Info/GetPatientAppointmentExamination';
import getPatientAppointmentInsuranceForm from '../../../utils/api/patient/Appointment/Info/GetPatientAppointmentInsuranceForm';
import getPatientAppointmentPrescription from '../../../utils/api/patient/Appointment/Info/GetPatientAppointmentPrescription';
import getPatientAppointmentReport from '../../../utils/api/patient/Appointment/Info/GetPatientAppointmentReport';
import getPatientDoctorAppointmentList from '../../../utils/api/patient/Appointment/GetPatientDoctorAppointmentList'
import getPatientAppointmentFileList from '../../../utils/api/patient/Appointment/GetPatientAppointmentFileList'
import getPatientAppointmentImage from '../../../utils/api/patient/Appointment/Info/GetPatientAppointmentImage'

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArticleIcon from '@mui/icons-material/Article';

import { ChatView } from '../chat/ChatView';

export const RandevuBilgiMenu = inject("store")(observer((props) => {

    const [appointment, setAppointment] = useState(null)

    const [diagnosisList, setDiagnosisList] = useState(null)
    const [examinationList, setExaminationList] = useState(null)
    const [insuranceFormList, setInsuranceFormList] = useState(null)
    const [prescriptionList, setPrescriptionList] = useState(null)
    const [reportList, setReportList] = useState(null)
    const [fileList, setFileList] = useState(null)
    const [imageTalepList, setImageTalepList] = useState(null)

    const fileTypeList = useRef([
        { id: 0, name: "Belirsiz", show: false },
        { id: 1, name: "Doktor Diploması", show: false },
        { id: 2, name: "Doktor Kaşesi", show: false },
        { id: 3, name: "Hasta Tahlil", show: true },
        { id: 4, name: "Klinik", show: false },
        { id: 5, name: "Bilgisayarlı Tomografi", show: true },
        { id: 6, name: "Ultrason", show: true },
        { id: 7, name: "Renkli Doppler Ultrason", show: true },
        { id: 8, name: "Düz Grafi", show: true },
        { id: 9, name: "Doktor Avatar", show: false },
        { id: 10, name: "Özel Grafiler", show: true },
        { id: 11, name: "Manyetik Rezonans", show: true },
        { id: 12, name: "Hasta Fotoğrafı", show: false }
    ])

    useEffect(() => {
        // Update the document title using the browser API
        getPatientDoctorAppointmentListReq()
        getPatientPrescriptionListReq()
        getPatientReportListReq()
        getPatientInsuranceFormListReq()
        getPatientExaminationListReq()
        getAppointmentFileListReq()
        getPatientImageListReq()
        // eslint-disable-next-line
    }, []);

    const getPatientDoctorAppointmentListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let startDate = dayjs(new Date()).subtract(1, 'year').format('YYYY-MM-DD');
        let endDate = dayjs(new Date()).format('YYYY-MM-DD');

        let patientDoctorAppointmentListResponse = await getPatientDoctorAppointmentList(props.store.baseUrl, props.store.token, props.store.patient[0].id,
            props.doctorId, startDate, endDate)

        if (patientDoctorAppointmentListResponse !== undefined && patientDoctorAppointmentListResponse !== null) {
            if (patientDoctorAppointmentListResponse.status === 200) {
                //console.log("patientDoctorAppointmentListResponse: ", patientDoctorAppointmentListResponse)
                let appointmentList = patientDoctorAppointmentListResponse.data

                for (let index = 0; index < appointmentList.length; index++) {
                    const appointmentTemp = appointmentList[index];

                    if (appointmentTemp.appointmentId === props.appointmentId) {
                        //console.log("appointmentTemp: ", appointmentTemp)
                        setAppointment(appointmentTemp)
                    }
                }
            } else if (patientDoctorAppointmentListResponse.status === 401) {
                props.store.setUserLogout()
                props.history.push("/danisan/kullanici-girisi")
            }
        }

        props.store.setLoadingBarStatus(true)
    }


    const getPatientExaminationListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let patientExaminationListResponse = await getPatientAppointmentExamination(props.store.baseUrl, props.store.token, props.store.patient[0].id, props.appointmentId)
        if (patientExaminationListResponse !== null && patientExaminationListResponse !== undefined) {
            if (patientExaminationListResponse.status === 200) {
                //console.log("patientExaminationListResponse.data: ", patientExaminationListResponse.data)
                setExaminationList(patientExaminationListResponse.data)
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const getPatientImageListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let patientImageResponse = await getPatientAppointmentImage(props.store.baseUrl, props.store.token, props.store.patient[0].id, props.appointmentId)
        if (patientImageResponse !== null && patientImageResponse !== undefined) {
            if (patientImageResponse.status === 200) {
                if (patientImageResponse.data !== null) {
                    setImageTalepList(patientImageResponse.data)
                } else {
                    setImageTalepList(null)
                }
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const getPatientInsuranceFormListReq = async () => {
        props.store.setLoadingBarStatus(false)
        let patientInsuranceFormListResponse = await getPatientAppointmentInsuranceForm(props.store.baseUrl, props.store.token, props.store.patient[0].id, props.appointmentId)
        if (patientInsuranceFormListResponse !== null && patientInsuranceFormListResponse !== undefined) {
            if (patientInsuranceFormListResponse.status === 200) {
                //console.log("patientInsuranceFormListResponse.data: ", patientInsuranceFormListResponse.data)
                if (patientInsuranceFormListResponse.data !== null) {
                    setInsuranceFormList([patientInsuranceFormListResponse.data])
                }
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const getPatientPrescriptionListReq = async () => {
        props.store.setLoadingBarStatus(false)
        let patientPrescriptionListResponse = await getPatientAppointmentPrescription(props.store.baseUrl, props.store.token, props.store.patient[0].id, props.appointmentId)
        if (patientPrescriptionListResponse !== null && patientPrescriptionListResponse !== undefined) {
            if (patientPrescriptionListResponse.status === 200) {
                if (patientPrescriptionListResponse.data !== null) {
                    console.log("patientPrescriptionListResponse.data: ", patientPrescriptionListResponse.data)
                    if (patientPrescriptionListResponse.data !== null) {
                        setPrescriptionList(patientPrescriptionListResponse.data)
                        setDiagnosisList([{
                            diagnosisMainCode: patientPrescriptionListResponse.data.diagnosisMainCode,
                            diagnosisCode: patientPrescriptionListResponse.data.diagnosisCode,
                            diagnosisName: patientPrescriptionListResponse.data.diagnosisName
                        }])
                    }
                }
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const getPatientReportListReq = async () => {
        props.store.setLoadingBarStatus(false)
        let patientReportListResponse = await getPatientAppointmentReport(props.store.baseUrl, props.store.token, props.store.patient[0].id, props.appointmentId)
        if (patientReportListResponse !== null && patientReportListResponse !== undefined) {
            if (patientReportListResponse.status === 200) {
                //console.log("patientReportListResponse.data: ", patientReportListResponse.data)
                if (patientReportListResponse.data !== null) {
                    setReportList([patientReportListResponse.data])
                }
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const getAppointmentFileListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getFileListResponse = await getPatientAppointmentFileList(props.store.baseUrl, props.store.token, props.store.patient[0].id, props.appointmentId)
        //console.log("getFileListResponse: ", getFileListResponse)
        if (getFileListResponse !== null && getFileListResponse !== undefined) {
            if (getFileListResponse.status === 200) {
                let fileList = []
                for (let i = 0; i < getFileListResponse.data.length; i++) {
                    const fileTemp = getFileListResponse.data[i];
                    let fileNameArray = fileTemp.fileName.split("/");
                    let fileName = fileNameArray[fileNameArray.length - 1]
                    console.log("fileName: ", fileName)
                    fileTemp.name = fileName
                    fileList.push(fileTemp)
                }
                setFileList(fileList)
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <Typography variant='body1' style={{ fontWeight: "bold" }}>Randevu Bilgisi</Typography>
                    <TableContainer style={{ width: "100%" }}>
                        <Table aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">RANDEVU BİLGİSİ</TableCell>
                                    <TableCell align="left">DOKTOR BİLGİSİ</TableCell>
                                    <TableCell align="left">UZMANLIK ALANI</TableCell>
                                    <TableCell align="left">RANDEVU TARİHİ</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={"randevu-bilgisi"}>
                                    <TableCell align='left'>{appointment?.appointmentTitle + ": " + appointment?.appointmentDescription}</TableCell>
                                    <TableCell component="th" scope="row">
                                        {appointment?.doctorTitle + " Doktor " + appointment?.doctorName}  {appointment?.doctorSurname}
                                    </TableCell>

                                    <TableCell align='left'>{appointment?.doctorDepartment}</TableCell>
                                    <TableCell align="left">{dayjs(new Date(appointment?.appointmentDate)).format("DD.MM.YYYY HH:mm")}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant='body1' style={{ fontWeight: "bold", marginTop: "10px" }}>Tanı Bilgisi</Typography>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>TANI ANA KODU</TableCell>
                                    <TableCell>TANI KODU</TableCell>
                                    <TableCell>TANI İSMİ</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(diagnosisList !== null) ?
                                    diagnosisList.map((diagnosis, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell>{(diagnosis !== null) ? diagnosis.diagnosisMainCode : ""}</TableCell>
                                            <TableCell>{(diagnosis !== null) ? diagnosis.diagnosisCode : "-"}</TableCell>
                                            <TableCell>{(diagnosis !== null) ? diagnosis.diagnosisName : ""}</TableCell>
                                        </TableRow>
                                    )) :
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant='body2'>
                                                Bu görüşmede kayıt altına alınmış bir tanı bulunmamaktadır
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant='body1' style={{ fontWeight: "bold", marginTop: "10px" }}>Reçete Bilgisi</Typography>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>İLAÇ ADI</TableCell>
                                    <TableCell>PERİYOT</TableCell>
                                    <TableCell>DOZ</TableCell>
                                    <TableCell>ADET</TableCell>
                                    <TableCell>İŞLEMLER</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(prescriptionList !== null) ?
                                    (prescriptionList.medicines !== null) ?
                                        prescriptionList.medicines.map((recete, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell>{recete.medicineName}</TableCell>
                                                <TableCell>{recete.medicinePeriod}</TableCell>
                                                <TableCell>{recete.medicineDose}</TableCell>
                                                <TableCell>{recete.medicineCount}</TableCell>
                                                <TableCell>
                                                    <Tooltip title="Reçete Görüntüle">
                                                        <IconButton color="primary" aria-label="upload picture" component={RouterLink}
                                                            target="_blank" to={"/danisan/recete/" + prescriptionList.appointmentId} >
                                                            <ArticleIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        :
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant='body2'>
                                                    Bu görüşmede kayıt altına alınmış bir reçete bulunmamaktadır
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    :
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant='body2'>
                                                Bu görüşmede kayıt altına alınmış bir reçete bulunmamaktadır
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant='body1' style={{ fontWeight: "bold", marginTop: "10px" }}>Rapor Bilgisi</Typography>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>RAPOR AÇIKLAMA</TableCell>
                                    <TableCell>RAPOR TİP</TableCell>
                                    <TableCell>RAPOR TARİHİ</TableCell>
                                    <TableCell>İŞLEMLER</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(reportList !== null) ?
                                    reportList.map((report, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell>{report?.reportDecision}</TableCell>
                                            <TableCell>{(report?.reportType === 1) ? "Doğum Raporu" : (report?.reportType === 2) ? "Durum Raporu" : "İş Göremezlik Raporu"}</TableCell>
                                            <TableCell>{dayjs(new Date(report?.reportStartDate)).format("DD.MM.YYYY") + " - " + dayjs(new Date(report?.reportEndDate)).format("DD.MM.YYYY")}</TableCell>
                                            <TableCell>
                                                <Tooltip title="Rapor Görüntüle">
                                                    <IconButton color="primary" aria-label="upload picture" component={RouterLink}
                                                        target="_blank" to={"/danisan/rapor/" + report.appointmentId} >
                                                        <ArticleIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    :
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant='body2'>
                                                Bu görüşmede kayıt altına alınmış bir rapor bulunmamaktadır
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography variant='body1' style={{ fontWeight: "bold", marginTop: "10px" }}>Sigorta Bilgi Formu Bilgisi</Typography>
                    <TableContainer>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>RANDEVU ZAMANI</TableCell>
                                        <TableCell align="right">DOKTOR ADI SOYADI</TableCell>
                                        <TableCell align="right">DOKTOR UZMANLIK ALANI</TableCell>
                                        <TableCell align="right">TANI BİLGİSİ</TableCell>
                                        <TableCell align="right">İŞLEMLER</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(insuranceFormList !== null) ? insuranceFormList.map((sbf, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell component="th" scope="row">
                                                {dayjs(new Date(sbf.appliedDate)).format("DD.MM.YYYY HH:mm")}
                                            </TableCell>
                                            <TableCell align="right">{sbf.doctorName + " " + sbf.doctorSurname}</TableCell>
                                            <TableCell align="right">{sbf.doctorDepartmentName}</TableCell>
                                            <TableCell align="right">{sbf.diagnosis}</TableCell>
                                            <TableCell align='right'>
                                                <Tooltip title="Sigorta Bilgi Formu Görüntüle">
                                                    <IconButton color="primary" aria-label="upload picture" component={RouterLink}
                                                        to={"/danisan/sbf/" + sbf.appointmentId} target="_blank">
                                                        <ArticleIcon />
                                                    </IconButton>

                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )) :
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant='body2'>
                                                    Bu görüşmede kayıt altına alınmış bir sigorta bilgi formu bilgisi bulunmamaktadır
                                                </Typography>
                                            </TableCell>
                                        </TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableContainer>

                    <Typography variant='body1' style={{ fontWeight: "bold", marginTop: "10px" }}>Tetkik Bilgisi</Typography>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>TETKİK İSMİ</TableCell>
                                    <TableCell>TETKİK KATEGORİ</TableCell>
                                    <TableCell>TALEP TARİHİ</TableCell>
                                    <TableCell>TETKİK LİSTE FİYATI</TableCell>
                                    <TableCell>TETKİK GERÇEKLEŞEN FİYATI</TableCell>
                                    <TableCell>İŞLEMLER</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(examinationList !== null) ?
                                    examinationList.map((tetkik, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell>{tetkik.examinationDesc}</TableCell>
                                            <TableCell>{tetkik.categoryName}</TableCell>
                                            <TableCell>{tetkik.appointmentDate}</TableCell>
                                            <TableCell>{tetkik.estimatedPrice} TL</TableCell>
                                            <TableCell>{tetkik.actualPrice} TL</TableCell>
                                            <TableCell>
                                                <Tooltip title="Tetkik Sonuçlarını Görüntüle">
                                                    <IconButton color="primary" aria-label="upload picture"
                                                        component={Link} disabled={(tetkik.examFiles !== null) ? false : true}
                                                        href={(tetkik.examFiles !== null) ? props.store.baseUrl + tetkik.examFiles[0] : ""} target="_blank">
                                                        <ArticleIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    :
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant='body2'>
                                                Bu görüşmede kayıt altına alınmış bir tetkik bilgisi bulunmamaktadır
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography variant='body1' style={{ fontWeight: "bold", marginTop: "10px" }}>Görüntüleme İsteği</Typography>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>GÖRÜNTÜLEME TALEP İSMİ</TableCell>
                                    <TableCell>TALEP TARİHİ</TableCell>
                                    <TableCell>İŞLEMLER</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(imageTalepList !== null) ?
                                    imageTalepList.map((image, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell>{image.imageName}</TableCell>
                                            <TableCell>***</TableCell>
                                            <TableCell>***</TableCell>
                                        </TableRow>
                                    ))
                                    :
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant='body2'>
                                                Bu görüşmede kayıt altına alınmış bir tetkik bilgisi bulunmamaktadır
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography variant='body1' style={{ fontWeight: "bold", marginTop: "10px" }}>Randevu Kapsamındaki Dosya Listesi</Typography>

                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>DOSYA ADI</TableCell>
                                    <TableCell>KATEGORİ</TableCell>
                                    <TableCell align="right">YÜKLEME TARİHİ</TableCell>
                                    <TableCell align="right">İŞLEMLER</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(fileList !== null) ? fileList.map((file, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell component="th" scope="row">
                                            {file?.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {fileTypeList.current.map((fileType, idx) => (
                                                (fileType.id === file.fileType) ?
                                                    <Typography>{fileType.name}</Typography> : null
                                            ))}
                                        </TableCell>
                                        <TableCell align="right">
                                            {dayjs(new Date(file?.createdDate)).format("DD.MM.YYYY HH:MM")}

                                        </TableCell>
                                        <TableCell align='right'>
                                            <Tooltip title="Dosyayı Görüntüle">
                                                <IconButton color="primary">
                                                    <Link href={props.store.baseUrl + file?.fileName} target="_blank"
                                                        variant="body2" style={{ paddingTop: "8px" }}>
                                                        <ArticleIcon color='primary' />
                                                    </Link>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )) :
                                    <TableRow>
                                        <TableCell>
                                            Henüz dosya kayıt edilmemiştir.
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                    <Button color="primary" variant='contained' component={RouterLink} to="/danisan/randevularim/1"
                        style={{ marginBottom: "15px" }}>
                        <ArrowLeftIcon /> Geri Dön
                    </Button>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Görüşme esnasında gönderilen mesajlar</Typography>
                    <ChatView appointmentId={props.appointmentId} />
                </Grid>
            </Grid>
        </>
    )
}))