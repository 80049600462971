import axios from "axios";

const userSmsSendAgain = async (baseUrl, patientId) => {

    let userResponse = null

    ///patient/{patientid}/register/{code}/complete

    await axios.get(baseUrl + `/patient/` + patientId + "/code", {})
        .then((res) => {
            userResponse = {
                status: res.data.status,
                data: res.data
            }
        }).catch((error) => {
            //console.log("error: ", error)
            if (error.response !== undefined) {
                userResponse = {
                    status: error.response.status
                }
            } 
        });

    console.log("user response: ", userResponse)
    return userResponse
}

export default userSmsSendAgain;
