import React, { Component } from "react";
import { observer, inject } from "mobx-react"
import PropTypes from 'prop-types';

import axios from "axios";
import Dropzone from "./dropzone/dropzone";
import "./upload.css";
import Progress from "./progress/Progress";

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MapissoAlert from '../common/MapissoAlert'

import getPatientFileList from '../../utils/api/patient/File/GetPatientFileList'

class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            uploading: false,
            uploadProgress: {},
            successfullUploaded: false
        };

        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.renderActions = this.renderActions.bind(this);
    }

    componentDidMount = async () => {

    }

    onFilesAdded(files) {
        this.setState(prevState => ({
            files: prevState.files.concat(files)
        }));
    }

    async uploadFiles() {
        this.setState({ uploadProgress: {}, uploading: true });
        const promises = [];

        this.state.files.forEach(file => {
            promises.push(this.sendRequest(file));
        });

        try {
            await Promise.all(promises);

            this.setState({ successfullUploaded: true, uploading: false });
        } catch (e) {
            // Not Production ready! Do some error handling here instead...
            this.setState({ successfullUploaded: true, uploading: false });
        }
    }

    sendRequest = async (file) => {
        let result = null;

        //console.log("file: ", file) 

        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileType", this.props.fileType)
        //formData.append("doctorid", this.props.doctorId)
        //formData.append("appointmentid", this.props.appointmentId)

        // console.log("UPLOAD objectId: ", this.props.store.selectedUploadObjectId)
        //console.log("UPLOAD kyaId: ", this.props.kyaId)
        //console.log("evrak tip: ", this.props.store.selectedUploadEvrakTip)
        if (this.props.fileType !== 0) {
            let uploadReqUrl = this.props.store.baseUrl + "/patient/" + this.props.store.patient[0].id + "/file/upload/" 
            console.log("uploadReqUrl: ", uploadReqUrl)

            const config = {
                headers: {
                    Authorization: `Bearer ${this.props.store.token}`,
                    'Content-Type': 'multipart/form-data'
                }
            };
            //console.log("uploadReqUrl: ", uploadReqUrl)

            if (uploadReqUrl !== "") {
                this.props.store.setLoadingBarStatus(false)
                await axios.post(uploadReqUrl, formData, config).then(async (res) => {
                    result = res;
                    const copy = { ...this.state.uploadProgress }
                    copy[file.name] = { state: "done", percentage: 100 }
                    //console.log(copy)
                    //console.log(copy)
                    this.setState({ uploadProgress: copy })
                    console.log("result: ", result.data)
                    console.log("this.props.fileType: ", this.props.fileType)
                    //this.props.store.setUploadedObject(this.props.store.baseUrl + "/" + result.data.data)
                    this.props.store.setMessage("Dosya başarıyla yüklendi", true, "success")
                    this.getFileListReq()
                }).catch((error) => {
                    //console.log("errora geldi..")
                    console.log(error)
                    result = null
                    const copy = { ...this.state.uploadProgress };
                    copy[file.name] = { state: "error", percentage: 0 };
                    this.setState({ uploadProgress: copy });
                    this.props.store.setUploadStatus(false)
                });

                this.props.store.setLoadingBarStatus(true)
            }
        }else{
            this.props.store.setMessage("Dosya kategorisi seçmeden dosya yüklemesi yapamazsınız.", true, "error")
        }

        return result;
    }

    renderProgress(file) {
        const uploadProgress = this.state.uploadProgress[file.name];
        //console.log("uploadProgress")
        console.log(uploadProgress)
        if (this.state.uploading || this.state.successfullUploaded) {
            return (
                <div className="ProgressWrapper">
                    <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
                </div>
            );
        }
    }

    deleteAllData() {
        this.setState({ files: [], successfullUploaded: false })
        //this.props.store.setUploadStatus(false)
    }

    getFileListReq = async () => {
        this.props.store.setLoadingBarStatus(false)

        let getFileListResponse = await getPatientFileList(this.props.store.baseUrl, this.props.store.token, this.props.store.patient[0].id)
        //console.log("getFileListResponse: ", getFileListResponse)
        if (getFileListResponse !== null && getFileListResponse !== undefined) {
            if (getFileListResponse.status === 200) {
                let fileList = []
                for (let i = 0; i < getFileListResponse.data.length; i++) {
                    const fileTemp = getFileListResponse.data[i];
                    let fileNameArray = fileTemp.fileName.split("/");
                    let fileName = fileNameArray[fileNameArray.length - 1]
                    //console.log("fileName: ", fileName)
                    fileTemp.name = fileName
                    fileList.push(fileTemp)
                }
                this.props.setFileList(fileList)
            }
        }

        this.props.store.setLoadingBarStatus(true)
    }

    renderActions() {
        if (this.state.successfullUploaded) {
            return (
                <Button color="error" variant="contained" onClick={() => this.deleteAllData()}>
                    <DeleteIcon /> <span style={{ marginRight: "5px" }}>TEMİZLE</span>
                </Button>
            );
        } else {
            return (
                <Button color="primary" variant="contained" disabled={this.state.files.length < 0 || this.state.uploading} onClick={this.uploadFiles}
                    style={{color: "#000"}}>
                    <CloudUploadIcon style={{ marginRight: "5px" }} /> Dosya Yükle
                </Button>
            );
        }
    }

    render() {
        return (
            <>
            <div className={(this.props.store.darkMode) ? "Upload upload-dark" : "Upload"} style={{ width: "100%" }}>
                
                <div className="Content">
                    <div>
                        <Dropzone onFilesAdded={this.onFilesAdded} disabled={this.state.uploading || this.state.successfullUploaded} />
                    </div>
                    <div className="Files">
                        {this.state.files.map(file => {
                            return (
                                <div key={file.name} className="Row">
                                    <span className="Filename">{file.name}</span>
                                    {this.renderProgress(file)}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <MapissoAlert />
            </div>
            <div className="Actions">{this.renderActions()}</div>
            </>
        );
    }
}

Upload.propTypes = {
    objectId: PropTypes.number,
    evrakTip: PropTypes.string
}

export default inject("store")(observer(Upload));