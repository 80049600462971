import axios from "axios";

const deletePatientInsuranceInfo = async (baseUrl, token, patientId, insuranceId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let deleteResponse = null

    await axios.delete(baseUrl + "/patient/" + patientId + "/insurance/" + insuranceId , config)
        .then((res) => {
            deleteResponse = {
                status: res.data.status,
                result: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                deleteResponse = {
                    status: error.response.status,
                    result:  error.response.status
                }
            }
        });
    return deleteResponse
}

export default deletePatientInsuranceInfo;