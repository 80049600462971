import React from "react";
const PromoCard = ({ promo, onClick }) => {
  return (
    <div className="promo-card">
      <div className="promo-card__count">{`${promo.count} adet randevu`}</div>
      <div className="promo-card__price">{promo.price + "TL"}</div>
      <div className="promo-card__discount">{`%${promo.discount} İndirim`}</div>
      <div className="promo-card__pad">{promo.priceAfterDiscount + "TL"}</div>
      {promo.description && (
        <div className="promo-card__desc">{promo.description}</div>
      )}
      <button onClick={()=>onClick()} className="promo-card__button">Satın Al</button>
    </div>
  );
};
export default PromoCard;
