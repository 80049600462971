import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import { LeftMenu } from '../components/common/LeftMenu';

import { Box, Button, Card, CardHeader, Container, Grid, IconButton, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import { PickersDay } from '@mui/x-date-pickers';

import RecentActorsIcon from '@mui/icons-material/RecentActors';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import 'dayjs/locale/tr';

import { ProfileButtonMenu } from '../components/common/ProfileButtonMenu';
import { TopSearchMenu } from '../components/danisan/search/TopSearchMenu';
import { Footer } from '../components/common/Footer';

import getPatientAppointmentList from '../utils/api/patient/Appointment/GetPatientAppointmentList'
import { professionals } from '../components/common/List';

class DashboardPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dashboardStatics: null,
            todayAppointmentList: [],
            date: dayjs(new Date()),
            profileMenuDisplay: false,
            profileMenuTarget: null,
            passedAppointmentList: [],
            futureAppointmentList: [],
            customDayRenderer: (
                date,
                selectedDates,
                pickersDayProps
            ) => {
                const stringifiedDate = date.toISOString().slice(0, 10);
                if ([].includes(stringifiedDate)) {
                    return <PickersDay {...pickersDayProps} selected />;
                }
                return <PickersDay {...pickersDayProps} />;
            }
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/danisan/kullanici-girisi");
        } else {
            this.props.store.setLoadingBarStatus(false)

            let startDate = dayjs(new Date()).subtract(1, 'year').format('YYYY-MM-DD');
            let endDate = dayjs(new Date()).format('YYYY-MM-DD');

            let passedAppointmentListResponse = await getPatientAppointmentList(this.props.store.baseUrl, this.props.store.token,
                this.props.store.patient[0].id, startDate, endDate)

            //console.log("passedAppointmentListResponse: ", passedAppointmentListResponse)

            if (passedAppointmentListResponse !== undefined && passedAppointmentListResponse !== null) {
                if (passedAppointmentListResponse.status === 200) {


                    let passedAppointmentList = passedAppointmentListResponse.data

                    let fixedAppointmentList = []
                    for (let index = 0; index < passedAppointmentList.length; index++) {
                        const appointment = passedAppointmentList[index];
                        //console.log("appointment: ", appointment.status)
                        if (appointment.status > 2 && appointment.status < 8) {
                            fixedAppointmentList.push(appointment)
                        }

                    }
                    this.setState({
                        passedAppointmentList: fixedAppointmentList
                    })

                } else if (passedAppointmentListResponse.status === 401) {
                    this.props.store.setUserLogout()
                    this.props.history.push("/danisan/kullanici-girisi");
                }
            }

            startDate = dayjs(new Date()).format('YYYY-MM-DD');
            endDate = dayjs(new Date()).add(1, 'year').format('YYYY-MM-DD');;

            let futureAppointmentListResponse = await getPatientAppointmentList(this.props.store.baseUrl, this.props.store.token,
                this.props.store.patient[0].id, startDate, endDate)

            //console.log("futureAppointmentList: ", futureAppointmentList)

            if (futureAppointmentListResponse !== undefined && futureAppointmentListResponse !== null) {
                if (futureAppointmentListResponse.status === 200) {

                    let futureAppointmentList = futureAppointmentListResponse.data

                    let fixedAppointmentList = []
                    for (let index = 0; index < futureAppointmentList.length; index++) {
                        const appointment = futureAppointmentList[index];
                        //console.log("appointment: ", appointment.status)
                        if (appointment.status < 3) {
                            fixedAppointmentList.push(appointment)
                        }

                    }
                    this.setState({
                        futureAppointmentList: fixedAppointmentList
                    })

                    let dateList = []
                    for (let index = 0; index < fixedAppointmentList.length; index++) {
                        let appointmentDate = dayjs(fixedAppointmentList[index].appointmentDate).subtract(1, "day");
                        appointmentDate = appointmentDate.toISOString().slice(0, 10);
                        if (!dateList.includes(appointmentDate)) {
                            dateList.push(appointmentDate)
                        }
                    }

                    //console.log("dateList: ", dateList)
                    this.setState({
                        customDayRenderer: (
                            date,
                            selectedDates,
                            pickersDayProps
                        ) => {
                            const stringifiedDate = date.toISOString().slice(0, 10);
                            if (dateList.includes(stringifiedDate)) {
                                return <PickersDay {...pickersDayProps} selected />;
                            } else {
                                return <PickersDay {...pickersDayProps} />;
                            }

                        }
                    })
                }
            }

            this.props.store.setLoadingBarStatus(true)
        }
    }

    onClickSearchPage = (kategori) => {
        this.props.history.push("/danisan/doktor-arama?kategori=" + kategori)
    }

    getLastDayOfYear(year) {
        return new Date(year, 11, 31);
    }



    render() {
        return (
            <div className="container mps-container">
                <LeftMenu activePage="Ana Sayfa" />
                <Box component="main" sx={{
                    width: { xs: "calc(100% - 90px)", md: "calc(100% - 235px)" }, marginLeft: { xs: "90px", md: "235px" },
                    flexGrow: 1,
                    py: 8
                }} style={{  maxHeight: "100vh", paddingTop: "10px" }} >
                    <Container style={{ padding: "0", marginBottom: "100px" }}>
                        <Grid container spacing={0} style={{ marginBottom: "0px", flexWrap: "wrap-reverse" }}>
                            <Grid item lg={8} sm={6} xl={8} xs={12}>
                                <TopSearchMenu history={this.props.history} />
                            </Grid>
                            <Grid item lg={4} sm={6} xl={4} xs={12}>
                                <ProfileButtonMenu />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} style={{ marginTop: "15px" }}>
                            <Grid item lg={8} sm={6} xl={8} xs={12}>
                                <Grid container spacing={3}>
                                    {professionals.map((item) => (
                                        <Grid key={item.id} item lg={4} sm={12} xl={4} xs={12}>
                                            <Card style={{ borderRadius: "30px" }} onClick={() => this.onClickSearchPage(item.slug)}>
                                                <CardActionArea>
                                                    <CardContent style={{ padding: 30, textAlign: "center" }}>
                                                        <img src={item.img} style={{ maxHeight: "190px" }} alt={item.name} />
                                                        <Typography gutterBottom variant="body1" component="div" style={{ fontWeight: "bold", marginTop: "15px" }}>
                                                            {item.name + ' Bul'}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>))}
                                    {/**
                                     *
                                    
                                    <Grid item lg={6} sm={12} xl={6} xs={12} >
                                        <Typography variant='h6' style={{ marginTop: "15px" }}>Sağlıklı Blog Yazılarımız</Typography>
                                        <Card sx={{ display: 'flex', marginTop: "10px", boxShadow: "none", border: "0px solid #eaeaea", background: "#fff9ef" }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 150 }}
                                                image="/assets/images/health.webp"
                                                alt="blog yazı"
                                            />
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: "calc(100% - 150px)", padding: "10px" }}>
                                                <CardContent sx={{ flex: '1 0 auto' }} style={{ padding: "10px", width: "100%" }}>
                                                    <Typography component="div" variant="body1">
                                                        Karaciğer sağlığına dikkat
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.secondary" component="div">
                                                        Dr. Ercan Seker
                                                    </Typography>
                                                </CardContent>
                                                <CardActions style={{ marginLeft: "auto" }}>
                                                    <Button variant='contained' >
                                                        Devamını oku...
                                                    </Button>
                                                </CardActions>
                                            </Box>
                                        </Card>
                                        <Card sx={{ display: 'flex', marginTop: "10px", boxShadow: "none", border: "0px solid #eaeaea", background: "#fff9ef" }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 150 }}
                                                image="/assets/images/health2.png"
                                                alt="blog yazı"
                                            />
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: "calc(100% - 150px)", padding: "10px" }}>
                                                <CardContent sx={{ flex: '1 0 auto' }} style={{ padding: "10px", width: "100%" }}>
                                                    <Typography component="div" variant="body1">
                                                        Diyet yaparken dikkat
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.secondary" component="div">
                                                        Diyetisyen Ercan Seker
                                                    </Typography>
                                                </CardContent>
                                                <CardActions style={{ marginLeft: "auto" }}>
                                                    <Button variant='contained' >
                                                        Devamını oku...
                                                    </Button>
                                                </CardActions>
                                            </Box>
                                        </Card>
                                        <Button style={{ float: "right" }}>
                                            Tüm blog yazılarını görüntüle
                                        </Button>
                                    </Grid>
                                     */}
                                    <Grid item lg={12} sm={12} xl={12} xs={12} >
                                        <Typography variant='h6' style={{ marginTop: "28px", marginBottom: "10px" }}>Geçmiş Görüşmeleriniz</Typography>
                                        {(this.state.passedAppointmentList.length > 0) ?
                                            <>
                                                <TableContainer style={{ width: "100%" }}>
                                                    <Table aria-label="simple table" >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>DOKTOR BİLGİSİ</TableCell>
                                                                <TableCell align="right">RANDEVU TARİHİ</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.passedAppointmentList.map((appointment, idx) => (
                                                                (idx < 4) ?
                                                                    <TableRow key={idx}>
                                                                        <TableCell component="th" scope="row">
                                                                            {appointment.doctorTitle} {appointment.doctorProfessional} {appointment.doctorName}  {appointment.doctorSurname}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {dayjs(appointment.appointmentDate).format("DD/MM/YYYY HH:mm")}</TableCell>

                                                                    </TableRow> : null
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <Button color='primary' style={{ float: "right", color: "#000" }} component={Link} to={"/danisan/randevularim/1"}>
                                                    <RecentActorsIcon style={{ marginRight: "10px" }} /> Tümünü Görüntüle
                                                </Button>
                                            </>
                                            : <Typography>
                                                Henüz geçmiş randevunuz olmadığı için burada bir şey gösteremiyoruz. <br /><br />
                                                Hemen randevu oluştur ve sağlık sorunlarını hızlıca çöz.
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={4} sm={6} xl={4} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} style={{ width: "100%" }}>
                                            <CalendarPicker
                                                value={this.state.date}
                                                onChange={(newDate) => this.setState({ date: newDate })}
                                                dayOfWeekFormatter={(day) => `${day}.`}
                                                toolbarFormat="DD MMMM dddd"
                                                renderDay={this.state.customDayRenderer}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} style={{ paddingLeft: "0px", paddingTop: "0" }} >
                                        <Card style={{ borderRadius: "30px", boxShadow: "none", paddingTop: "0", marginTop: "15px" }} elevation={24}>
                                            <CardHeader title="Randevularım" style={{ paddingBottom: "10px", paddingTop: "0" }}>
                                            </CardHeader>
                                            <CardContent style={{ paddingTop: "0", textAlign: "left", paddingBottom: "10px" }}>
                                                {(this.state.futureAppointmentList.length > 0) ?
                                                    <>
                                                        <TableContainer style={{ width: "100%" }}>
                                                            <Table aria-label="simple table" >
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>DOKTOR BİLGİSİ</TableCell>
                                                                        <TableCell align="right">RANDEVU TARİHİ</TableCell>
                                                                        <TableCell align="right"></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {this.state.futureAppointmentList.map((appointment, idx) => (
                                                                        (idx < 3) ?
                                                                            <TableRow key={idx}>
                                                                                <TableCell component="th" scope="row">
                                                                                    {(appointment.doctorTitle !== null && appointment.doctorTitle !== undefined) ? appointment.doctorTitle + " " : ""}
                                                                                    {(appointment.doctorProfessional !== null && appointment.doctorProfessional !== undefined) ? appointment.doctorProfessional + " " : ""}
                                                                                    {appointment.doctorName}  {appointment.doctorSurname}
                                                                                </TableCell>
                                                                                <TableCell align="right">{dayjs(appointment.appointmentDate).format("DD/MM/YYYY HH:mm")}</TableCell>
                                                                                <TableCell align="right">
                                                                                    <Tooltip title="Görüşme Odasına Gir">
                                                                                        <IconButton color="primary" size='small' component={Link} to={"/danisan/gorusme/" + appointment.doctorId + "/" + appointment.appointmentId}>
                                                                                            <VideoCameraFrontIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </TableCell>
                                                                            </TableRow> : null
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        <Button variant='contained' color='primary' style={{ float: "right" }} component={Link} to={"/danisan/randevularim/0"}>
                                                            <RecentActorsIcon style={{ marginRight: "10px" }} /> Tüm Randevularım
                                                        </Button>
                                                    </>
                                                    : <Typography>
                                                        Aktif randevunuz olmadığı için burada gösteremiyoruz. <br />
                                                        Hemen randevu oluştur ve sağlık sorunlarını hızlıca çöz.
                                                    </Typography>
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>

                    </Container>
                    <Footer />
                </Box>

            </div>
        )
    }
}

export default inject("store")(observer(DashboardPage));
