import axios from "axios";

const putForStartVideoCall = async (baseUrl, token, patientId, appointmentId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    await axios.put(baseUrl + "/patient/" + patientId + "/appointment/" + appointmentId + "/start", {}, config)
        .then((res) => {
            putResponse = {
                status: res.data.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    data: error.response.status
                }
            }
        });
    return putResponse
}

export default putForStartVideoCall;