import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Card, CardContent, Grid, Typography, Link, Button } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getPatientPromos } from "../../../utils/api/patient/Promos";
import dayjs from "dayjs";

export const PaketInnerMenu = inject("store")(
    observer((props) => {
        const [promoList, setPromoList] = useState([]);
        const [pastPromoList, setPastPromoList] = useState([]);

        useEffect(() => {
            getPatPromos();
        }, []);

        const getPatPromos = async () => {
            props.store.setLoadingBarStatus(false);
            let getPromoListRes = await getPatientPromos(
                props.store.baseUrl,
                props.store.token,
                props.store.patient[0].id
            );
            if (getPromoListRes !== null && getPromoListRes !== undefined) {
                if (getPromoListRes.status === 200) {
                    if (getPromoListRes.data !== null) {
                        setPromoList(getPromoListRes.data.filter((promo) => promo.count > 0));
                        setPastPromoList(getPromoListRes.data.filter((promo) => promo.count === 0));
                    } else {
                        setPromoList([]);
                        setPastPromoList([]);
                    }
                }
            }
            props.store.setLoadingBarStatus(true);
        };

        return (
            <Grid container spacing={3} style={{ padding: "20px", paddingLeft: "0" }}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <>
                                {promoList.length > 0 && (
                                    <TableContainer style={{ width: "100%" }}>
                                        <Typography style={{ fontWeight: "bold", marginBottom: "15px" }}>
                                            Aktif Paketlerim
                                        </Typography>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">DOKTOR BİLGİSİ</TableCell>
                                                    <TableCell align="left">KALAN RANDEVU ADEDİ</TableCell>
                                                    <TableCell align="left">SATIN ALINMA TARİHİ</TableCell>
                                                    <TableCell align="right">İŞLEMLER</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {promoList.map((promo) => (
                                                    <TableRow key={promo.patientPromoId}>
                                                        <TableCell align="left">
                                                            {promo.titleName +
                                                                " " +
                                                                promo.professionalName +
                                                                " " +
                                                                promo.doctorName +
                                                                " " +
                                                                promo.doctorSurname}
                                                        </TableCell>
                                                        <TableCell align="left">{promo.count}</TableCell>
                                                        <TableCell align="left">
                                                            {dayjs(new Date(promo.createdDate)).format(
                                                                "DD-MM-YYYY HH:mm"
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Link
                                                                href={
                                                                    "/danisan/randevu-olustur/" +
                                                                    promo.doctorId +
                                                                    "?paket"
                                                                }
                                                                underline="none"
                                                            >
                                                                <Button variant="contained">Randevu Al</Button>
                                                            </Link>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                                {pastPromoList.length > 0 && (
                                    <TableContainer style={{ width: "100%" }}>
                                        <Typography style={{ fontWeight: "bold", marginBottom: "15px" }}>
                                            Geçmiş Paketlerim
                                        </Typography>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">DOKTOR BİLGİSİ</TableCell>
                                                    <TableCell align="right">SATIN ALINMA TARİHİ</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {pastPromoList.map((promo) => (
                                                    <TableRow key={promo.patientPromoId}>
                                                        <TableCell align="left">
                                                            {promo.titleName +
                                                                " " +
                                                                promo.professionalName +
                                                                " " +
                                                                promo.doctorName +
                                                                " " +
                                                                promo.doctorSurname}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {dayjs(new Date(promo.createdDate)).format(
                                                                "DD-MM-YYYY HH:mm"
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                                {promoList.length + pastPromoList.length === 0 && (
                                    <Typography>Görüntülenecek bir paket bulunmamakta.</Typography>
                                )}
                            </>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    })
);
