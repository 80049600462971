import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import dayjs from 'dayjs'

import { LeftMenu } from '../components/common/LeftMenu';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { ProfileButtonMenu } from '../components/common/ProfileButtonMenu';
import { TopSearchMenu } from '../components/danisan/search/TopSearchMenu';
import { Footer } from '../components/common/Footer';

import getPatientAppointmentByBarcode from '../utils/api/patient/Appointment/GetPatientAppointmentByBarcode'
import postAppointmentPayment from '../utils/api/patient/Appointment/PostAppointmentPayment'

import { PatientAppointment } from '../model/Patient/PatientAppointment';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

class PaymentPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            paymentAppointment: PatientAppointment.create({}),
            months: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"]
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            let callbackUrl = "/danisan/" + this.props.match.params.danisanid + "/appointment/" + this.props.match.params.appointmentBarcode + "/payment"
            //console.log("callbackUrl: ", callbackUrl)

            this.props.store.setLoadingBarStatus(true)
            this.props.store.setUserLogout()
            this.props.history.push("/danisan/kullanici-girisi?url=" + callbackUrl);
        } else {
            this.props.store.setLoadingBarStatus(false)

            let getAppointmentResponse = await getPatientAppointmentByBarcode(this.props.store.baseUrl, this.props.store.token, this.props.match.params.danisanid, this.props.match.params.appointmentBarcode)

            if (getAppointmentResponse !== null && getAppointmentResponse !== undefined) {
                if (getAppointmentResponse.status === 200) {
                    //console.log("getAppointmentResponse.data: ", getAppointmentResponse.data)
                    this.setState({
                        paymentAppointment: getAppointmentResponse.data
                    })
                } else if (getAppointmentResponse.status === 401) {
                    this.props.store.setUserLogout()
                    this.props.history.push("/danisan/kullanici-girisi")
                }
            }

            this.props.store.setLoadingBarStatus(true)

        }
    }

    onClickPaymentButton = async () => {
        this.props.store.setLoadingBarStatus(false)

        let paymentResponse = await postAppointmentPayment(this.props.store.baseUrl, this.props.store.token,
            this.props.store.patient[0].id, this.props.match.params.appointmentBarcode)

        if (paymentResponse !== null && paymentResponse !== undefined) {
            if (paymentResponse.status === 200) {
                console.log("paymentResponse: ", paymentResponse)
                window.open(paymentResponse.result.data, "_blank");
            }
        }

        this.props.store.setLoadingBarStatus(true)
    }

    render() {
        return (
            <div className="container mps-container">
                <LeftMenu activePage="PaymentFailed" />
                <Box component="main" sx={{
                    width: { xs: "calc(100% - 90px)", md: "calc(100% - 235px)" }, marginLeft: { xs: "90px", md: "235px" },
                    flexGrow: 1,
                    py: 8
                }} style={{ maxHeight: "100vh", paddingTop: "10px" }} >
                    <Container style={{ padding: "0" }} maxWidth="100%">
                        <Grid container spacing={0} style={{ marginBottom: "0px", flexWrap: "wrap-reverse" }}>
                            <Grid item lg={8} sm={6} xl={8} xs={12}>
                                <TopSearchMenu history={this.props.history} />
                            </Grid>
                            <Grid item lg={4} sm={6} xl={4} xs={12}>
                                <ProfileButtonMenu />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} style={{ padding: "30px" }}>
                            <Grid item lg={3} sm={3} xl={3} xs={3} >
                            </Grid>
                            <Grid item lg={6} sm={6} xl={6} xs={6} >
                                <Card style={{ width: "500px", background: "#ffb74d" }}>
                                    <CardContent>
                                        <Typography variant='body1' gutterBottom style={{ fontWeight: "bold" }}>
                                            ÖDEME BİLGİLERİ
                                        </Typography>
                                        <Typography variant="h5" component="div">

                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography sx={{ mb: 1.5 }} color="text.primary">
                                                </Typography>
                                                <Typography variant="h6">
                                                    {this.state.paymentAppointment.doctorTitle} {this.state.paymentAppointment.doctorProfessional} {this.state.paymentAppointment.doctorName} {this.state.paymentAppointment.doctorSurname}
                                                </Typography>
                                                <Typography>
                                                    {this.state.paymentAppointment.doctorDepartment}
                                                </Typography>
                                                <Typography variant="h5" style={{ fontWeight: "bold", fontSize: "30px", marginTop: "140px", lineHeight: "1" }}>
                                                    <span style={{ fontWeight: "normal", fontSize: "15px" }}>Randevu Bedeli: </span> <br />{this.state.paymentAppointment.examinationPrice} TL
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{ textAlign: "right" }}>
                                                <CalendarMonthIcon style={{ fontSize: "100px" }} />
                                                <br />
                                                {dayjs(this.state.paymentAppointment.appointmentDate).format('YYYY')}
                                                <Typography variant='body1' style={{ marginBottom: "0", lineHeight: 1 }}>
                                                    <span style={{ fontSize: "50px", fontWeight: "bold" }}>{dayjs(this.state.paymentAppointment.appointmentDate).format('DD')} {this.state.months[Number(dayjs(this.state.paymentAppointment.appointmentDate).format('MM')) + 1]}   </span>
                                                </Typography>
                                                <Typography variant='body1' style={{ marginTop: "0", lineHeight: 1, marginBottom: "0px" }}>
                                                    <span style={{ fontSize: "50px", fontWeight: "bold" }}>{dayjs(this.state.paymentAppointment.appointmentDate).format('HH:mm')}</span>
                                                </Typography>
                                                <Button variant='contained' color='primary' onClick={this.onClickPaymentButton}
                                                    style={{ background: "rgb(219 137 17)", float: "right", marginBottom: "10px", marginTop: "30px" }} >ÖDEME YAP</Button>
                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={3} sm={3} xl={3} xs={3} >
                            </Grid>
                        </Grid>
                    </Container>
                    <Footer />
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(PaymentPage));
