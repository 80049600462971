import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import { LeftMenu } from '../components/common/LeftMenu';

import { Box, Container, Grid } from '@mui/material';
import { SearchMenu } from '../components/danisan/search/SearchMenu';

import queryString from 'query-string';

import { ProfileButtonMenu } from '../components/common/ProfileButtonMenu';
import { Footer } from '../components/common/Footer';



class SearchPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            profileMenuDisplay: false,
            profileMenuTarget: null,
            kategori: null
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/danisan/kullanici-girisi");
        } else {
            this.setState({
                kategori: queryString.parse(this.props.location.search)
            })
        }
    }

    onLogout = () => {
        this.props.store.setUserLogout()
        window.location.reload()
    }

    onHandleProfileMenuOpen = (event) => {
        //setProfileMenuDisplay(true)
        //setProfileMenuTarget(event.currentTarget)
        this.setState({
            profileMenuDisplay: true,
            profileMenuTarget: event.currentTarget
        })
    }

    onClickProfileMenuClose = () => {
        //setProfileMenuDisplay(false)
        //setProfileMenuTarget(null)
        this.setState({
            profileMenuDisplay: false,
            profileMenuTarget: null
        })
    }

    render() {
        return (
            <div className="container mps-container">
                <LeftMenu activePage="Arama Sayfası" />
                <Box component="main" sx={{
                    flexGrow: 1,
                    py: 8
                }} style={{ width: "calc(100% - 220px)", marginLeft: "220px", maxHeight: "100vh", paddingTop: "10px" }} >
                    <Container style={{ padding: "0" }} maxWidth={"100%"}>
                        <Grid container spacing={0} style={{ marginBottom: "10px" }}>
                            <Grid item lg={8} sm={6} xl={8} xs={12}>

                            </Grid>
                            <Grid item lg={4} sm={6} xl={4} xs={12}>
                                <ProfileButtonMenu />
                            </Grid>
                        </Grid>

                        <SearchMenu queryParam={(queryString.parse(this.props.location.search) !== null) ? queryString.parse(this.props.location.search) : null} history={this.props.history} />
 
                    </Container>
                    <Footer />
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(SearchPage));
