import { PatientAppointment } from '../../../../model/Patient/PatientAppointment'

const parsePatientAppointmentData = (inputAppointment) => {

    let appointment = null
    try {

        appointment = PatientAppointment.create({
            appointmentId: (inputAppointment.appointmentId !== null) ?inputAppointment.appointmentId: 0,
            doctorId: (inputAppointment.doctorId !== null) ?inputAppointment.doctorId : 0,
            doctorName: (inputAppointment.doctorName !== null) ?inputAppointment.doctorName: "",
            doctorSurname: (inputAppointment.descdoctorSurnameription !== null) ?inputAppointment.doctorSurname: "",
            doctorTitle: (inputAppointment.doctorTitle !== null) ?inputAppointment.doctorTitle: "",
            doctorDepartment: (inputAppointment.doctorDepartment !== null) ?inputAppointment.doctorDepartment: "",
            doctorProfessional: (inputAppointment.doctorProfessional !== null) ?inputAppointment.doctorProfessional: "",
            address: (inputAppointment.address !== null) ?inputAppointment.address: "",
            appointmentTitle: (inputAppointment.appointmentTitle !== null) ?inputAppointment.appointmentTitle: "",
            appointmentDescription: (inputAppointment.appointmentDescription !== null) ?inputAppointment.appointmentDescription: "",
            appointmentDate: (inputAppointment.appointmentDate !== null) ? new Date(inputAppointment.appointmentDate): new Date(),
            status: (inputAppointment.status !== null) ? inputAppointment.status: 0,
            barcode: (inputAppointment.barcode !== null) ? inputAppointment.barcode: ""
        })
        
    } catch (error) {
        console.log("error: ", error)
    }
    
    return appointment;
} 

export default parsePatientAppointmentData;