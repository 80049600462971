import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import { Link } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, Grid, IconButton, Typography, Badge } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { FaVideo, FaUserMd } from 'react-icons/fa'
import { GiMedicines } from 'react-icons/gi'
import { HiDocumentText } from 'react-icons/hi'
import CallEndIcon from '@mui/icons-material/CallEnd';
import CloseIcon from '@mui/icons-material/Close';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ErrorIcon from '@mui/icons-material/Error'

import { LeftMenu } from '../components/common/LeftMenu';
import { TopSearchMenu } from '../components/danisan/search/TopSearchMenu';
import { ProfileButtonMenu } from '../components/common/ProfileButtonMenu';

import putForStartVideoCall from '../utils/api/patient/VideoCall/PutForStartVideoCall';
import getPatientDoctorInfo from '../utils/api/patient/GetPatientDoctorInfo'
import getPatientAppointmentById from '../utils/api/patient/Appointment/GetAppointmentById';
import getAppointmentPrescription from '../utils/api/patient/Appointment/GetAppointmentPrescription'
import getAppointmentReport from '../utils/api/patient/Appointment/GetAppointmentReport'
import getAppointmentInsuranceForm from '../utils/api/patient/Appointment/GetAppointmentInsuranceForm'

import { Chat } from '../components/danisan/chat/Chat';
import dayjs from 'dayjs'

class VideoCallPage extends Component {

    messagesEndRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            videoUrl: "",
            chatList: [],
            chatItem: "",
            gorusmeOncesiBilgilendirmeDisplay: false,
            acceptedAllBilgilendirme: false,
            receteMenuDisplay: false,
            raporMenuDisplay: false,
            sbfMenuDisplay: false,
            doctor: null,
            appointment: null,
            pageCloseWarningDisplay: false
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.store.setUserLogout()
            this.props.history.push("/danisan/kullanici-girisi");
        } else {
            await this.getAppointmentDoctorInfoReq()
            await this.getAppointmenInfoReq()
        }
    }

    getAppointmenInfoReq = async () => {
        this.props.store.setLoadingBarStatus(false)

        let appointmentInfoResponse = await getPatientAppointmentById(this.props.store.baseUrl,
            this.props.store.token, this.props.store.patientId, this.props.match.params.id)

        if (appointmentInfoResponse !== null && appointmentInfoResponse !== undefined) {
            if (appointmentInfoResponse.status === 200) {
                //console.log("appointment info: ", appointmentInfoResponse)
                if (appointmentInfoResponse.data !== null) {
                    this.setState({
                        appointment: appointmentInfoResponse.data[0]
                    })
                }
            } else if (appointmentInfoResponse.status === 401) {
                this.props.store.setUserLogout()
                window.location.reload()
            }
        }

        this.props.store.setLoadingBarStatus(true)
    }

    getAppointmentDoctorInfoReq = async () => {
        this.props.store.setLoadingBarStatus(false)

        let appointmentInfoResponse = await getPatientDoctorInfo(this.props.store.baseUrl,
            this.props.store.token, this.props.store.patientId, this.props.match.params.doctorid)

        if (appointmentInfoResponse !== null && appointmentInfoResponse !== undefined) {
            if (appointmentInfoResponse.status === 200) {
                //console.log("appointment info: ", appointmentInfoResponse)
                this.setState({
                    doctor: appointmentInfoResponse.data
                })
            } else if (appointmentInfoResponse.status === 401) {
                this.props.store.setUserLogout()
                window.location.reload()
            }
        }

        this.props.store.setLoadingBarStatus(true)
    }

    startVideoProcess = async () => {
        this.setState({
            gorusmeOncesiBilgilendirmeDisplay: true
        })
    }

    onLoadIframe = () => {
        //guestNameInput
        //const iframeEl = document.getElementById('meeting-iframe')

        //const innerDoc = iframeEl.contentDocument || iframeEl.contentWindow.document

        //let guessNameElement = innerDoc.getElementById('guestNameInput');
        //guessNameElement.value = this.props.store.patient[0].name + " " + this.props.store.patient[0].surname
    }

    onChangeChatItemTextInput = (event) => {
        this.setState({
            chatItem: event.target.value
        })
    }

    endVideoCall = () => {
        this.props.history.push("/danisan")
    }

    onAcceptedGorusmeOncesiBilgilendirme = async () => {
        this.setState({
            acceptedAllBilgilendirme: true,
            gorusmeOncesiBilgilendirmeDisplay: false
        })

        this.props.store.setLoadingBarStatus(false)
        //${this.match.params.id}
        let startVideoCallResponse = await putForStartVideoCall(this.props.store.baseUrl, this.props.store.token, this.props.store.patient[0].id, this.props.match.params.id)
        if (
          startVideoCallResponse !== null &&
          startVideoCallResponse !== undefined
        ) {
          if (startVideoCallResponse.data !== "") {
            const patientName = (
              this.props.store.patient[0].name +
              "%20" +
              this.props.store.patient[0].surname
            ).replace(" ", "%20");
            const params = new URLSearchParams();
            params.append("jitsi_meet_external_api_id", "0");
            params.append("config.toolbarButtons", '["microphone","camera"]');
            params.append("config.hideConferenceSubject", "true");
            params.append("config.hideConferenceTimer", "true");
            params.append("config.connectionIndicators", '{"disabled":true}');
            params.append(
              "interfaceConfig.VIDEO_QUALITY_LABEL_DISABLED",
              "true"
            );
            params.append("userInfo.displayName", '"patientName"');
            params.append("appData.localStorageContent", "null");

            console.log("state", this.state);
            console.log("props", this.props);

            const urlParameterString = params
              .toString()
              .replace("patientName", patientName);
            let videoLink = startVideoCallResponse.data + urlParameterString;
            //   let videoLink = startVideoCallResponse.data + params
            this.setState({
              videoUrl: videoLink,
            });
          } else {
            this.props.store.setMessage(
              "Görüşme başlatılırken hata oluştu",
              true,
              "error"
            );
          }

          this.props.store.setLoadingBarStatus(true);
        }
    }

    onClickReceteShowDisplay = async () => {
        this.props.store.setLoadingBarStatus(false)

        let receteListesi = await this.getPrescriptionListReq()

        if (receteListesi !== null) {
            this.setState({
                receteMenuDisplay: true
            })
        } else {
            this.props.store.setMessage("Henüz doktorunuz tarafından reçete oluşturulmadı.", true, "warning")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    onClickRaporMenuDisplay = async () => {
        this.props.store.setLoadingBarStatus(false)

        let rapor = await this.getReportListReq()
       
        if (rapor !== null) {
            this.setState({
                raporMenuDisplay: true
            })
        } else {
            this.props.store.setMessage("Henüz doktorunuz tarafından rapor oluşturulmadı.", true, "warning")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    onClickSbfMenuDisplay = async () => {
        this.props.store.setLoadingBarStatus(false)

        let sbfListesi = await this.getInsuranceFormListReq()

        if (sbfListesi !== null) {
            this.setState({
                sbfMenuDisplay: true
            })
        } else {
            this.props.store.setMessage("Henüz doktorunuz tarafından Sigorta Bilgi Formu oluşturulmadı.", true, "warning")
        }

        this.props.store.setLoadingBarStatus(true)
    }



    handleReceteMenuClose = () => {
        this.setState({
            receteMenuDisplay: false
        })
    }

    handleRaporMenuClose = () => {
        this.setState({
            raporMenuDisplay: false
        })
    }

    handleSbfMenuClose = () => {
        this.setState({
            sbfMenuDisplay: false
        })
    }

    getPrescriptionListReq = async () => {
        this.props.store.setLoadingBarStatus(false)

        let receteListesi = []

        let getPrescriptionListResponse = await getAppointmentPrescription(this.props.store.baseUrl, this.props.store.token, this.props.store.patient[0].id, this.props.match.params.id)
        console.log("getPrescriptionListResponse: ", getPrescriptionListResponse.data)
        if (getPrescriptionListResponse !== null && getPrescriptionListResponse !== undefined) {
            if (getPrescriptionListResponse.status === 200) {
                receteListesi = getPrescriptionListResponse.data
            }
        }

        this.props.store.setLoadingBarStatus(true)

        return receteListesi;
    }

    getReportListReq = async () => {
        this.props.store.setLoadingBarStatus(false)

        let raporListesi = []
        let getReportListResponse = await getAppointmentReport(this.props.store.baseUrl, this.props.store.token, this.props.store.patient[0].id, this.props.match.params.id)
        console.log("getReportListResponse: ", getReportListResponse)
        if (getReportListResponse !== null && getReportListResponse !== undefined) {
            if (getReportListResponse.status === 200) {
                raporListesi = getReportListResponse.data
            }
        }

        this.props.store.setLoadingBarStatus(true)

        return raporListesi
    }

    getInsuranceFormListReq = async () => {
        this.props.store.setLoadingBarStatus(false)

        let insuranceList = []
        let getInsuranceListResponse = await getAppointmentInsuranceForm(this.props.store.baseUrl, this.props.store.token, this.props.store.patient[0].id, this.props.match.params.id)
        //console.log("getInsuranceListResponse: ", getInsuranceListResponse)
        if (getInsuranceListResponse !== null && getInsuranceListResponse !== undefined) {
            if (getInsuranceListResponse.status === 200) {
                //setInsuranceForm(getInsuranceListResponse.data)
                insuranceList = getInsuranceListResponse.data
            }
        }

        this.props.store.setLoadingBarStatus(true)
        return insuranceList;
    }

    onClickEndGorusme = () => {
        this.setState({
            pageCloseWarningDisplay: true
        })
    }

    onClickCloseVideoGorusme = () => {
        window.location.replace("/danisan");
    }

    onClickCloseWarningDisplayMenu = () => {
        this.setState({
            pageCloseWarningDisplay: false
        })
    }

    render() {

        return (
            <div className="container mps-container">
                <LeftMenu activePage="Görüşme" />
                <Box component="main" sx={{
                    width: { xs: "calc(100% - 90px)", md: "calc(100% - 235px)" }, marginLeft: { xs: "90px", md: "235px" },
                    flexGrow: 1,
                    py: 8
                }} style={{ maxHeight: "100vh", paddingTop: "10px" }}>
                    <Container maxWidth={false}>
                        <Grid container spacing={0} style={{ marginBottom: "0px", flexWrap: "wrap-reverse" }}>
                            <Grid item lg={8} sm={6} xl={8} xs={12}>
                                <TopSearchMenu history={this.props.history} />
                            </Grid>
                            <Grid item lg={4} sm={6} xl={4} xs={12}>
                                <ProfileButtonMenu />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ marginTop: "10px" }}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                                <Grid container style={{ paddingTop: "0", height: "100%" }}>
                                    <Grid item xs={8}>
                                        <Card style={{ height: 'calc(100vh - 270px)', borderRadius: "50px" }}>
                                            <CardContent style={{ padding: "0", height: "100%" }}>
                                                {(this.state.videoUrl !== "") ?
                                                    <iframe allow="camera; microphone; display-capture; autoplay; clipboard-write; hid" id="meeting-iframe" src={this.state.videoUrl} title="video-gorusme"
                                                        style={{ height: "calc(100% + 25px)", width: "calc(100% + 25px)", border: "0", marginLeft: "-10px", marginTop: "-10px" }}
                                                        onLoad={this.onLoadIframe} /> :
                                                    <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
                                                        <div>
                                                            <FaUserMd style={{
                                                                marginTop: "20%", fontSize: "175px", color: "#ffb74d",
                                                                borderRadius: "150px", border: "7px solid #ffb74d", marginBottom: "15px"
                                                            }} />
                                                        </div>

                                                        <Button variant='contained' color="primary" onClick={this.startVideoProcess} style={{ fontSize: "16px", color: "#000" }}>
                                                            <FaVideo style={{ fontSize: "24px", marginRight: "15px" }} /> Görüşmeyi Başlat
                                                        </Button>
                                                    </div>
                                                }
                                            </CardContent>
                                        </Card>
                                        <Card style={{ height: '130px', borderRadius: "10px", marginTop: "25px" }}>
                                            <CardContent style={{ padding: "0", height: "100px" }}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={3} style={{ padding: "50px", textAlign: "center" }}>
                                                        <IconButton aria-label="upload picture" component="label" className='video-call-buttons'
                                                            onClick={this.onClickReceteShowDisplay}>
                                                            <GiMedicines />
                                                        </IconButton>
                                                        <p style={{ fontSize: "12px", marginTop: "5px", color: "gray", fontWeight: "bold" }}>Reçete</p>
                                                    </Grid>
                                                    <Grid item xs={3} style={{ padding: "50px", textAlign: "center" }}>
                                                        <IconButton aria-label="upload picture" component="label" className='video-call-buttons'
                                                            onClick={this.onClickRaporMenuDisplay}>
                                                            <Badge color="primary">
                                                                <NewspaperIcon />
                                                            </Badge>
                                                        </IconButton>
                                                        <p style={{ fontSize: "12px", marginTop: "5px", color: "gray", fontWeight: "bold" }}>Rapor</p>
                                                    </Grid>
                                                    <Grid item xs={3} style={{ padding: "50px", textAlign: "center" }}>
                                                        <IconButton aria-label="upload picture" component="label" className='video-call-buttons'
                                                            onClick={this.onClickSbfMenuDisplay}>
                                                            <Badge color="primary">
                                                                <HiDocumentText />
                                                            </Badge>
                                                        </IconButton>
                                                        <p style={{ fontSize: "12px", marginTop: "5px", color: "gray", fontWeight: "bold" }}>Sigorta Bilgi Formu</p>
                                                    </Grid>

                                                    <Grid item xs={3} style={{ padding: "50px", textAlign: "center" }}>

                                                        <IconButton aria-label="upload picture" color="error" className='video-call-button-endcall' onClick={this.onClickEndGorusme}>
                                                            <CallEndIcon style={{ color: "#fff" }} />
                                                        </IconButton>
                                                        <p style={{ fontSize: "12px", marginTop: "5px", color: "gray", fontWeight: "bold" }}>Görüşmeyi <br /> Sonlandır</p>
                                                    </Grid>
                                                </Grid>

                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Card style={{ borderRadius: "10px", marginTop: "0px", marginLeft: "25px" }}>
                                            <CardContent style={{ height: "calc(100% - 50px)", paddingTop: "30px" }}>

                                                <Typography><b>Doktor Adı Soyadı:</b> <br />{(this.state.doctor !== null) ? (this.state.doctor.title.titleName ? (this.state.doctor.title.titleName + " " ) : "") + this.state.doctor.professional?.professionalName + " " + this.state.doctor?.name + " " + this.state.doctor?.surname : ""}</Typography>
                                                <Typography><b>Uzmanlık Alanı:</b> {(this.state.doctor !== null) ? this.state.doctor.department?.departmentName : ""}</Typography>
                                                <Typography><b>Randevu Zamanı:</b> {(this.state.appointment !== null) ? dayjs(this.state.appointment?.appointmentDate).format("DD.MM.YYYY HH:mm") : ""}</Typography>

                                                <Tabs value={0} aria-label="basic tabs example"
                                                /*variant="scrollable" scrollButtons="auto"*/>
                                                    <Tab label="MESAJLAŞMA" />
                                                </Tabs>

                                                <Chat appointmentId={this.props.match.params.id} doctorId={this.props.match.params.doctorid} />

                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                <Dialog open={this.state.gorusmeOncesiBilgilendirmeDisplay} >
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant="body1" style={{ fontWeight: "bold" }}>Bilgilendirme</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            Değerli kullanıcımız, uzaktan sağlık hizmetinin yerine getirilebilmesi
                            için <b>kamera ve mikrofon erişim izni </b>gerekmektedir. Sağlık mensubu ile yapacağınız görüşmeyi karşı tarafa
                            bildirerek istediğiniz anda sonlandırabilirsiniz.
                        </Typography>
                        <Typography>
                            Sağlık mensubu ile yapacağınız görüşmede <b>hiçbir şekilde ses ve görüntü kaydı yapılmamaktadır.</b>
                        </Typography>

                    </DialogContent>
                    <DialogActions>
                        <Button /*component={Link} to=""*/ variant='contained' onClick={this.onAcceptedGorusmeOncesiBilgilendirme}>
                            Devam Et
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.receteMenuDisplay} fullScreen>
                    <AppBar sx={{ position: 'relative', background: "#ffab40" }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.handleReceteMenuClose}
                                aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Reçete Bilgi Menüsü
                            </Typography>
                            <Button variant='contained' style={{ background: "#c97e1e" }}
                                target="_blank" to={"/danisan/recete/" + this.props.match.params.id} component={Link}>
                                Yazdır
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <iframe src={"/danisan/recete/" + this.props.match.params.id} title="Reçete" style={{ height: "100%", border: "0" }} />
                </Dialog>
                <Dialog open={this.state.raporMenuDisplay} fullScreen>
                    <AppBar sx={{ position: 'relative', background: "#ffab40" }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.handleRaporMenuClose}
                                aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Rapor Bilgi Menüsü
                            </Typography>
                            <Button variant='contained' style={{ background: "#c97e1e" }}
                                target="_blank" to={"/danisan/rapor/" + this.props.match.params.id} component={Link}>
                                Yazdır
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <iframe src={"/danisan/rapor/" + this.props.match.params.id} title="Rapor" style={{ height: "100%", border: "0" }} />
                </Dialog>
                <Dialog open={this.state.sbfMenuDisplay} fullScreen>
                    <AppBar sx={{ position: 'relative', background: "#ffab40" }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.handleSbfMenuClose}
                                aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Sigorta Bilgi Formu Menüsü
                            </Typography>
                            <Button variant='contained' style={{ background: "#c97e1e" }}
                                target="_blank" to={"/danisan/sbf/" + this.props.match.params.id} component={Link}>
                                Yazdır
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <iframe src={"/danisan/sbf/" + this.props.match.params.id} title="Rapor" style={{ height: "100%", border: "0" }} />
                </Dialog>

                <Dialog fullWidth maxWidth="xs" open={this.state.pageCloseWarningDisplay} onClose={this.onClickCloseWarningDisplayMenu}>
                    <DialogTitle id="alert-dialog-title">

                    </DialogTitle>
                    <DialogContent style={{ paddingTop: "0" }}>
                        <Grid container>
                            <Grid item xs={3}>
                                <ErrorIcon color="error" style={{ fontSize: "80px" }} />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="body1" style={{ fontWeight: "bold" }}>Görüşmeden çıkmak istediğinize emin misiniz ?</Typography>
                                <Typography variant="body2">Görüşmeyi sonlandırdığınız anda sigorta bilgi
                                    formu doktorunuz tarafından oluşturulmamış ise Özel Sağlık Sigortası tarafından bu randevu ücretini karşılamasında sorun yaşayabilirsiniz.</Typography>
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' onClick={this.onClickCloseWarningDisplayMenu}>
                            GERİ DÖN
                        </Button>
                        <Button color='error' variant='contained' onClick={this.onClickCloseVideoGorusme}>
                            GÖRÜŞMEDEN AYRIL
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default inject("store")(observer(VideoCallPage));
