import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";

import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { IMaskInput } from "react-imask";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "dayjs/locale/tr";
import dayjs from "dayjs";

import MapissoAlert from "../components/common/MapissoAlert";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import userRegister from "../utils/api/patient/UserRegister";
import getIlListesi from "../utils/api/common/GetIlListesi";
import { termsConditions, healthPermission } from "./RegisterText";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00)000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tcKimlikNo: "",
      adi: "",
      soyadi: "",
      dogumTarihi: new Date(),
      password: "",
      repassword: "",
      phoneNumber: "",
      email: "",
      cityId: 0,
      passwordHelperTitle: "",
      passwordErrorStatus: false,
      repasswordHelperTitle: "",
      repasswordErrorStatus: false,
      repasswordShowStatus: false,
      passwordShowStatus: false,
      checkedUyelikSozlesmesi: false,
      checkedKvkkSozlesmesi: true,
      checkedSaglikIzniSozlesmesi: false,
      uyelikSozlesmesiDisplay: false,
      saglikSozlesmesiDisplay: false,
      ilListesi: [],
    };
  }

  componentDidMount = async () => {
    if (this.props.store.isLogin) {
      this.props.store.setLoadingBarStatus(true);
      this.props.history.push("/danisan");
    } else {
      this.props.store.setLoadingBarStatus(false);

      let getIlListesiResponse = await getIlListesi(this.props.store.baseUrl);
      if (getIlListesiResponse !== null && getIlListesiResponse !== undefined) {
        if (getIlListesiResponse.status === 200) {
          this.setState({
            ilListesi: getIlListesiResponse.result,
          });
        }
      }
      this.props.store.setLoadingBarStatus(true);
    }
  };

  onKeyPressHandler = (target) => {
    if (target.charCode === 13) {
      this.onLogin();
    }
  };

  onRegisterTcKimlikNoTextFieldChange = (event) => {
    this.setState({
      tcKimlikNo: event.target.value,
    });
  };

  onRegisterAdiTextFieldChange = (event) => {
    this.setState({
      adi: event.target.value,
    });
  };

  onRegisterSoyadiTextFieldChange = (event) => {
    this.setState({
      soyadi: event.target.value,
    });
  };

  onRegisterDogumTarihiChange = (event) => {
    this.setState({
      dogumTarihi: dayjs(event),
    });
  };

  onRegisterEmailTextFieldChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  onRegisterPasswordTextFieldTextChange = (event) => {
    let passwordText = event.target.value;
    if (passwordText.length < 8 && !this.isUpper(passwordText)) {
      this.setState({
        passwordHelperTitle:
          "*Şifreniz en az 8 karakter uzunluğunda olmalıdır. En az 1 tane harf içermelidir.",
        passwordErrorStatus: true,
      });
    } else {
      this.setState({
        passwordHelperTitle: "",
        passwordErrorStatus: false,
      });
    }
    this.setState({
      password: event.target.value,
    });
  };

  onRegisterRePasswordTextFieldTextChange = (event) => {
    let passwordText = event.target.value;
    if (passwordText !== this.state.password) {
      this.setState({
        repasswordHelperTitle: "*Şifreler uyuşmuyor.",
        repasswordErrorStatus: true,
      });
    } else {
      this.setState({
        repasswordHelperTitle: "",
        repasswordErrorStatus: false,
      });
    }
  };

  isUpper(str) {
    return !/[a-z]/.test(str) && /[A-Z]/.test(str);
  }

  handleClickShowRepassword = () => {
    this.setState({
      repasswordShowStatus: true,
    });
  };

  handleMouseDownRepassword = () => {
    this.setState({
      repasswordShowStatus: false,
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      passwordShowStatus: true,
    });
  };

  handleMouseDownPassword = () => {
    this.setState({
      passwordShowStatus: false,
    });
  };

  onRegisterPhoneNumberTextFieldTextChange = (event) => {
    this.setState({
      phoneNumber: event.target.value,
    });
  };

  onChangeSelectIlMenu = (event) => {
    this.setState({
      cityId: event.target.value,
    });
  };

  onClickRegisterButton = async () => {
    this.props.store.setLoadingBarStatus(false);
    // eslint-disable-next-line
    if (
      this.state.checkedUyelikSozlesmesi &&
      this.state.checkedKvkkSozlesmesi
    ) {
      // eslint-disable-next-line
      let phoneNumberFixed = this.state.phoneNumber
        .replace(/[\(\)]/g, "")
        .replace("-", "");

      let registerObject = {
        tckno: Number(this.state.tcKimlikNo),
        mobilePhone: Number(phoneNumberFixed),
        email: this.state.email,
        password: this.state.password,
        name: this.state.adi,
        surname: this.state.soyadi,
        birthDate: dayjs(new Date(this.state.dogumTarihi))
          .add(1, "day")
          .toDate(),
        kullanimKosullariKabul: true,
        kvkkMetniKabul: true,
        cityId: this.state.cityId,
      };
      if (
        this.state.tcKimlikNo !== "" &&
        this.state.adi !== "" &&
        this.state.soyadi !== "" &&
        this.state.password !== "" &&
        this.state.phoneNumber !== ""
      ) {
        let registerResponse = await userRegister(
          this.props.store.baseUrl,
          registerObject
        );
        //console.log("registerResponse: ", registerResponse)
        if (registerResponse !== null && registerResponse !== undefined) {
          if (registerResponse.status === 200) {
            //console.log("registerResponse.data: ", registerResponse.data)
            this.props.store.setPatientId(registerResponse.data.patientId);
            this.props.history.push("/danisan/aktivasyon");
          } else {
            this.props.store.setMessage(registerResponse.data, true, "error");
          }
        }
      } else {
        this.props.store.setMessage(
          "Lütfen zorunlu alanları doldurunuz.",
          true,
          "error"
        );
      }
    } else {
      this.props.store.setMessage(
        "Kullanım Hüküm ve Koşullarını onaylamadan kayıt işlemi gerçekleştiremiyoruz. ",
        true,
        "error"
      );
    }

    this.props.store.setLoadingBarStatus(true);
  };

  onCheckedUyelikSozlesmesi = (event) => {
    this.setState({
      checkedUyelikSozlesmesi: event.target.checked,
    });
  };

  onCheckedSaglikIzinSozlesmesi = (event) => {
    this.setState({
      checkedSaglikIzniSozlesmesi: event.target.checked,
    });
  };

  onClickKullanimKosullariKabul = () => {
    console.log("burada");
    this.setState({
      checkedUyelikSozlesmesi: true,
      uyelikSozlesmesiDisplay: false,
    });
  };

  onClickOpenUyelikKullanimSozlesmesi = () => {
    this.setState({
      uyelikSozlesmesiDisplay: true,
    });
  };

  onClickSaglikVeriIzniKabul = () => {
    this.setState({
      checkedSaglikIzniSozlesmesi: true,
      saglikSozlesmesiDisplay: false,
    });
  };

  onClickOpenSaglikIzniSozlesmesi = () => {
    this.setState({
      saglikSozlesmesiDisplay: true,
    });
  };

  render() {
    return (
      <div className="container mps-container bg-image">
        <Grid container style={{ width: "100%" }}>
          <Grid
            item
            xs={5}
            className="height100vh"
            style={{
              padding: "100px",
              background: "#fff",
              minWidth: "500px",
              paddingLeft: "50px",
              paddingTop: "10px",
            }}
          >
            <Grid
              container
              spacing={0}
              style={{ marginTop: "20px", width: "250px", marginLeft: "50px" }}
            >
              <Grid item xs={12}>
                <img
                  src="/assets/images/saglikcim-logo.jpg"
                  alt="wholemedics"
                  style={{
                    width: "100%",
                    marginTop: "0px",
                    marginLeft: "5px",
                    marginBottom: "10px",
                  }}
                />
              </Grid>
            </Grid>
            <Typography
              variant="h5"
              style={{ marginTop: "20px", marginLeft: "50px" }}
            >
              Kayıt Ol
            </Typography>
            <Grid container spacing={1} style={{ paddingLeft: "50px" }}>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="adi"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                  label="Adı*"
                  onChange={this.onRegisterAdiTextFieldChange}
                  autoComplete="given-name"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="soyadi"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                  label="Soyadı*"
                  onChange={this.onRegisterSoyadiTextFieldChange}
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"tr"}
                  style={{ width: "100%" }}
                >
                  <DesktopDatePicker
                    label="Doğum Tarihi*"
                    inputFormat="DD/MM/YYYY"
                    value={this.state.dogumTarihi}
                    onChange={this.onRegisterDogumTarihiChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="bday"
                        style={{ marginTop: "10px", width: "100%" }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="email"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                  label="Email*"
                  onChange={this.onRegisterEmailTextFieldChange}
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="tckno"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                  label="T.C. Kimlik numarası*"
                  onChange={this.onRegisterTcKimlikNoTextFieldChange}
                  autoComplete="on"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="password"
                  type={this.state.passwordShowStatus ? "text" : "password"}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                  label="Şifreniz*"
                  onChange={this.onRegisterPasswordTextFieldTextChange}
                  helperText={this.state.passwordHelperTitle}
                  error={this.state.passwordErrorStatus}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.passwordShowStatus ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="repassword"
                  type={this.state.repasswordShowStatus ? "text" : "password"}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                  label="Şifreniz (tekrar)*"
                  onChange={this.onRegisterRePasswordTextFieldTextChange}
                  helperText={this.state.repasswordHelperTitle}
                  error={this.state.repasswordErrorStatus}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowRepassword}
                          onMouseDown={this.handleMouseDownRepassword}
                          edge="end"
                        >
                          {this.state.repasswordShowStatus ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <InputLabel id="city-select-label">İl Bilgisi</InputLabel>
                  <Select
                    labelId="city-select-label"
                    label="İl Bilgisi"
                    value={this.state.cityId}
                    id="city-select"
                    style={{ background: "#fff" }}
                    onChange={this.onChangeSelectIlMenu}
                    MenuProps={MenuProps}
                    autoComplete="home city"
                  >
                    <MenuItem key={"emptyil"} value={0}>
                      İl Seçiniz
                    </MenuItem>
                    {this.state.ilListesi.map((il, idx) => (
                      <MenuItem key={idx} value={il.cityId}>
                        {il.cityName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div style={{ paddingLeft: "50px", marginTop: "10px" }}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                name="phonenumber"
                value={this.state.phoneNumber}
                type="text"
                InputProps={{
                  inputComponent: TextMaskCustom,
                }}
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "10px",
                  marginTop: "10px",
                }}
                label="Cep Telefonu Numarası*"
                onChange={this.onRegisterPhoneNumberTextFieldTextChange}
                autoComplete="tel"
              />
              <Grid container>
                <Grid item xs={1}>
                  <Checkbox
                    checked={this.state.checkedUyelikSozlesmesi}
                    onChange={this.onCheckedUyelikSozlesmesi}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    color="default"
                    variant="body2"
                    style={{
                      fontSize: "13px",
                      marginTop: "10px",
                      marginInlineStart: "6px",
                    }}
                    onClick={this.onClickOpenUyelikKullanimSozlesmesi}
                  >
                    <b
                      className="wholemedics-href"
                      style={{
                        color: "inherit",
                        textDecoration: "underline",
                        fontSize: "13px",
                      }}
                    >
                      Kullanım Hüküm ve Koşulları
                    </b>{" "}
                    okudum, kabul ediyorum
                  </Typography>
                </Grid>
                {/**
                                     * <Grid item xs={1}>
                                    <Checkbox checked={this.state.checkedSaglikIzniSozlesmesi} onChange={this.onCheckedSaglikIzinSozlesmesi} />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography color="default" variant='body2' style={{ fontSize: "13px", marginTop: "10px" }} onClick={this.onClickOpenSaglikIzniSozlesmesi}>
                                        <b className="wholemedics-href" style={{ color: "inherit", textDecoration: "underline", fontSize: "13px" }}>
                                            Sağlık Verisi İşlenme İzni </b> kapsamında Sağlıkçım.net tarafından sağlık durumuna ilişkin kişisel verilerin
                                        saklanmasını ve işlenmesini kabul ediyorum.</Typography>
                                </Grid>
                                     */}
              </Grid>

              <FormGroup style={{ marginTop: "5px" }}>
                <Typography
                  variant="subtitle2"
                  color="default"
                  style={{ marginTop: "0px", fontSize: "13px" }}
                >
                  Kişisel verilerinizin işlenmesi hakkında detaylı bilgi için
                  <a
                    href="https://saglikcim.net/kisisel-verilerin-korunmasi"
                    style={{
                      color: "inherit",
                      textDecoration: "underline",
                      fontWeight: "bold",
                      marginLeft: "5px",
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Kişisel Verilerin İşlenmesi Hakkındaki Aydınlatma Metnini
                  </a>
                  'ni okuyabilirsiniz
                </Typography>
              </FormGroup>

              <Button
                variant="contained"
                color="primary"
                onClick={this.onClickRegisterButton}
                style={{
                  width: "100%",
                  height: "50px",
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                KAYIT OL
              </Button>
              <LinearProgress
                style={{
                  display: this.props.store.loadingBarStatus ? "none" : "block",
                  width: "calc(100% - 18px)",
                  marginLeft: "9px",
                }}
              />
            </div>
          </Grid>
        </Grid>
        <MapissoAlert />
        <Dialog
          open={this.state.uyelikSozlesmesiDisplay}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {" "}
              Kullanım Hüküm ve Koşulları
            </Typography>
          </DialogTitle>
          <DialogContent>{termsConditions}</DialogContent>
          <DialogActions>
            <Button
              /*component={Link} to=""*/ variant="contained"
              onClick={this.onClickKullanimKosullariKabul}
            >
              Kabul Et
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.saglikSozlesmesiDisplay}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {" "}
              Sağlık Verisi İşleme İzni
            </Typography>
          </DialogTitle>
          <DialogContent>{healthPermission}</DialogContent>
          <DialogActions>
            <Button
              /*component={Link} to=""*/ variant="contained"
              onClick={this.onClickSaglikVeriIzniKabul}
            >
              Kabul Et
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default inject("store")(observer(RegisterPage));
