import axios from "axios";
import parsePatientAppointmentData from "../parsers/PatientAppointmentParser";

const getPatientAppointmentList = async (baseUrl, token, patientId, startDate, endDate) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null 

    await axios.get(baseUrl + `/patient/` + patientId + "/appointment?startdate=" + startDate + "&enddate=" + endDate, config)
        .then((res) => {
            //console.log("res.data: ", res.data)

            let appointmentList = res.data.data

            let fixedAppointmentList = []
            for (let index = 0; index < appointmentList.length; index++) {
                const tempAppointment = appointmentList[index];
                fixedAppointmentList.push(parsePatientAppointmentData(tempAppointment))
            }

            getResponse = {
                status: res.data.status,
                data: fixedAppointmentList
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    result: error.response.status
                }
            }
        });
    return getResponse
}

export default getPatientAppointmentList;