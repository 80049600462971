import axios from "axios";

const userSmsCodeVerify = async (baseUrl, patientId, smsCode) => {

    let userResponse = null

    ///patient/{patientid}/register/{code}/complete

    await axios.put(baseUrl + `/patient/` + patientId + "/register/" + smsCode + "/complete", {})
        .then((res) => {
            userResponse = {
                status: res.data.status,
                data: res.data
            }
        }).catch((error) => {
            //console.log("error: ", error)
            if (error.response !== undefined) {
                userResponse = {
                    status: error.response.status
                }
            } 
        });

    console.log("user response: ", userResponse)
    return userResponse
}

export default userSmsCodeVerify;
