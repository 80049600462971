import { types } from "mobx-state-tree"

export const Insurance = types
    .model({
        insuranceId: types.optional(types.integer, 0),
        patientId: types.optional(types.number, 0),
        firmName: types.optional(types.string, ""),
        policyNo: types.optional(types.string, ""),
        customerNo: types.optional(types.string, ""),
        startDate: types.optional(types.Date, new Date()),
        endDate: types.optional(types.Date, new Date())
    }).actions(self => ({

    }))
