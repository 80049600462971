import axios from "axios";

const patientUpdate = async (baseUrl, token, patientId, body ) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    await axios.put(baseUrl + `/patient/` + patientId, body, config)
        .then((res) => {
            //console.log("res.data: ", res.data)
            putResponse = {
                status: res.data.status,
                result: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    result: error.response.status
                }
            }
        });
    return putResponse
}

export default patientUpdate;