import React from 'react';
import { observer, inject } from "mobx-react"

import { Button, Grid, Link, Paper, Typography } from '@mui/material';

export const Footer = inject("store")(observer((props) => {

    return (
        <Paper elevation={0} style={{ width: "100%",  padding: "50px", marginTop: "50px", paddingTop: "0", background: "#F9F9F9",borderRadius: "0" }}>
            <Grid container spacing={3}>
                <Grid item lg={8} sm={12} xl={8} xs={12}>
                    <Link href="https://saglikcim.net/hakkimizda" underline="none">
                         <Typography variant='body2' style={{ marginTop: "10px" }}>Hakkımızda</Typography>
                    </Link>
                    <Link href="https://saglikcim.net/kisisel-verilerin-korunmasi" underline="none">
                        <Typography variant='body2' style={{ marginTop: "10px" }}>Kişisel Verilerin Korunması Hakkında</Typography>
                    </Link>
                    <Link href="https://saglikcim.net/kullanim-hukum-ve-kosullari" underline="none" >
                        <Typography variant='body2' style={{ marginTop: "10px" }}>Kullanım Koşul ve Hükümleri</Typography>
                    </Link>
                    <Link href="https://saglikcim.net/saglikcimnet-nedir" underline="none">
                        <Typography variant='body2' style={{ marginTop: "10px" }}>Sıkça Sorulan Sorular</Typography>
                    </Link>
                </Grid>
                <Grid item lg={4} sm={12} xl={4} xs={12}>
                    <Grid container spacing={0} style={{ marginTop: "20px", maxWidth: "200px" }}>
                        <Grid item xs={12}>
                        <img src='/assets/images/saglikcim-logo-v3.png' alt="wholemedics" style={{ width: "100%", marginTop: "10px", marginLeft: "5px", marginBottom: "10px" }} />
                        </Grid>
                    </Grid>
                    <Typography>Her zaman yanıbaşınızdayız.</Typography>
                    <Button variant='contained' style={{ marginTop: "20px" }}>
                        İletişim
                    </Button> 
                </Grid>
                <Grid item lg={6} sm={12} xl={6} xs={12}>
                        <img src='/assets/images/wholemedic-logo.png' alt="wholemedics" style={{width: "250px"}}></img>
                </Grid>
                <Grid item lg={6} sm={12} xl={6} xs={12}>
                    <Typography variant='body2' style={{ marginTop: "10px", float: "right" }}><b>Sağlıkçım.net</b> Tüm Hakları Saklıdır 2022©</Typography>
                </Grid>
            </Grid>
        </Paper>

    )
}))