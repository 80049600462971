import axios from "axios";

const userLogin = async (baseUrl, tcKimlikNo, password) => {

    let userResponse = null

    const body = {
        tckno: Number(tcKimlikNo),
        password: password
    }

    await axios.put(baseUrl + `/patient/login/code`, body)
        .then((res) => {
            //console.log("res.data: ", res.data)
            if(res.data.status === 200){
                userResponse = {
                    status: res.data.status,
                    data: res.data.data//parseUserData(res.data)
                }
            }else {
                userResponse = {
                    status: res.data.status,
                    data: res.data.message
                }
            }
            
        }).catch((error) => {
            //console.log("error: ", error)
            if (error.response !== undefined) {
                userResponse = {
                    status: error.response.status
                }
            } 
        });

    //console.log("user response: ", userResponse)
    return userResponse
}

export default userLogin;
