import React, { useState, useEffect, useRef } from 'react';
import { observer, inject } from "mobx-react"
import { Link } from 'react-router-dom';
import dayjs from 'dayjs'

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { AppBar, Button, Card, CardContent, Dialog, DialogActions, DialogContent, Toolbar, Typography } from '@mui/material';
import { Tooltip } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import InfoIcon from '@mui/icons-material/Info';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import PaymentIcon from '@mui/icons-material/Payment';
import DeleteIcon from '@mui/icons-material/Delete';

import putAppointmentCancel from '../../../utils/api/patient/Appointment/PutAppointmentCancel'
import getPatientAppointmentList from '../../../utils/api/patient/Appointment/GetPatientAppointmentList'

export const RandevularimMenu = inject("store")(observer((props) => {

    const [activeTab, setActiveTab] = React.useState(0);

    const [passedRandevuListesi, setPassedRandevuListesi] = useState([])
    const [futureRandevuListesi, setFutureRandevuListesi] = useState([])
    const [consultDoctorListesi, setConsultDoctorListesi] = useState([])
    const [waitingPaymentListesi, setWaitingPaymentListesi] = useState([])

    const [randevuIptalWarningDisplay, setRandevuIptalWarningDisplay] = useState(false)
    const selectedAppointmentId = useRef(0)

    useEffect(() => {
        // Update the document title using the browser API
        setPassedRandevuListesi(props.passed)
        setFutureRandevuListesi(props.future)
        setConsultDoctorListesi(props.consultTalepList)
        setWaitingPaymentListesi(props.waitingPaymentListesi)
        // eslint-disable-next-line
    }, [props.passed, props.future, props.consultTalepList, props.waitingPaymentListesi]);

    useEffect(() => {
        setActiveTab(props.type)
    }, [props.type])

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue)
    }

    const onClickRandevuIptalButton = (appointmentId) => {
        selectedAppointmentId.current = appointmentId
        setRandevuIptalWarningDisplay(true)
    }

    const onClickRandevuIptal = async (appointmentId) => {
        props.store.setLoadingBarStatus(false)

        let cancelAppointmentResponse = await putAppointmentCancel(props.store.baseUrl, props.store.token, props.store.patientId, appointmentId)
        if (cancelAppointmentResponse !== null && cancelAppointmentResponse !== undefined) {
            if (cancelAppointmentResponse.status === 200) {
                props.store.setMessage("Randevu iptal işlemi başarıyla gerçekleştirildi.", true, "success")
                await getFutureAppointmentList()
            } else {
                props.store.setMessage("Randevu iptal edilebilir statüde değil. Lütfen müşteri hizmetlerini arayınız!", true, "error")
            }
        } else {
            props.store.setMessage("Randevu iptal edilebilir statüde değil. Lütfen müşteri hizmetlerini arayınız!", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const getFutureAppointmentList = async () => {
        props.store.setLoadingBarStatus(false)
        let startDate = dayjs(new Date()).subtract(1, 'year').format('YYYY-MM-DD');
        let endDate = dayjs(new Date()).add(1, 'year').format('YYYY-MM-DD');

        let appointmentListResponse = await getPatientAppointmentList(props.store.baseUrl, props.store.token,
            props.store.patient[0].id, startDate, endDate)

        if (appointmentListResponse !== undefined && appointmentListResponse !== null) {
            if (appointmentListResponse.status === 200) {
                let allAppointmentList = appointmentListResponse.data.reverse();

                let passedAppointmentList = []
                let futureAppointmentList = []
                let paymentWaitingList = []

                for (let index = 0; index < allAppointmentList.length; index++) {
                    const appointmentTemp = allAppointmentList[index];
                    if (appointmentTemp.status > 2 && appointmentTemp.status < 8) {
                        if(!dayjs().isBefore(dayjs(appointmentTemp.appointmentDate).add(30, "minute"))){
                            passedAppointmentList.push(appointmentTemp)
                        }
                    } else if (appointmentTemp.status === 9) {
                        paymentWaitingList.push(appointmentTemp)
                    } else {
                        futureAppointmentList.push(appointmentTemp)
                    }
                }

                setFutureRandevuListesi(futureAppointmentList)

            } else if (appointmentListResponse.status === 401) {
                props.store.setUserLogout()
                window.location.reload()
            }
        }
        props.store.setLoadingBarStatus(false)
    }

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="RANDEVULARIM" />
                    <Tab label="GEÇMİŞ RANDEVULARIM" />
                    <Tab label="KONSÜLTASYON TALEPLERİ" />
                    <Tab label="ÖDEME BEKLEYEN RANDEVULAR" />
                </Tabs>
            </Box>

            <Card hidden={(activeTab === 0) ? false : true} style={{ boxShadow: "none", minHeight: "75vh" }}>
                <CardContent>
                    <Typography variant='body2' style={{ marginBottom: "15px" }}>
                        <b>ÖNEMLİ NOT:</b> Randevunun gerçekleşmesine 48 saatten az zaman kala gerçekleşen iptallerde, ücret iadesi yapılmamaktadır!
                    </Typography>
                    <TableContainer style={{ width: "100%" }}>
                        <Table aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">RANDEVU BİLGİSİ</TableCell>
                                    <TableCell align="left">DOKTOR BİLGİSİ</TableCell>
                                    <TableCell align="left">UZMANLIK ALANI</TableCell>
                                    <TableCell align="left">RANDEVU TARİHİ</TableCell>
                                    <TableCell align="right">İŞLEMLER</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {futureRandevuListesi.map((appointment, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell align='left'>{appointment.appointmentTitle + ": " + appointment.appointmentDescription}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {`${appointment.doctorTitle} ${appointment.doctorProfessional} ${appointment.doctorName} ${appointment.doctorSurname}`}
                                        </TableCell>

                                        <TableCell align='left'>{appointment.doctorDepartment}</TableCell>
                                        <TableCell align="left">{dayjs(new Date(appointment.appointmentDate)).format("DD.MM.YYYY HH:mm")}</TableCell>
                                        <TableCell align="right">
                                            <Tooltip title="Görüşme Odasına Gir">
                                                <Button color="primary" variant='contained' component={Link} to={"/danisan/gorusme/" + appointment.doctorId + "/" + appointment.appointmentId}
                                                    style={{ margin: "5px" }}>
                                                    <MeetingRoomIcon style={{ marginRight: "10px", color: "#000" }} /> <span style={{ color: "#000" }}>Görüşme Odasına Gir</span>
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Randevuyu İptal Et">
                                                <Button color="error" variant='contained' onClick={() => onClickRandevuIptalButton(appointment.appointmentId)} style={{ margin: "5px" }}>
                                                    <DeleteIcon style={{ marginRight: "10px", color: "#fff" }} /> <span style={{ color: "#fff" }}>İptal Et</span>
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>

            <Card hidden={(activeTab === 1) ? false : true} style={{ boxShadow: "none", minHeight: "75vh" }}>
                <CardContent>
                    <TableContainer style={{ width: "100%" }}>
                        <Table aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">RANDEVU BİLGİSİ</TableCell>
                                    <TableCell align="left">DOKTOR BİLGİSİ</TableCell>
                                    <TableCell align="left">UZMANLIK ALANI</TableCell>
                                    <TableCell align="left">RANDEVU TARİHİ</TableCell>
                                    <TableCell align="right">DETAYLAR</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {passedRandevuListesi.map((appointment, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell align='left'>
                                            {appointment.appointmentTitle + ": " + appointment.appointmentDescription}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {`${appointment.doctorTitle} ${appointment.doctorProfessional} ${appointment.doctorName} ${appointment.doctorSurname}`}
                                        </TableCell>
                                        <TableCell align='left'>{appointment.doctorDepartment}</TableCell>
                                        <TableCell align="left">{dayjs(new Date(appointment.appointmentDate)).format("DD.MM.YYYY HH:mm")}</TableCell>
                                        <TableCell align="right">
                                            <Tooltip title="Görüşme Özeti">
                                                <Button color="primary" variant='contained' component={Link} to={"/danisan/randevu-bilgi/" + appointment.doctorId + "/" + appointment.appointmentId}>
                                                    <InfoIcon style={{ marginRight: "10px", color: "#000" }} /> <span style={{ color: "#000" }}>Görüşme Özeti</span>
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>

            <Card hidden={(activeTab === 2) ? false : true} style={{ boxShadow: "none", minHeight: "75vh" }}>
                <CardContent>
                    <TableContainer style={{ width: "100%" }}>
                        <Table aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">KONSÜLTAN DOKTOR BİLGİSİ</TableCell>
                                    <TableCell align="left">UZMANLIK ALANI</TableCell>
                                    <TableCell align="left">RANDEVU ÜCRETİ</TableCell>
                                    <TableCell align="left">GEÇERLİLİK SÜRESİ</TableCell>
                                    <TableCell align="right">DETAYLAR</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {consultDoctorListesi.map((consultDoctor, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell align='left'>
                                            {consultDoctor.consultDoctorTitle + " " + consultDoctor.consultDoctorName + " " + consultDoctor.consultDoctorSurname}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {consultDoctor.consultDoctorDepartment}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {consultDoctor.consultDoctorExaminationPrice}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {dayjs(consultDoctor.expiryDate).format("DD.MM.YYYY")}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Tooltip title="Seçtiğiniz doktor için randevu al">
                                                <Button variant="contained" style={{ height: "50px", marginTop: "5px", width: "120px", border: "2px solid #000", paddingLeft: "0", paddingRight: "0" }}
                                                    component={Link} to={"/danisan/randevu-olustur/" + consultDoctor.consultDoctorId}>
                                                    <CalendarMonthIcon style={{ color: "#000", marginRight: "10px" }} /> <span style={{ marginTop: "3px", color: "#000" }}>Randevu</span>
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>

            <Card hidden={(activeTab === 3) ? false : true} style={{ boxShadow: "none", minHeight: "75vh" }}>
                <CardContent>
                    <TableContainer style={{ width: "100%" }}>
                        <Table aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">RANDEVU BİLGİSİ</TableCell>
                                    <TableCell align="left">DOKTOR BİLGİSİ</TableCell>
                                    <TableCell align="left">UZMANLIK ALANI</TableCell>
                                    <TableCell align="left">RANDEVU TARİHİ</TableCell>
                                    <TableCell align="right">DETAYLAR</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {waitingPaymentListesi.map((appointment, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell align='left'>
                                            {appointment.appointmentTitle + ": " + appointment.appointmentDescription}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {`${appointment.doctorTitle} ${appointment.doctorProfessional} ${appointment.doctorName} ${appointment.doctorSurname}`}
                                        </TableCell>
                                        <TableCell align='left'>{appointment.doctorDepartment}</TableCell>
                                        <TableCell align="left">{dayjs(new Date(appointment.appointmentDate)).format("DD.MM.YYYY HH:mm")}</TableCell>
                                        <TableCell align="right">
                                            <Tooltip title="Ödeme ekranına git">
                                                <Button color="primary" variant='contained' component={Link}
                                                    to={"/danisan/" + props.store.patientId + "/appointment/" + appointment.barcode + "/payment"}>
                                                    <PaymentIcon style={{ marginRight: "10px", color: "#000" }} /> <span style={{ color: "#000" }}>Ödeme Ekranına Git</span>
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>

            <Dialog open={randevuIptalWarningDisplay} onClose={() => setRandevuIptalWarningDisplay(false)}>
                <AppBar color="error" sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Randevu İptal Uyarısı
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Typography variant='body1'> Randevunun gerçekleşmesine 48 saatten az zaman kala gerçekleşen iptal işlemlerinde ücret iadesi yapılmamaktadır! <br/>
                        </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color="primary" onClick={() => setRandevuIptalWarningDisplay(false) }>
                        Kapat
                    </Button>
                    <Button color="error" variant='contained' onClick={() => onClickRandevuIptal(selectedAppointmentId.current)} style={{ margin: "5px" }}>
                        <span style={{ color: "#fff" }}>Randevu iptalini onaylıyorum</span>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}))