import axios from "axios";
import parsePatientInsuranceData from "../parsers/PatientInsuranceDataParser";

const getPatientInsuranceInfo = async (baseUrl, token, patientId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + `/patient/` + patientId + "/insurance", config)
        .then((res) => {
            if(res.data.data !== null){
                let insuranceDataList = res.data.data

                let fixedInsuranceDataList = []
                for (let index = 0; index < insuranceDataList.length; index++) {
                    let insuranceTemp = insuranceDataList[index];
                    insuranceTemp = parsePatientInsuranceData(insuranceTemp)
                    fixedInsuranceDataList.push(insuranceTemp)
                }

                getResponse = {
                    status: res.data.status,
                    result: fixedInsuranceDataList
                }
            }else{
                getResponse = {
                    status: res.data.status,
                    result: res.data.message
                }
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    result:  error.response.status
                }
            }
        });
    return getResponse
}

export default getPatientInsuranceInfo;