import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"
import { toJS } from 'mobx';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import patientUpdate from '../../../utils/api/patient/PatientUpdate'
import patientMedicalInfoUpdate from '../../../utils/api/patient/PatientMedicalinfoUpdate'
import getPatientMedicalInfo from '../../../utils/api/patient/GetPatientMedicalInfo'
import deletePatientMedicalInfo from '../../../utils/api/patient/DeletePatientMedicalInfo'

import CancelIcon from '@mui/icons-material/Cancel';

export const MedikalBilgiMenu = inject("store")(observer((props) => {

    const [kronikHastalik, setKronikHastalik] = useState({
        medicalInfoTypeId: 3,
        description: ""
    })

    const [alerji, setAlerji] = useState({
        medicalInfoTypeId: 10,
        description: ""
    })

    /*const [medicineName, setMedicineName] = useState("")
    const [medicinePeriod, setMedicinePeriod] = useState("")
    const [medicineAdet, setMedicineAdet] = useState(0)
    const [medicineDoz, setMedicineDoz] = useState("")*/


    const [ameliyat, setAmeliyat] = useState({
        medicalInfoTypeId: 9,
        description: ""
    })

    const [yakinKronolojikHasta, setYakinKronolojikHasta] = useState({
        medicalInfoTypeId: 11,
        description: ""
    })

    const [kronikHastalikListesi, setKronikHastalikListesi] = useState([])
    const [alerjiListesi, setAlerjiListesi] = useState([])
    //const [ilacListesi, setIlacListesi] = useState([])
    const [ameliyatListesi, setAmeliyatListesi] = useState([])
    const [yakinKronikHastaListesi, setYakinKronikHastaListesi] = useState([])

    useEffect(() => {
        // Update the document title using the browser API
        getPatientMedicalInfoReq()
        // eslint-disable-next-line
    }, []);

    const onChangePatientTextInput = (event) => {
        let patient = toJS(props.store.patient[0])
        patient[event.target.name] = Number(event.target.value)
        props.store.setPatient(patient)
    }

   /* const onChangeBloodGroup = (event) => {
        let patient = toJS(props.store.patient[0])
        patient.bloodGroup = event.target.value
        props.store.setPatient(patient)
    }*/

    const getPatientMedicalInfoReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getMedicalInfoResponse = await getPatientMedicalInfo(props.store.baseUrl, props.store.token, props.store.patient[0].id)
        if (getMedicalInfoResponse !== null && getMedicalInfoResponse !== undefined) {
            if (getMedicalInfoResponse.status === 200) {
                //console.log("getMedicalInfoResponse: ", getMedicalInfoResponse.result.medicalInfos)
                let medicalInfoResultArray = getMedicalInfoResponse.result.medicalInfos

                let kronikHastalikArray = []
                let alerjiArray = []
                let ameliyatArray = []
                let yakinKronikHastalikArray = []
                let ilacArray = []
                for (let index = 0; index < medicalInfoResultArray.length; index++) {
                    const tempMedicalInfoTemp = medicalInfoResultArray[index];
                    //kronik hastalıklar
                    if (tempMedicalInfoTemp.medicalInfoTypeId === 3) {
                        kronikHastalikArray.push(tempMedicalInfoTemp)
                        //alerji
                    } else if (tempMedicalInfoTemp.medicalInfoTypeId === 10) {
                        alerjiArray.push(tempMedicalInfoTemp)
                        //ameliyat
                    } else if (tempMedicalInfoTemp.medicalInfoTypeId === 9) {
                        ameliyatArray.push(tempMedicalInfoTemp)
                        //yakinların kronik hasta listesi
                    } else if (tempMedicalInfoTemp.medicalInfoTypeId === 11) {
                        yakinKronikHastalikArray.push(tempMedicalInfoTemp)
                        //ilac
                    } else if (tempMedicalInfoTemp.medicalInfoTypeId === 8) {
                        ilacArray.push(tempMedicalInfoTemp)
                        //yakinların kronik hasta listesi
                    }
                }
                setKronikHastalikListesi(kronikHastalikArray)
                setAlerjiListesi(alerjiArray)
                setAmeliyatListesi(ameliyatArray)
                setYakinKronikHastaListesi(yakinKronikHastalikArray)
                //setIlacListesi(ilacArray)
            }else if (getMedicalInfoResponse.status === 401) {
                props.store.setUserLogout()
                props.history.push("/danisan/kullanici-girisi")
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    const updatePatient = async () => {
        props.store.setLoadingBarStatus(false)

        let patient = toJS(props.store.patient[0])

        let patientResponse = await patientUpdate(props.store.baseUrl, props.store.token, props.store.patient[0].id, patient)
        if (patientResponse !== null && patientResponse !== undefined) {
            if (patientResponse.status === 200) {
                props.store.setMessage("Bilgileriniz başarıyla güncellendi.", true, "success")
            } else {
                props.store.setMessage("Bilgileriniz güncellenirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Bilgileriniz güncellenirken hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const onChangeKronikHastalik = (event) => {
        setKronikHastalik({ ...kronikHastalik, description: event.target.value })
    }

    const onChangeAlerjiTextInput = (event) => {
        setAlerji({ ...alerji, description: event.target.value })
    }

    const onChangeAmeliyatTextInput = (event) => {
        setAmeliyat({ ...ameliyat, description: event.target.value })
    }

    const onChangeYakinlarKronikHastaTextInput = (event) => {
        setYakinKronolojikHasta({ ...yakinKronolojikHasta, description: event.target.value })
    }

    const onClickAddKronikHastalik = async () => {
        setKronikHastalikListesi([...kronikHastalikListesi, kronikHastalik])
        props.store.setLoadingBarStatus(false)

        let kronikHastaCreateResponse = await patientMedicalInfoUpdate(props.store.baseUrl, props.store.token, props.store.patient[0].id, kronikHastalik)
        if (kronikHastaCreateResponse !== null && kronikHastaCreateResponse !== undefined) {
            if (kronikHastaCreateResponse.status === 200) {
                props.store.setMessage("Kronik hastalık başarıyla eklendi.", true, "success")
            } else {
                props.store.setMessage("Kronik hastalık eklenirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Kronik hastalık eklenirken hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const onClickAddAlerji = async () => {
        setAlerjiListesi([...alerjiListesi, alerji])
        props.store.setLoadingBarStatus(false)

        let alerjiCreateResponse = await patientMedicalInfoUpdate(props.store.baseUrl, props.store.token, props.store.patient[0].id, alerji)
        if (alerjiCreateResponse !== null && alerjiCreateResponse !== undefined) {
            if (alerjiCreateResponse.status === 200) {
                props.store.setMessage("Alerji başarıyla eklendi.", true, "success")
            } else {
                props.store.setMessage("Alerji eklenirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Alerji eklenirken hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)

    }

    const onClickAddAmeliyat = async () => {
        setAmeliyatListesi([...ameliyatListesi, ameliyat])
        props.store.setLoadingBarStatus(false)

        let alerjiCreateResponse = await patientMedicalInfoUpdate(props.store.baseUrl, props.store.token, props.store.patient[0].id, ameliyat)
        if (alerjiCreateResponse !== null && alerjiCreateResponse !== undefined) {
            if (alerjiCreateResponse.status === 200) {
                props.store.setMessage("Ameliyat bilgisi başarıyla eklendi.", true, "success")
            } else {
                props.store.setMessage("Ameliyat bilgisi eklenirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Ameliyat bilgisi eklenirken hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const onClickAddYakinKronolojikHastalik = async () => {

        setYakinKronikHastaListesi([...yakinKronikHastaListesi, yakinKronolojikHasta])

        props.store.setLoadingBarStatus(false)

        let alerjiCreateResponse = await patientMedicalInfoUpdate(props.store.baseUrl, props.store.token, props.store.patient[0].id, yakinKronolojikHasta)
        if (alerjiCreateResponse !== null && alerjiCreateResponse !== undefined) {
            if (alerjiCreateResponse.status === 200) {
                props.store.setMessage("Yakın Kronolojik hastalık bilgisi başarıyla eklendi.", true, "success")
            } else {
                props.store.setMessage("Yakın Kronolojik hastalık bilgisi eklenirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Yakın Kronolojik hastalık bilgisi eklenirken hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    /*const onClickAddIlac = async () => {

        let medicine = {
            medicalInfoTypeId: 8,
            description: medicineName + " Periyot: " + medicinePeriod + " Adet:" + medicineAdet + " Doz: " + medicineDoz
        }

        setIlacListesi([...ilacListesi, medicine])

        props.store.setLoadingBarStatus(false)

        let medicineCreateResponse = await patientMedicalInfoUpdate(props.store.baseUrl, props.store.token, props.store.patient[0].id, medicine)
        if (medicineCreateResponse !== null && medicineCreateResponse !== undefined) {
            if (medicineCreateResponse.status === 200) {
                props.store.setMessage("İlaç bilgisi başarıyla eklendi.", true, "success")
            } else {
                props.store.setMessage("İlaç bilgisi eklenirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("İlaç bilgisi eklenirken hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)

    }*/

    const handleDeleteKronikHastalik = async (medicalInfoId) => {
        props.store.setLoadingBarStatus(false)
        let deleteMedicalInfoResponse = await deletePatientMedicalInfo(props.store.baseUrl, props.store.token, props.store.patient[0].id, medicalInfoId)
        if (deleteMedicalInfoResponse !== null && deleteMedicalInfoResponse !== undefined) {
            if (deleteMedicalInfoResponse.status === 200) {
                props.store.setMessage("Başarıyla silindi", true, "success")
                window.location.reload()
            } else {
                props.store.setMessage("Silme işleminde hata oluştu", true, "error")
            }
        } else {
            props.store.setMessage("Silme işleminde hata oluştu", true, "error")
        }
        props.store.setLoadingBarStatus(true)
    }

    /*
    const onChangeMedicineNameTextInput = (event) => {
        setMedicineName(event.target.value)
    }

    const onChangeMedicinePeriodTextInput = (event) => {
        setMedicinePeriod(event.target.value)
    }

    const onChangeMedicineAdetTextInput = (event) => {
        setMedicineAdet(Number(event.target.value))
    }

    const onChangeMedicineDozTextInput = (event) => {
        setMedicineDoz(event.target.value)
    }*/

    return (
        <Grid container spacing={3} style={{ padding: "20px", paddingLeft: "0" }}>
            <Grid item lg={6} sm={12} xl={6} xs={12} >
                <Card>
                    <CardContent>
                        <Typography style={{ fontWeight: "bold" }}>Fiziksel Özellikler</Typography>
                        <Accordion style={{ background: "#fff", marginTop: "15px", boxShadow: "none" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <Typography style={{ fontWeight: "bold" }}>Boy</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField name="height" variant='outlined' type="number" value={props.store.patient[0].height} onChange={onChangePatientTextInput}
                                    label="Boy(cm)" style={{ width: "100%" }}></TextField>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ background: "#fff", boxShadow: "none", marginTop: "15px" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header" >
                                <Typography style={{ fontWeight: "bold" }}>Ağırlık</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField name="weight" variant='outlined' type="number" label="Ağırlık(kg)"
                                    value={props.store.patient[0].weight} onChange={onChangePatientTextInput}
                                    style={{ width: "100%" }}></TextField>
                            </AccordionDetails>
                        </Accordion>
                        {/**
                             <Accordion style={{ background: "#fff", boxShadow: "none", marginTop: "15px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header">
                                    <Typography style={{fontWeight: "bold"}}>Kan Grubu</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Kan Grubu</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={props.store.patient[0].bloodGroup}
                                            label="Kan Grubu" onChange={onChangeBloodGroup}>
                                            <MenuItem value={0}>Bilmiyorum</MenuItem>
                                            <MenuItem value={1}>A RH +</MenuItem>
                                            <MenuItem value={2}>A RH -</MenuItem>
                                            <MenuItem value={3}>B RH +</MenuItem>
                                            <MenuItem value={4}>B RH -</MenuItem>
                                            <MenuItem value={5}>AB RH +</MenuItem>
                                            <MenuItem value={6}>AB RH -</MenuItem>
                                            <MenuItem value={7}>0 RH +</MenuItem>
                                            <MenuItem value={8}>0 RH -</MenuItem>
                                        </Select>
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>
                         */
                        }
                    </CardContent>
                    <CardActions>
                        <Button variant='contained' color="primary" style={{ marginLeft: "auto", marginRight: "15px", color: "#000" }} onClick={updatePatient}>
                            Güncelle
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item lg={6} sm={12} xl={6} xs={12}>
                <Card>
                    <CardContent>
                        <Typography style={{ fontWeight: "bold" }}>Sağlık Geçmişi</Typography>
                        <Accordion style={{ background: "#fff", marginTop: "15px", boxShadow: "none" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <Typography style={{ fontWeight: "bold" }}>Kronik Hastalıklar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField variant='outlined' label="Hastalık İsmi" style={{ width: "calc(100% - 100px)" }}
                                    value={kronikHastalik.description} onChange={onChangeKronikHastalik}></TextField>
                                <Button variant='contained' style={{ margin: "10px", color: "#000" }} onClick={onClickAddKronikHastalik}>Ekle</Button>
                                <Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
                                    {(kronikHastalikListesi !== null) ? kronikHastalikListesi.map((kronikHastalik, idx) => (
                                        <Chip key={idx} label={kronikHastalik.description} onDelete={() => handleDeleteKronikHastalik(kronikHastalik.medicalInfoId)}
                                            color="primary" style={{ color: "#000" }} deleteIcon={<CancelIcon style={{ color: "#000", fontSize: "24px" }} />} />
                                    )) : null}

                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ background: "#fff", boxShadow: "none", marginTop: "15px" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography style={{ fontWeight: "bold" }}>Alerjiler</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField variant='outlined' label="Alerji İsmi" style={{ width: "calc(100% - 100px)" }}
                                    value={alerji.description} onChange={onChangeAlerjiTextInput}></TextField>
                                <Button variant='contained' style={{ margin: "10px", color: "#000" }} onClick={onClickAddAlerji}>Ekle</Button>
                                <Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
                                    {(alerjiListesi !== null) ? alerjiListesi.map((alerji, idx) => (
                                        <Chip key={idx} label={alerji.description} onDelete={() => handleDeleteKronikHastalik(alerji.medicalInfoId)}
                                            color="primary" style={{ color: "#000" }} deleteIcon={<CancelIcon style={{ color: "#000", fontSize: "24px" }} />} />
                                    )) : null}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                         {/**
                        <Accordion style={{ background: "#fff", boxShadow: "none", marginTop: "15px" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header">
                                <Typography style={{ fontWeight: "bold" }}>Kullanılan İlaçlar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField variant='outlined' label="İlaç İsmi" style={{ width: "100%" }}
                                            value={medicineName} onChange={onChangeMedicineNameTextInput}></TextField>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl variant='outlined' style={{ width: "100%" }}>
                                            <InputLabel id="demo-simple-select-label">Kullanım Periyodu</InputLabel>
                                            <Select labelId="demo-simple-select-label"
                                                id="demo-simple-select" variant='outlined'
                                                value={medicinePeriod}
                                                label="Kullanım Periyodu" onChange={onChangeMedicinePeriodTextInput}>
                                                <MenuItem value={"Gün"}>Günlük</MenuItem>
                                                <MenuItem value={"Hafta"}>Haftalık</MenuItem>
                                                <MenuItem value={"Ay"}>Aylık</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField variant='outlined' label="Adet" style={{ width: "100%" }}
                                            value={medicineAdet} onChange={onChangeMedicineAdetTextInput}></TextField>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField variant='outlined' label="Doz" style={{ width: "100%" }}
                                            value={medicineDoz} onChange={onChangeMedicineDozTextInput}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant='contained' style={{ height: "55px", color: "#000" }} onClick={onClickAddIlac}>Ekle</Button>
                                    </Grid>
                                </Grid>

                                <Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
                                    {(ilacListesi !== null) ? ilacListesi.map((ilac, idx) => (
                                        <Chip key={idx} label={ilac.description}
                                            onDelete={() => handleDeleteKronikHastalik(ilac.medicalInfoId)} color="primary" style={{ color: "#000" }}
                                            deleteIcon={<CancelIcon style={{ color: "#000", fontSize: "24px" }} />} />
                                    )) : null}
                                </Stack>
                            </AccordionDetails>
                                    </Accordion> 
                        **/}

                        <Accordion style={{ background: "#fff", boxShadow: "none", marginTop: "15px" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header">
                                <Typography style={{ fontWeight: "bold" }}>Ameliyatlar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField variant='outlined' label="Ameliyat İsmi" style={{ width: "calc(100% - 100px)" }}
                                    value={ameliyat.description} onChange={onChangeAmeliyatTextInput}></TextField>
                                <Button variant='contained' style={{ margin: "10px", color: "#000" }} onClick={onClickAddAmeliyat}>Ekle</Button>
                                <Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
                                    {(ameliyatListesi !== null) ? ameliyatListesi.map((ameliyat, idx) => (
                                        <Chip key={idx} label={ameliyat.description} onDelete={() => handleDeleteKronikHastalik(ameliyat.medicalInfoId)}
                                            color="primary" style={{ color: "#000" }} deleteIcon={<CancelIcon style={{ color: "#000", fontSize: "24px" }} />} />
                                    )) : null}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion style={{ background: "#fff", boxShadow: "none", marginTop: "15px" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header">
                                <Typography style={{ fontWeight: "bold" }}>Yakınlarınızda Bulunan Kronik Hastalıklar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <TextField variant='outlined' label="Kronik Hastalık İsmi (Örn: Yakınlık Derecesi - Kronik Hastalık İsmi)" style={{ width: "calc(100% - 100px)" }}
                                        value={yakinKronolojikHasta.description} onChange={onChangeYakinlarKronikHastaTextInput} ></TextField>
                                    <Button variant='contained' style={{ margin: "10px", color: "#000" }} onClick={onClickAddYakinKronolojikHastalik}>Ekle</Button>
                                    <Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
                                        {(yakinKronikHastaListesi !== null) ? yakinKronikHastaListesi.map((yakinHastalik, idx) => (
                                            <Chip key={idx} label={yakinHastalik.description} onDelete={() => handleDeleteKronikHastalik(yakinHastalik.medicalInfoId)}
                                                color="primary" style={{ color: "#000" }} deleteIcon={<CancelIcon style={{ color: "#000", fontSize: "24px" }} />} />
                                        )) : null}
                                    </Stack>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}))