import * as React from 'react';
import { observer, inject } from "mobx-react"
import { Link } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { FaRegUserCircle } from 'react-icons/fa'
import { FiLogOut } from 'react-icons/fi'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';

import getUserLogout from '../../utils/api/patient/UserLogout'

function stringAvatar(name) {
    //console.log("name: ", name)
    return (name !== "") ? { 
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    }: {children: ""};
}

export const ProfileButtonMenu = inject("store")(observer((props) => {

    const [profileMenuTarget, setProfileMenuTarget] = useState(null)
    const [profileMenuDisplay, setProfileMenuDisplay] = useState(false)

    const onHandleProfileMenuOpen = (event) => {
        setProfileMenuDisplay(true)
        setProfileMenuTarget(event.currentTarget)
    }

    const onClickProfileMenuClose = () => {
        setProfileMenuDisplay(false)
        setProfileMenuTarget(null)

    }

    const onLogout = async() => {
        props.store.setLoadingBarStatus(false)
        await getUserLogout(props.store.baseUrl, props.store.token)
        props.store.setUserLogout()
        props.store.setLoadingBarStatus(true)
        window.location.reload()
    }


    return (
        <>
            <Stack className="user-menu" direction="row" spacing={2} onClick={(event) => onHandleProfileMenuOpen(event)}
                style={{ float: "right", marginRight: "10px" }}>
                <Avatar {...stringAvatar((props.store.user[0] !== null && props.store.user[0] !== undefined) ? props.store.user[0].name + " " + props.store.user[0].surname : "")} color="primary" style={{ paddingTop: "3px" }} />
                <Typography variant='body1' style={{ marginTop: "8px" }}>{(props.store.user[0] !== null && props.store.user[0] !== undefined) ? props.store.user[0].name + " " + props.store.user[0].surname : ""}</Typography>
                <KeyboardArrowDownIcon style={{ marginTop: "8px" }} />
            </Stack>
            <Menu id="profile-menu" className='profile-menu-result'
                anchorEl={profileMenuTarget}
                open={profileMenuDisplay}
                onClose={onClickProfileMenuClose}>
                <MenuItem to="/danisan/profilim" component={Link} style={{ width: "220px", padding: "20px", textDecoration: "none", color: "#000" }}>
                    <FaRegUserCircle style={{ fontSize: "24px", marginRight: "5px" }} />
                    <Typography variant="body1" style={{ fontWeight: "400" }}>Profilim</Typography>
                </MenuItem>
                <MenuItem onClick={() => onLogout()} style={{ width: "220px", padding: "20px" }}>
                    <FiLogOut style={{ color: "#D14343", fontSize: "24px", marginRight: "5px" }} />
                    <Typography variant="body1" style={{ fontWeight: "600", color: "#D14343" }}>Çıkış Yap</Typography>
                </MenuItem>
            </Menu>
        </>
    );
}))
