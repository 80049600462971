import axios from "axios";

const getPatientConsultDoctorList = async (baseUrl, token, patientId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null 

    await axios.get(baseUrl + `/patient/` + patientId + "/appointment/consult", config)
        .then((res) => {
            //console.log("res.data: ", res.data)

            getResponse = {
                status: res.data.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    result: error.response.status
                }
            }
        });
    return getResponse
}

export default getPatientConsultDoctorList;