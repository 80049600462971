import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import { LeftMenu } from '../components/common/LeftMenu';
import { Box, Button, Card, CardContent, Container, Grid, Link, Typography} from '@mui/material';
import { ProfileButtonMenu } from '../components/common/ProfileButtonMenu';
import { TopSearchMenu } from '../components/danisan/search/TopSearchMenu';
import { Footer } from '../components/common/Footer';

import ErrorIcon from '@mui/icons-material/Error';

class PaymentFailedPage extends Component {
    isPromo = new URLSearchParams(this.props.history.location.search).has("promo");
    constructor(props) {
        super(props)
        this.state = {
          
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.store.setUserLogout()
            this.props.history.push("/danisan/kullanici-girisi");
        } 
    }

    render() {
        return (
            <div className="container mps-container">
                <LeftMenu activePage="PaymentFailed" />
                <Box component="main" sx={{
                    width: { xs: "calc(100% - 90px)", md: "calc(100% - 235px)" }, marginLeft: { xs: "90px", md: "235px" },
                    flexGrow: 1,
                    py: 8
                }} style={{ maxHeight: "100vh", paddingTop: "10px" }} >
                    <Container style={{ padding: "0" }} maxWidth="100%">
                        <Grid container spacing={0} style={{ marginBottom: "0px" , flexWrap: "wrap-reverse"}}>
                            <Grid item lg={8} sm={6} xl={8} xs={12}>
                                <TopSearchMenu history={this.props.history}/>
                            </Grid>
                            <Grid item lg={4} sm={6} xl={4} xs={12}>
                                <ProfileButtonMenu />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} style={{ padding: "30px" }}>
                            <Grid item lg={3} sm={3} xl={3} xs={3} >
                            </Grid>
                            <Grid item lg={6} sm={6} xl={6} xs={6} >
                                <Card style={{ width: "500px", background: "#e53935" }}>
                                    <CardContent style={{textAlign: "center"}}>
                                        <Typography variant='h3' gutterBottom style={{ fontWeight: "bold", color: "#fff" }}>
                                        <ErrorIcon style={{fontSize: "70px"}}/> <br/>
                                        Ödeme sırasında hata oluştu.
                                        </Typography>
                                        <Button variant='contained' color='error'  component={Link} href={this.isPromo ? "/danisan/paketlerim" : "/danisan/randevularim/3"}
                                            style={{marginBottom: "10px", marginTop: "30px", color: "#fff" }} >
                                            {this.isPromo ? "PAKETLERİ GÖRÜNTÜLE" : "ÖDEME BEKLEYEN RANDEVULARI GÖRÜNTÜLE"}</Button>

                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={3} sm={3} xl={3} xs={3} >
                            </Grid>
                        </Grid>

                    </Container>
                    <Footer />
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(PaymentFailedPage));
