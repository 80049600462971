import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"
import { Button, CardActions, CardMedia, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';

import 'dayjs/locale/tr';
import dayjs from 'dayjs';
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorder from '@mui/icons-material/FavoriteBorder'
import DateRangeIcon from '@mui/icons-material/DateRange';
import WarningIcon from '@mui/icons-material/Warning';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import getPatientDoctorInfo from '../../../utils/api/patient/GetPatientDoctorInfo'
import getPatientDoctorAvailableHours from '../../../utils/api/patient/Appointment/GetPatientDoctorAvailableHours'
import postAppointment from '../../../utils/api/patient/Appointment/PostAppointment'
import { getDoctorPromos, insertPatientPromo } from '../../../utils/api/patient/Promos';

import PromoCard from '../../common/PromoCard';
import { CountDown } from '../../common/CountDown';
import { Doctor } from '../../../model/Doctor/Doctor';

export const RandevuOlusturMenu = inject("store")(observer((props) => {

    const [selectedDoctor, setSelectedDoctor] = useState(Doctor.create({}))
    const [randevuDate, setRandevuDate] = useState()

    const [availableHours, setAvailableHours] = useState([])
    const [selectedHoursIdx, setSelectedHoursIdx] = useState(-1)

    const [randevuOnBilgilendirmeMenuDisplay, setRandevuOnBilgilendirmeMenuDisplay] = useState(false)
    const [randevuTitle, setRandevuTitle] = useState("")
    const [couponCode, setCouponCode] = useState("")
    const [isHourSelected, setIsHourSelected] = useState(false)

    const [promoList, setPromoList] = useState([])
    const isPaket = new URLSearchParams(props.history.location.search).has("paket");

    //const [odemeBilgilendirmeMenuDisplay, setOdemeBilgilendirmeMenuDisplay] = useState(false)

    useEffect(() => {
        // Update the document title using the browser API

        getDoctorInfoReq(props.doctorId);
        getPromoList();
        // eslint-disable-next-line
    }, [props.doctorId]);

    useEffect(() => {
        //console.log("randevuDate: ", randevuDate)
        getDoctorAvailableHoursReq(randevuDate)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [randevuDate])

    const onChangeRandevuBilgilendirmeTextField = (event) => {
        setRandevuTitle(event.target.value)
    }

    const onChangeCouponField = (event) => {
        setCouponCode(event.target.value)
    }

    const getDoctorInfoReq = async (doctorId) => {
        props.store.setLoadingBarStatus(false)

        let getDoctorInfoResponse = await getPatientDoctorInfo(props.store.baseUrl, props.store.token, props.store.patient[0].id, doctorId)
        if (getDoctorInfoResponse !== null && getDoctorInfoResponse !== undefined) {
            if (getDoctorInfoResponse.status === 200) {
                //console.log("getDoctorInfoResponse: ", getDoctorInfoResponse)
                if (getDoctorInfoResponse.result !== null) {
                    setSelectedDoctor(getDoctorInfoResponse.data)
                } else if (getDoctorInfoResponse.status === 401) {
                    props.store.setUserLogout()
                    props.history.push("/danisan/kullanici-girisi")
                } else {
                    props.store.setMessage("Doktor bilgisi getirilirken hata oluştu.", true, "error")
                }
            } else {
                props.store.setMessage("Doktor bilgisi getirilirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Doktor bilgisi getirilirken hata oluştu.", true, "error")
        }
        props.store.setLoadingBarStatus(true)
    }

    const getDoctorAvailableHoursReq = async (date) => {
        props.store.setLoadingBarStatus(false)
        let inputDate = dayjs(date).format('YYYY-MM-DD');

        let getDoctorAvailableHoursReqResponse = await getPatientDoctorAvailableHours(props.store.baseUrl, props.store.token, props.store.patient[0].id,
            props.doctorId, inputDate)
       // console.log("getDoctorAvailableHoursReqResponse: ", getDoctorAvailableHoursReqResponse)
        if (getDoctorAvailableHoursReqResponse !== undefined && getDoctorAvailableHoursReqResponse !== null) {
            if (getDoctorAvailableHoursReqResponse.status === 200) {
                if (getDoctorAvailableHoursReqResponse.result !== null && getDoctorAvailableHoursReqResponse.result.length > 0) {
                    const uniqueData = Array.from(new Set(getDoctorAvailableHoursReqResponse.result.map(JSON.stringify)), JSON.parse);
                    const currentDate = new Date();
                    const filteredHours = uniqueData.filter(hour => new Date(hour.startDate) >= currentDate);
                    setAvailableHours(filteredHours.sort((a, b) => new Date(a.startDate) - new Date(b.startDate)));
                } else {
                    props.store.setMessage("Doktorunuz çalışma saatlerini tanımlamadığı için randevu oluşturamazsınız.", true, "error")
                    setAvailableHours([])
                }
            } else if (getDoctorAvailableHoursReqResponse.status === 401) {
                props.store.setUserLogout()
                window.location.reload()
            } else {
                props.store.setMessage("Doktorunuz çalışma saatlerini tanımlamadığı için randevu oluşturamazsınız.", true, "error")
                setAvailableHours([])
            }
        } else {
            props.store.setMessage("Doktorunuz çalışma saatlerini tanımlamadığı için randevu oluşturamazsınız.", true, "error")
            setAvailableHours([])
        }
        props.store.setLoadingBarStatus(true)
    }

    const onClickSelectedHours = (selectedDate, selectedHourIdx) => {
        setRandevuDate(selectedDate)
        setSelectedHoursIdx(selectedHourIdx)
        setIsHourSelected(true)
    }

    const onClickCreateAppointment = async () => {
        props.store.setLoadingBarStatus(false)

        let appointmentData = {
            doctorId: props.doctorId,
            title: props.store.patient[0].name + " " + props.store.patient[0].surname,
            description: randevuTitle,
            coupon: couponCode,
            appointmentDate: randevuDate,
            email: props.store.patient[0].email
        }

        //console.log("appointmentData: ", appointmentData)

        let createAppointmentResponse = await postAppointment(props.store.baseUrl, props.store.token, props.store.patient[0].id, appointmentData)
        if (createAppointmentResponse !== null && createAppointmentResponse !== undefined) {
            if (createAppointmentResponse.status === 200) {
                //props.store.setOdemeBilgilendirmeMenuDisplay(true)
                //props.store.setAppointmentCreateDate(new Date())
                if (createAppointmentResponse.result !== null) {
                    let odemeLink = createAppointmentResponse.result.data;
                    if (odemeLink) window.open(odemeLink, "_blank");
                }
                props.store.setMessage("Randevu  oluşturma işlemi başarıyla gerçekleştirildi.", true, "success");
            } else if (createAppointmentResponse.status === 201) {
                props.store.setMessage(createAppointmentResponse.result.message, true, "success");
            } else {
                props.store.setMessage(createAppointmentResponse.result.message, true, "error");
            }
            setRandevuOnBilgilendirmeMenuDisplay(false);
        }
        props.store.setLoadingBarStatus(true);
    }

    const odemeEkraninaGit = () => {
        props.store.setOdemeBilgilendirmeMenuDisplay(false)

        //props.history.push("/danisan/odeme-ekrani")
    }

    const randevuEkraninaGeriDon = () => {
        props.store.setOdemeBilgilendirmeMenuDisplay(false)
        props.store.setOdemeSuresiDolduMenuDisplay(false)
    }

    const onClickOpenRandevuOlusturMenuDisplay = () => {
        if (isHourSelected) {
            setRandevuOnBilgilendirmeMenuDisplay(true);
            setCouponCode("");
            setRandevuTitle("");
        } else {
            props.store.setMessage("Randevu saati seçilmeden randevu oluşturma işlemini yapamazsınız.", true, "error")
        }
    }

    const onClickCloseRandevuOlusturMenu = () => {
        setRandevuOnBilgilendirmeMenuDisplay(false)
    }

    const getPromoList = async () => {
        props.store.setLoadingBarStatus(false)
        let getPromoListRes = await getDoctorPromos(props.store.baseUrl, props.store.token, props.store.patient[0].id, props.doctorId)
        if (getPromoListRes !== null && getPromoListRes !== undefined) {
            if (getPromoListRes.status === 200) {
                if (getPromoListRes.data !== null) {
                    setPromoList(getPromoListRes.data)
                } else {
                    setPromoList([])
                }
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const buyPromo = async (promoid) => {
        props.store.setLoadingBarStatus(false)
        let insertPPRes = await insertPatientPromo(props.store.baseUrl, props.store.token, props.store.patient[0].id, promoid)
        if (insertPPRes !== null && insertPPRes !== undefined) {
            if (insertPPRes.status === 200) {
                if (insertPPRes.data !== null) {
                    const odemeLink = insertPPRes.data;
                    if (odemeLink) window.open(odemeLink, "_blank");
                    props.store.setMessage("Ödeme ekranına yönlendiriliyorsunuz...", true, "success");
                } else {
                    props.store.setMessage(insertPPRes.result.message, true, "success");
                }
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant='h6' style={{ marginBottom: "15px" }}>Randevu Al</Typography>
                    <Grid container spacing={0} style={{ marginBottom: "0px" }}>
                        <Grid item lg={3} sm={6} xl={3} xs={12}>
                            <Typography style={{ fontWeight: "bold" }}>1. Doktor Bilgisi</Typography>
                            <CardMedia
                                component="img"
                                sx={{ width: "100px", marginTop: "15px" }}
                                image={(selectedDoctor.avatar !== null) ? props.store.baseUrl + selectedDoctor.avatar : "/assets/images/PersonIcon.png"}
                                alt="Avatar"
                            />
                            <Typography variant='body2' style={{ marginBottom: "15px", marginTop: "15px" }}><b>Adı Soyadı:</b> {selectedDoctor.title.titleName ? selectedDoctor.title.titleName : ""} {selectedDoctor.professional.professionalName} {selectedDoctor.name} {selectedDoctor.surname}</Typography>
                            <Typography variant='body2' style={{ marginBottom: "5px" }}><b>Uzmanlık Alanı:</b> {selectedDoctor.department.departmentName}</Typography>
                            <Typography variant='body2'><b>Favoriye Alınma Sayısı: </b>
                                <Checkbox checked={true} icon={<FavoriteBorder />} checkedIcon={<FavoriteIcon />} />
                                <span style={{ fontSize: "14px" }}>{selectedDoctor.doctorFavCount}</span>
                            </Typography>
                            <Typography variant='body2' style={{ marginBottom: "5px" }}><b>Muayene Ücreti:</b> {selectedDoctor.examinationPrice} TL</Typography>

                        </Grid>
                        <Grid item lg={4} sm={6} xl={4} xs={12}>
                            <Typography style={{ fontWeight: "bold", marginLeft: "0px" }}>2. Takvim</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} style={{ width: "100%" }}>
                                <CalendarPicker
                                    value={randevuDate}
                                    onChange={(newDate) => setRandevuDate(newDate)}
                                    dayOfWeekFormatter={(day) => `${day}.`}
                                    toolbarFormat="DD MMMM dddd"
                                    minDate={new Date()}
                                />
                            </LocalizationProvider>

                        </Grid>
                        <Grid item lg={5} sm={6} xl={5} xs={12}>
                            <Button color='primary' variant='contained' style={{float: "right", color: "#fff"}} component={Link} to={"/danisan/doktor-arama?kategori=doktor"}>
                                <ArrowLeftIcon/> Geri Dön
                            </Button>
                            <Typography style={{ fontWeight: "bold", marginBottom: "15px" }}>3. Randevu Tarih/Saat Seç</Typography>
                            <Typography variant='body1' style={{ marginLeft: "0px" }}><b>Seçilen Gün Bilgisi:   </b>{dayjs(randevuDate).format('DD-MM-YYYY')}</Typography>
                            {(availableHours !== null) ? availableHours.map((hours, idx) => (
                                <Button key={idx} variant="contained" style={{ marginRight: "10px", marginTop: "10px" }}
                                    onClick={() => onClickSelectedHours(hours.startDate, idx)} color={(selectedHoursIdx === idx) ? "success" : "primary"}
                                    disabled={!hours.isActive}>{dayjs(hours.startDate).format('HH:mm')} </Button>
                            )) : null}
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions style={{ textAlign: "right" }}>
                    <Button variant='contained' style={{ marginLeft: "auto", marginRight: "10px", color: "#000", height: "50px" }}
                        onClick={onClickOpenRandevuOlusturMenuDisplay}>
                        <DateRangeIcon /> Randevu Oluştur
                    </Button>
                </CardActions>
                {promoList.length > 0 && <CardContent>
                    <Typography variant='h6' style={{ marginBottom: "15px" }}>Paket Satın Al</Typography>
                    <Grid container spacing={0} style={{ marginBottom: "0px" }}>
                        {promoList.map((promo, idx) => (<PromoCard key={idx} promo={promo} onClick={() => buyPromo(promo.promoId)} />))}
                    </Grid>
                </CardContent>}
            </Card>
            <Dialog open={randevuOnBilgilendirmeMenuDisplay} onClose={onClickCloseRandevuOlusturMenu}>
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>Randevu Bilgisi</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant='body1' style={{ marginLeft: "0px" }}><b>Randevu Gün/Saat Bilgisi:   </b>{dayjs(randevuDate).format('DD.MM.YYYY HH:mm')}</Typography>
                    <TextField value={randevuTitle} onChange={onChangeRandevuBilgilendirmeTextField}
                        label="Randevu Sebebi Açıklaması" multiline rows={3} style={{ width: "100%", marginTop: "10px" }}>
                    </TextField>
                    {isPaket ?
                        <Typography variant="body1" style={{ marginTop: "10px", fontWeight: "bold" }}>Randevu oluşturulurken aktif paketiniz kullanılacaktır.</Typography>
                    :
                        <TextField value={couponCode} onChange={onChangeCouponField}
                            label="Varsa kupon kodu"  style={{ width: "100%", marginTop: "20px" }}>
                        </TextField>}
                </DialogContent>
                <DialogActions>
                    <Button /*component={Link} to=""*/ variant='contained' onClick={onClickCreateAppointment}>
                        <DateRangeIcon /> Randevu Oluştur
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={false}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <WarningIcon color="primary" style={{ fontSize: "50px" }} />
                    <Typography variant="body1" style={{ fontWeight: "bold" }}> Ödeme Bilgilendirme</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Oluşturduğunuz randevu tarih ve saati sizin için 5 dakika boyunca rezerve edilmiştir.
                        <b> 5 dakika</b> içinde ödeme işlemi gerçekleşmediği durumda rezervasyonunuz <b>iptal</b> edilecektir.
                    </DialogContentText>
                    <Typography variant='body1' style={{ marginTop: "15px", fontWeight: "bold" }}>Muayene Bedeli:  150 TL</Typography>
                    <CountDown date={props.store.appointmentCreateDate} time={300000} />
                </DialogContent>
                <DialogActions>
                    <Button component={Link} to="" variant='contained' onClick={odemeEkraninaGit}>
                        Ödeme Ekranına Git
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={false}>
                <DialogTitle id="alert-dialog-title">
                    <WarningIcon color="error" style={{ fontSize: "50px" }} />
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>Randevunuz İptal Edildi</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        5 dakika içerisinde ödeme işlemi gerçekleştirmediğiniz için randevunuz iptal edilmiştir.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button component={Link} to="" variant='contained' onClick={randevuEkraninaGeriDon}>
                        Randevu Ekranına Geri Dön
                    </Button>
                </DialogActions >
            </Dialog >
        </>
    )
}))