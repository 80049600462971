import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Doctor } from "../../../model/Doctor/Doctor";
import dayjs from "dayjs";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import getPatientDoctorAppointmentList from "../../../utils/api/patient/Appointment/GetPatientDoctorAppointmentList";
import { Button } from "@mui/material";

export const DoktorBilgiMenu = inject("store")(
  observer((props) => {
    const [selectedDoctor, setSelectedDoctor] = useState(Doctor.create({}));
    const [appointmentList, setAppointmentList] = useState([]);

    useEffect(() => {
      // Update the document title using the browser API
      // eslint-disable-next-line
      if (props.dataid) {
        getPatientDoctorAppointmentListReq(props.dataid);
      }
      // eslint-disable-next-line
    }, [props.dataid]);

    const getPatientDoctorAppointmentListReq = async (doctorId) => {
      props.store.setLoadingBarStatus(false);

      let startDate = dayjs(new Date())
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      let endDate = dayjs(new Date()).format("YYYY-MM-DD");

      let appointmentListResponse = await getPatientDoctorAppointmentList(
        props.store.baseUrl,
        props.store.token,
        props.patientId,
        doctorId,
        startDate,
        endDate
      );

      //console.log("appointmentListResponse: ", appointmentListResponse)
      if (
        appointmentListResponse !== null &&
        appointmentListResponse !== undefined
      ) {
        if (appointmentListResponse.status === 200) {
          setAppointmentList(appointmentListResponse.data);
          setSelectedDoctor(appointmentListResponse.data[0]);
        } else if (appointmentListResponse.status === 401) {
          props.history.push("/danisan/kullanici-girisi");
        }
      }

      props.store.setLoadingBarStatus(true);
    };

    return (
      <Grid container spacing={3} style={{ marginTop: "0px" }}>
        <Grid item xs={6}>
          <Typography style={{ fontWeight: "bold", marginBottom: "15px" }}>
            Doktor Bilgisi
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="contained"
            style={{ float: "right" }}
            onClick={() => props.history.push("/danisan/doktorlarim")}
          >
            <ArrowLeftIcon /> Geri Dön
          </Button>
        </Grid>
        {appointmentList.length > 0 && selectedDoctor ? (
          <Grid item xs={12}>
            <Grid container spacing={1} style={{ marginTop: "0px" }}>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Adı Soyadı
                </Typography>
                <Typography variant="body2">
                  {(selectedDoctor?.doctorTitle
                    ? selectedDoctor.doctorTitle + " "
                    : "") +
                    selectedDoctor?.doctorProfessional +
                    " " +
                    selectedDoctor?.doctorName +
                    " " +
                    selectedDoctor?.doctorSurname}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Uzmanlık Alanı
                </Typography>
                <Typography variant="body2">
                  {selectedDoctor?.doctorDepartment}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{ fontWeight: "bold", marginTop: "20px" }}
                  variant="body2"
                >
                  Doktor ile yaptığınız görüşmelerin listesi
                </Typography>
                <TableContainer>
                  <Table
                    aria-label="simple table"
                    style={{ marginTop: "15px" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>RANDEVU TARİHİ</TableCell>
                        <TableCell>RANDEVU ÜCRETİ</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {appointmentList.map((appointment, idx) => (
                        <TableRow
                          key={idx}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {dayjs(appointment.appointmentDate).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </TableCell>
                          <TableCell>
                            {appointment.examinationPrice} TL
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Doktordan daha önce randevu almadınız.
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  })
);
