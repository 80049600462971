import { types } from "mobx-state-tree"
import { DoctorDepartment } from "./DoctorDepartment"
import { Role } from "../Role"
import { Address } from "../Address"
import { DoctorTitle } from "./DoctorTitle"
import { DoctorDiploma} from "./DoctorDiploma"
import { DoctorProfessional} from "./DoctorProfessional"
import { DoctorUniversity } from "./DoctorUniversity"

export const Doctor = types
    .model({
        id: types.maybeNull(types.optional(types.number, 0)),
        name: types.maybeNull(types.optional(types.string, "")),
        surname: types.maybeNull(types.optional(types.string, "")),
        email: types.maybeNull(types.optional(types.string, "")),
        tckno: types.maybeNull(types.optional(types.number, 0)),
        mobilePhone: types.maybeNull(types.optional(types.number, 0)),
        isActive: types.maybeNull(types.optional(types.boolean, false)),
        isApproved: types.maybeNull(types.optional(types.boolean, false)),
        avatar: types.maybeNull(types.optional(types.string, "")),
        userType: types.maybeNull(types.optional(types.number, 0)),
        roles: types.maybeNull(types.optional(types.array(Role), [])),
        address: types.maybeNull(types.optional(Address, {})),
        title: types.maybeNull(types.optional(DoctorTitle, {})),
        diploma: types.maybeNull(types.optional(DoctorDiploma, {})),
        professional: types.maybeNull(types.optional(DoctorProfessional, {})),
        department: types.maybeNull(types.optional(DoctorDepartment, {})),
        univercity: types.maybeNull(types.optional(DoctorUniversity, {})),
        shortDesc: types.maybeNull(types.optional(types.string, "")),
        longDesc: types.maybeNull(types.optional(types.string, ""))
    }).actions(self => ({

    }))
