import axios from "axios";

const userRegister = async (baseUrl, body) => {
    let userResponse = null
    await axios.post(baseUrl + `/patient/register`, body)
        .then((res) => {
            //console.log("res.data: ", res.data.status)
            if (res.data.data !== null) {
                userResponse = {
                    status: res.data.status,
                    data: res.data.data
                }
            } else {
                userResponse = {
                    status: res.data.status,
                    data: res.data.message
                }
            }

        }).catch((error) => {
            //console.log("error: ", error)
            if (error.response !== undefined) {
                userResponse = {
                    status: error.response.status
                }
            }
        });

    //console.log("user response: ", userResponse)
    return userResponse
}

export default userRegister;
