import axios from "axios";

const postPatientInsurance = async (baseUrl, token, patientId, patientInsurance) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    await axios.post(baseUrl + `/patient/` + patientId + "/insurance", patientInsurance, config)
        .then((res) => {
            //console.log("res.data: ", res.data)
            if(res.data.status === 200){
                putResponse = {
                    status: res.data.status,
                    result: res.data.data
                }
            }else if(res.data.status === 401){
                putResponse = {
                    status: res.data.status,
                    result: res.data.message
                }
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    result: error.response.status
                }
            }
        });
    return putResponse
}

export default postPatientInsurance;