import axios from "axios";

const doctorFavoritePost = async (baseUrl, token, patientId, doctorId ) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    let body = {
        doctorId: doctorId
    }

    //console.log("body: ", body)

    await axios.post(baseUrl + `/patient/` + patientId + "/fav", body, config)
        .then((res) => {
            //console.log("res.data: ", res.data)
            putResponse = {
                status: res.data.status,
                result: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    result: error.response.status
                }
            }
        });
    return putResponse
}

export default doctorFavoritePost;