import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";

import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";

import { IMaskInput } from "react-imask";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "dayjs/locale/tr";
import dayjs from "dayjs";

import MapissoAlert from "../components/common/MapissoAlert";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import userRegisterNoCode from "../utils/api/patient/UserRegisterNoCode";
import getIlListesi from "../utils/api/common/GetIlListesi";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00)000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class RegisterPageNoCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tcKimlikNo: "",
      adi: "",
      soyadi: "",
      dogumTarihi: new Date(),
      password: "",
      repassword: "",
      phoneNumber: "",
      email: "",
      cityId: 0,
      passwordHelperTitle: "",
      passwordErrorStatus: false,
      repasswordHelperTitle: "",
      repasswordErrorStatus: false,
      repasswordShowStatus: false,
      passwordShowStatus: false,
      checkedKvkkSozlesmesi: true,
      checkedSaglikIzniSozlesmesi: false,
      uyelikSozlesmesiDisplay: false,
      saglikSozlesmesiDisplay: false,
      ilListesi: [],
      permission: false,
      registerSuccess: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.store.isLogin) {
      this.props.store.setLoadingBarStatus(true);
      this.props.history.push("/danisan");
    } else {
      this.props.store.setLoadingBarStatus(false);

      let getIlListesiResponse = await getIlListesi(this.props.store.baseUrl);
      if (getIlListesiResponse !== null && getIlListesiResponse !== undefined) {
        if (getIlListesiResponse.status === 200) {
          this.setState({
            ilListesi: getIlListesiResponse.result,
          });
        }
      }
      this.props.store.setLoadingBarStatus(true);
    }
    const pass = window.prompt("Parola");
    if (pass === "glvx4v") this.setState({ permission: true });
  };

  onKeyPressHandler = (target) => {
    if (target.charCode === 13) {
      this.onLogin();
    }
  };

  onRegisterTcKimlikNoTextFieldChange = (event) => {
    this.setState({
      tcKimlikNo: event.target.value,
    });
  };

  onRegisterAdiTextFieldChange = (event) => {
    this.setState({
      adi: event.target.value,
    });
  };

  onRegisterSoyadiTextFieldChange = (event) => {
    this.setState({
      soyadi: event.target.value,
    });
  };

  onRegisterDogumTarihiChange = (event) => {
    this.setState({
      dogumTarihi: dayjs(event),
    });
  };

  onRegisterEmailTextFieldChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  onRegisterPasswordTextFieldTextChange = (event) => {
    let passwordText = event.target.value;
    if (passwordText.length < 8 && !this.isUpper(passwordText)) {
      this.setState({
        passwordHelperTitle:
          "*Şifreniz en az 8 karakter uzunluğunda olmalıdır. En az 1 tane harf içermelidir.",
        passwordErrorStatus: true,
      });
    } else {
      this.setState({
        passwordHelperTitle: "",
        passwordErrorStatus: false,
      });
    }
    this.setState({
      password: event.target.value,
    });
  };

  onRegisterRePasswordTextFieldTextChange = (event) => {
    let passwordText = event.target.value;
    if (passwordText !== this.state.password) {
      this.setState({
        repasswordHelperTitle: "*Şifreler uyuşmuyor.",
        repasswordErrorStatus: true,
      });
    } else {
      this.setState({
        repasswordHelperTitle: "",
        repasswordErrorStatus: false,
      });
    }
  };

  isUpper(str) {
    return !/[a-z]/.test(str) && /[A-Z]/.test(str);
  }

  handleClickShowRepassword = () => {
    this.setState({
      repasswordShowStatus: true,
    });
  };

  handleMouseDownRepassword = () => {
    this.setState({
      repasswordShowStatus: false,
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      passwordShowStatus: true,
    });
  };

  handleMouseDownPassword = () => {
    this.setState({
      passwordShowStatus: false,
    });
  };

  onRegisterPhoneNumberTextFieldTextChange = (event) => {
    this.setState({
      phoneNumber: event.target.value,
    });
  };

  onChangeSelectIlMenu = (event) => {
    this.setState({
      cityId: event.target.value,
    });
  };

  onClickRegisterButton = async () => {
    this.props.store.setLoadingBarStatus(false);
    // eslint-disable-next-line
    let phoneNumberFixed = this.state.phoneNumber
      .replace(/[\(\)]/g, "")
      .replace("-", "");

    let registerObject = {
      tckno: Number(this.state.tcKimlikNo),
      mobilePhone: Number(phoneNumberFixed),
      email: this.state.email,
      password: this.state.password,
      name: this.state.adi,
      surname: this.state.soyadi,
      birthDate: dayjs(new Date(this.state.dogumTarihi)).add(1, "day").toDate(),
      kullanimKosullariKabul: true,
      kvkkMetniKabul: true,
      cityId: this.state.cityId,
      noCode: true
    };
    if (
      this.state.tcKimlikNo !== "" &&
      this.state.adi !== "" &&
      this.state.soyadi !== "" &&
      this.state.password !== "" &&
      this.state.phoneNumber !== ""
    ) {
      let registerResponse = await userRegisterNoCode(
        this.props.store.baseUrl,
        registerObject
      );
      //console.log("registerResponse: ", registerResponse)
      if (registerResponse !== null && registerResponse !== undefined) {
        if (registerResponse.status === 200) {
          //console.log("registerResponse.data: ", registerResponse.data)
          this.setState({registerSuccess: true})
        } else {
          this.props.store.setMessage(registerResponse.data, true, "error");
        }
      }
    } else {
      this.props.store.setMessage(
        "Lütfen zorunlu alanları doldurunuz.",
        true,
        "error"
      );
    }

    this.props.store.setLoadingBarStatus(true);
  };

  redirectLogin = () => {
    this.props.history.push("/danisan/kullanici-girisi");
  };

  render() {
    if (!this.state.permission) {
      return (
        <h1 style={{ display: "flex", justifyContent: "center" }}>
          Erişim Engellendi
        </h1>
      );
    }
    return (
      <div className="container mps-container bg-image">
        <Grid container style={{ width: "100%" }}>
          <Grid
            item
            xs={5}
            className="height100vh"
            style={{
              padding: "100px",
              background: "#fff",
              minWidth: "500px",
              paddingLeft: "50px",
              paddingTop: "10px",
            }}
          >
            <Grid
              container
              spacing={0}
              style={{ marginTop: "20px", width: "250px", marginLeft: "50px" }}
            >
              <Grid item xs={12}>
                <img
                  src="/assets/images/saglikcim-logo.jpg"
                  alt="wholemedics"
                  style={{
                    width: "100%",
                    marginTop: "0px",
                    marginLeft: "5px",
                    marginBottom: "10px",
                  }}
                />
              </Grid>
            </Grid>
            <Typography
              variant="h5"
              style={{ marginTop: "20px", marginLeft: "50px" }}
            >
              Kayıt Ol
            </Typography>
            <Grid container spacing={1} style={{ paddingLeft: "50px" }}>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="tckno"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                  label="T.C. Kimlik numarası*"
                  onChange={this.onRegisterTcKimlikNoTextFieldChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="adi"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                  label="Adı*"
                  onChange={this.onRegisterAdiTextFieldChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="soyadi"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                  label="Soyadi*"
                  onChange={this.onRegisterSoyadiTextFieldChange}
                />
              </Grid>

              <Grid item xs={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"tr"}
                  style={{ width: "100%" }}
                >
                  <DesktopDatePicker
                    label="Doğum Tarihi*"
                    inputFormat="DD/MM/YYYY"
                    value={this.state.dogumTarihi}
                    onChange={this.onRegisterDogumTarihiChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ marginTop: "10px", width: "100%" }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="email"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                  label="Email*"
                  onChange={this.onRegisterEmailTextFieldChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="password"
                  type={this.state.passwordShowStatus ? "text" : "password"}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                  label="Şifreniz*"
                  onChange={this.onRegisterPasswordTextFieldTextChange}
                  helperText={this.state.passwordHelperTitle}
                  error={this.state.passwordErrorStatus}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.passwordShowStatus ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="repassword"
                  type={this.state.repasswordShowStatus ? "text" : "password"}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                  label="Şifreniz (tekrar)*"
                  onChange={this.onRegisterRePasswordTextFieldTextChange}
                  helperText={this.state.repasswordHelperTitle}
                  error={this.state.repasswordErrorStatus}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowRepassword}
                          onMouseDown={this.handleMouseDownRepassword}
                          edge="end"
                        >
                          {this.state.repasswordShowStatus ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <InputLabel id="city-select-label">İl Bilgisi</InputLabel>
                  <Select
                    labelId="city-select-label"
                    label="İl Bilgisi"
                    value={this.state.cityId}
                    id="city-select"
                    style={{ background: "#fff" }}
                    onChange={this.onChangeSelectIlMenu}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key={"emptyil"} value={0}>
                      İl Seçiniz
                    </MenuItem>
                    {this.state.ilListesi.map((il, idx) => (
                      <MenuItem key={idx} value={il.cityId}>
                        {il.cityName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div style={{ paddingLeft: "50px", marginTop: "10px" }}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                name="phonenumber"
                value={this.state.phoneNumber}
                type="text"
                InputProps={{
                  inputComponent: TextMaskCustom,
                }}
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "10px",
                  marginTop: "10px",
                }}
                label="Cep Telefonu Numarası*"
                onChange={this.onRegisterPhoneNumberTextFieldTextChange}
              />

              <Button
                variant="contained"
                color="primary"
                onClick={this.onClickRegisterButton}
                style={{
                  width: "100%",
                  height: "50px",
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                KAYIT OL
              </Button>
              <LinearProgress
                style={{
                  display: this.props.store.loadingBarStatus ? "none" : "block",
                  width: "calc(100% - 18px)",
                  marginLeft: "9px",
                }}
              />
            </div>
          </Grid>
        </Grid>
        <MapissoAlert />
        <Dialog open={this.state.registerSuccess}>
          <DialogTitle id="alert-dialog-title">
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              Kayıt Başarılı!
            </Typography>
          </DialogTitle>
          <DialogActions>
            <Button variant="contained" onClick={this.redirectLogin}>
              Tamam
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default inject("store")(observer(RegisterPageNoCode));
