import axios from "axios";

const getLanguageListesi = async (baseUrl, token) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + `/Common/language`, config)
        .then((res) => {
            getResponse = {
                status: res.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    result:  error.response.status
                }
            }
        });
    return getResponse
}

export default getLanguageListesi;