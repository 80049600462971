import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Link } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";

import MapissoAlert from "../components/common/MapissoAlert";
import { CountDown } from "../components/common/CountDown";

import userSmsCodeVerify from "../utils/api/patient/UserSmsCodeVerify";
import userSmsSendAgain from "../utils/api/patient/UserSmsSendAgain";

class ActivationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smsCode: "",
      tcKimlikNo: "",
      password: "",
      repassword: "",
      passwordHelperTitle: "",
      passwordErrorStatus: false,
      repasswordHelperTitle: "",
      repasswordErrorStatus: false,
      repasswordShowStatus: false,
      passwordShowStatus: false,
      registerSuccess: { register: false, id: 0 },
    };
  }

  componentDidMount = async () => {
    if (this.props.store.isLogin) {
      this.props.store.setLoadingBarStatus(true);
      this.props.history.push("/danisan");
    }
  };

  onSmsCodeValidate = async () => {
    this.props.store.setLoadingBarStatus(false);

    if (this.state.smsCode !== "") {
      let response = await userSmsCodeVerify(
        this.props.store.baseUrl,
        this.props.store.patientId,
        this.state.smsCode
      );

      if (response != null) {
        if (response.status === 200) {
          this.props.store.setLoadingBarStatus(true);
          const id = response.data.data.data;
          this.setState({
            registerSuccess: { register: true, id: id ?? 0 },
          });
        } else {
          this.props.store.setToken("");
          this.props.store.setMessage(response.data, true, "error");
        }
      } else {
        this.props.store.setMessage(
          "Servis altyapısında problem oluştu",
          true,
          "error"
        );
      }
    } else {
      this.props.store.setMessage(
        "SMS kodunu girmeniz gerekmektedir..",
        true,
        "error"
      );
    }
    this.props.store.setLoadingBarStatus(true);
  };

  redirectLogin = () => {
    this.props.history.push("/danisan/kullanici-girisi");
  };

  onRegisterVerifyCodeChange = (event) => {
    this.setState({
      smsCode: event.target.value,
    });
  };

  userSmsSendAgainRequest = async () => {
    this.props.store.setLoadingBarStatus(false);

    let createNewSmsCode = await userSmsSendAgain(
      this.props.store.baseUrl,
      this.props.store.patientId
    );
    if (createNewSmsCode !== null && createNewSmsCode !== undefined) {
      if (createNewSmsCode.status === 200) {
        if (createNewSmsCode?.data?.data) {
          this.setState({
            smsCode: createNewSmsCode.data.data,
          });
        }
        this.props.store.setMessage(
          "Yeni kod gönderimi başarıyla tamamlandı.",
          true,
          "success"
        );
      } else {
        this.props.store.setMessage(
          "Yeni kod gönderilirken hata oluştu.",
          true,
          "error"
        );
      }
    } else {
      this.props.store.setMessage(
        "Yeni kod gönderilirken hata oluştu.",
        true,
        "error"
      );
    }
    this.props.store.setLoadingBarStatus(true);
  };

  render() {
    return (
      <div className="container mps-container bg-image">
        <Grid container style={{ width: "100%" }}>
          <Grid
            item
            xs={4}
            className="height100vh"
            style={{
              padding: "100px",
              background: "#fff",
              minWidth: "500px",
              paddingLeft: "50px",
              paddingTop: "50px",
            }}
          >
            <Grid
              container
              spacing={0}
              style={{ marginTop: "20px", width: "250px", marginLeft: "50px" }}
            >
              <Grid item xs={12}>
                <img
                  src="/assets/images/saglikcim-logo.jpg"
                  alt="wholemedics"
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginLeft: "5px",
                    marginBottom: "10px",
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ paddingLeft: "50px" }}>
              <Typography style={{ marginTop: "50px", fontWeight: "bold" }}>
                Aktivasyon
              </Typography>
              <Typography variant="body1">
                Cep telefonu numaranıza gönderilen sms kodunu giriniz.
              </Typography>
              <TextField
                id="outlined-basic"
                variant="outlined"
                name="tckno"
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "10px",
                  marginTop: "10px",
                }}
                value={this.state.smsCode}
                label="Aktivasyon Kodu*"
                onChange={this.onRegisterVerifyCodeChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.userSmsSendAgainRequest}
                      >
                        Tekrar Gönder
                      </Button>
                    </InputAdornment>
                  ),
                }}
                autoComplete="one-time-code"
              />

              <CountDown />

              <Button
                variant="contained"
                color="primary"
                onClick={this.onSmsCodeValidate}
                style={{
                  width: "100%",
                  height: "50px",
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                AKTİVE ET
              </Button>
              <LinearProgress
                style={{
                  display: this.props.store.loadingBarStatus ? "none" : "block",
                  width: "calc(100% - 18px)",
                  marginLeft: "9px",
                }}
              />
            </div>
            <div
              style={{
                paddingLeft: "50px",
                width: "100%",
                marginTop: "25px",
                textAlign: "center",
              }}
            >
              <Typography variant="body2">Sağlıkçım'a üye misin?</Typography>
              <Button
                variant="outlined"
                color="primary"
                to={"/danisan/login"}
                component={Link}
                target="_blank"
                style={{
                  width: "100%",
                  height: "50px",
                  fontWeight: "600",
                  color: "#000",
                }}
              >
                Giriş Yap
              </Button>

              <img
                style={{
                  position: "absolute",
                  left: "150px",
                  bottom: "20px",
                  width: "200px",
                  marginTop: "30px",
                }}
                alt="wholemedics"
                src="/assets/images/wholemedic-logo.png"
              ></img>
            </div>
          </Grid>
        </Grid>
        <MapissoAlert />
        <Dialog open={this.state.registerSuccess.register}>
          <DialogTitle id="alert-dialog-title">
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              Kayıt Başarılı!
            </Typography>
          </DialogTitle>
          <DialogContent>
            Bilgilerinizi kullanarak <br />
            <a href="https://uygulama.saglikcim.net/" target="_blank">
              <img
                src="/assets/images/saglikcim-logo.jpg"
                style={{
                  height: "40px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></img>
            </a>
            <br />
            adresinde kullanıcı girişi yapabilirsiniz. <br />
            {this.state.registerSuccess.id > 0 && (
              <>
                Eczanem'e giriş şifreniz mail adresiniz ile aynıdır. Şifrenizi
                değiştirmek için resme tıklayın:
                <br />
                <a href="https://eczanem.saglikcim.net/" target="_blank">
                  <img
                    src="/assets/images/eczanem.png"
                    style={{
                      height: "50px",
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  ></img>
                </a>{" "}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.redirectLogin}>
              Tamam
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default inject("store")(observer(ActivationPage));
