import axios from "axios";

const postAppointmentChat = async (baseUrl, token, patientId, appointmentId, chat) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    await axios.post(baseUrl + "/patient/" + patientId + "/appointment/" + appointmentId + "/chat", chat, config)
        .then((res) => {
            //console.log("res.data: ", res.data)
            putResponse = {
                status: res.data.status,
                data: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return putResponse
}

export default postAppointmentChat;