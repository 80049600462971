import { types } from "mobx-state-tree"

export const Patient = types
    .model({
        id: types.maybeNull(types.optional(types.number, 0)),
        cityId: types.maybeNull(types.optional(types.number, 0)),
        districtId: types.maybeNull(types.optional(types.number, 0)),
        neighbourhoodId: types.maybeNull(types.optional(types.number, 0)),
        cityName: types.maybeNull(types.optional(types.string, "")),
        districtName: types.maybeNull(types.optional(types.string, "")),
        neighbourhoodName: types.maybeNull(types.optional(types.string, "")),
        address: types.maybeNull(types.optional(types.string, "")),
        gender: types.maybeNull(types.optional(types.number, 0)),
        height: types.maybeNull(types.optional(types.number, 0)),
        weight: types.maybeNull(types.optional(types.number, 0)),
        professional: types.maybeNull(types.optional(types.string, "")),
        bloodGroup: types.maybeNull(types.optional(types.number, 0)),
        name: types.maybeNull(types.optional(types.string, "")),
        surname: types.maybeNull(types.optional(types.string, "")),
        email: types.maybeNull(types.optional(types.string, "")),
        tckno: types.maybeNull(types.optional(types.number, 0)),
        mobilePhone: types.maybeNull(types.optional(types.number, 0)),
        isActive: types.maybeNull(types.optional(types.boolean, true)),
        isApproved: types.maybeNull(types.optional(types.boolean, true)),
        avatar: types.maybeNull(types.optional(types.string, "")),
        userType: types.maybeNull(types.optional(types.number, 0)),
        birthDate: types.maybeNull(types.optional(types.Date, new Date()))
    }).actions(self => ({

    }))