import { Insurance } from '../../../../model/Patient/Insurance'

const parsePatientInsuranceData = (inputInsurance) => {

    let insurance = null
    try {

        insurance = Insurance.create({
            insuranceId: (inputInsurance !== null) ? inputInsurance.insuranceId: 0,
            patientId: (inputInsurance !== null) ? inputInsurance.patientId: 0,
            firmName: (inputInsurance !== null) ? inputInsurance.firmName: "",
            policyNo: (inputInsurance !== null) ? inputInsurance.policyNo: "",
            customerNo: (inputInsurance !== null) ? inputInsurance.customerNo: "",
            startDate: (inputInsurance !== null) ? new Date(inputInsurance.startDate): new Date(),
            endDate: (inputInsurance !== null) ? new Date(inputInsurance.endDate): new Date()

        })
        
    } catch (error) {
        console.log("error: ", error)
    }
    
    return insurance;
} 

export default parsePatientInsuranceData;