import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import { Grid } from '@mui/material';
import { ReceteMenu } from '../components/danisan/tibbibelgeler/ReceteMenu';

class RecetePage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dashboardStatics: null,
            todayAppointmentList: [],
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.store.setUserLogout()
            this.props.history.push("/danisan/kullanici-girisi");
        } 
    }

    onLogout = () => {
        this.props.store.setUserLogout()
        window.location.reload()
    }

    onHandleProfileMenuOpen = (event) => {
        //setProfileMenuDisplay(true)
        //setProfileMenuTarget(event.currentTarget)
        this.setState({
            profileMenuDisplay: true,
            profileMenuTarget: event.currentTarget
        })
    }

    onClickProfileMenuClose = () => {
        //setProfileMenuDisplay(false)
        //setProfileMenuTarget(null)
        this.setState({
            profileMenuDisplay: false,
            profileMenuTarget: null
        })
    }

    render() {
        return (
            <div className="container mps-container">
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item lg={12} sm={12} xl={12} xs={12} >
                        <ReceteMenu receteId={parseInt(this.props.match.params.id)} />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default inject("store")(observer(RecetePage));
