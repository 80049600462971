import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardActions, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MapissoAlert from '../components/common/MapissoAlert';

import { Patient } from '../model/Patient/Patient';
import { Insurance } from '../model/Patient/Insurance';

import getIlListesi from '../utils/api/common/GetIlListesi'
import getIlceListesi from '../utils/api/common/GetIlceListesi'
import getMahalleListesi from '../utils/api/common/GetMahalleListesi'
import putPatientInfo from '../utils/api/patient/PutPatient'
import postPatientInsurance from '../utils/api/patient/Insurance/PostPatientInsurance';

const steps = ['Temel Bilgiler', 'Fiziksel Özellikler', 'Özel Sigorta Bilgileri'];

const sigortaSirketListesi = [
    "Acnturk Sigorta AŞ",
    "AKSigorta AŞ",
    "Allianz Sigorta AŞ",
    "Ana Sigorta Anonim Şirketi",
    "Anadolu Anonim Türk Sigorta Şirketi",
    "Ankara Anonim Türk Sigorta Şirketi",
    "Arex Sigorta AŞ",
    "S.S. Atlas Sigorta Kooperatifi",
    "Aveon Global Sigorta AŞ",
    "Axa Sigorta AŞ",
    "Bereket Sigorta AŞ",
    "Bereket Katılım Hayat AŞ",
    "Bereket Katılım Sigorta AŞ",
    "BNP Paribas Cardif Hayat AŞ",
    "BNP Paribas Cardif Sigorta AŞ",
    "BUPA Acıbadem Sigorta AŞ",
    "Coface Sigorta AŞ",
    "Corpus Sigorta AŞ",
    "Demir Sağlık ve Hayat Sigorta AŞ",
    "Doga Sigorta AŞ",
    "Dubai Sigorta AŞ",
    "Emaa Sigorta AŞ",
    "Ethica Sigorta Anonim Şirketi",
    "Euler Hermes Sigorta AŞ",
    "Eureko Sigorta AŞ",
    "Generali Sigorta AŞ",
    "GRI Sigorta AŞ",
    "Groupama Hayat AŞ",
    "Groupama Sigorta AŞ",
    "Gulf Sigorta AŞ",
    "HDI Sigorta AŞ",
    "Hepiyi Sigorta AŞ",
    "Koru Sigorta AŞ",
    "Magdeburger Sigorta AŞ",
    "Mapfre Sigorta AŞ",
    "Mapfre Yaşam Sigorta AŞ",
    "Neova Katılım Sigorta AŞ",
    "Orient Sigorta AŞ",
    "Quick Sigorta AŞ",
    "Prive Sigorta AŞ",
    "Ray Sigorta Anonim Şirketi",
    "Sompo Sigorta AŞ",
    "Şeker Sigorta AŞ",
    "Türk Nippon Sigorta AŞ",
    "Türk P ve I Sigorta AŞ",
    "Türkiye Sigorta AŞ",
    "Unico Sigorta AŞ",
    "Zurich Sigorta AŞ"
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const RegisterDetailInfoPage = inject("store")(observer((props) => {

    const [activeStep, setActiveStep] = useState(0);
    const [selectedPatient, setSelectedPatient] = useState(Patient.create({}))

    const [ilListesi, setIlListesi] = useState([])
    const [ilceListesi, setIlceListesi] = useState([])
    const [mahalleListesi, setMahalleListesi] = useState([])

    const [selectedCinsiyet, setSelectedCinsiyet] = useState(0)

    const [temelBilgilerDisplay, setTemelBilgilerDisplay] = useState(true)
    const [fizikselOzelliklerDisplay, setFizikselOzelliklerDisplay] = useState(false)
    const [sigortaSirketBilgiDisplay, setSigortaSirketBilgiDisplay] = useState(false)

    const [patientInsurance, setPatientInsurance] = useState(Insurance.create({}))


    useEffect(() => {
        // Update the document title using the browser API
        getIlListesiReq()
        if (props.store.patient[0] !== undefined) {
            setSelectedPatient(props.store.patient[0])
        }
        // eslint-disable-next-line
    }, []);

    const getIlListesiReq = async () => {
        let ilListesiResponse = await getIlListesi(props.store.baseUrl, props.store.token)
        console.log("ilListesiResponse: ", ilListesiResponse)
        if (ilListesiResponse !== null && ilListesiResponse !== undefined) {
            if (ilListesiResponse.status === 200) {
                setIlListesi(ilListesiResponse.result)
            }else if(ilListesiResponse.status === 401){
                this.props.store.setUserLogout()
                window.location.reload()
            }
        }
    }

    const getIlceListesiReq = async (cityId) => {
        let ilceListesiResponse = await getIlceListesi(props.store.baseUrl, props.store.token, cityId)
        console.log("ilceListesiResponse: ", ilceListesiResponse)
        if (ilceListesiResponse !== null && ilceListesiResponse !== undefined) {
            if (ilceListesiResponse.status === 200) {
                setIlceListesi(ilceListesiResponse.result)
            }
        }
    }

    const getMahalleListesiReq = async (districtId) => {
        let mahalleListesiResponse = await getMahalleListesi(props.store.baseUrl, props.store.token, districtId)
        console.log("mahalleListesiResponse: ", mahalleListesiResponse)
        if (mahalleListesiResponse !== null && mahalleListesiResponse !== undefined) {
            if (mahalleListesiResponse.status === 200) {
                setMahalleListesi(mahalleListesiResponse.result)
            }
        }
    }

    const onChangeSelectIlMenu = async (event) => {
        console.log("event.target.name: ", event.target.name)
        setSelectedPatient({ ...selectedPatient, cityId: event.target.value })
        await getIlceListesiReq(event.target.value)
    }

    const onChangeSelectIlceMenu = async (event) => {
        await getMahalleListesiReq(event.target.value)
        setSelectedPatient({ ...selectedPatient, districtId: event.target.value })

    }

    const onChangeSelectMahalleMenu = (event) => {
        setSelectedPatient({ ...selectedPatient, neighbourhoodId: event.target.value })
    }

    const onClickIleri = (stepName) => {
        if (stepName === "temelbilgiler") {
            //if (selectedIl !== 0) {
            setActiveStep(1)
            setTemelBilgilerDisplay(false)
            setFizikselOzelliklerDisplay(true)
            setSigortaSirketBilgiDisplay(false)
            /*} else {
                props.store.setMessage("Bu aşamayı geçebilmek için bulunduğunuz ili seçmeniz gerekmektedir.", true, "error")
            }*/
        } else if (stepName === "fizikselOzellikler") {
            setActiveStep(2)
            setTemelBilgilerDisplay(false)
            setFizikselOzelliklerDisplay(false)
            setSigortaSirketBilgiDisplay(true)
        }
    }

    const onClickGeri = (stepName) => {
        if (stepName === "fizikselOzellikler") {
            setActiveStep(0)
            setTemelBilgilerDisplay(true)
            setFizikselOzelliklerDisplay(false)
            setSigortaSirketBilgiDisplay(false)
        } else if (stepName === "sigortaBilgileri") {
            setActiveStep(1)
            setTemelBilgilerDisplay(false)
            setFizikselOzelliklerDisplay(true)
            setSigortaSirketBilgiDisplay(false)
        }
    }

    const onChangeSigortaSirketSelectMenu = (event) => {
        setPatientInsurance({ ...patientInsurance, firmName: event.target.value })
    }

    const onChangeSigortaTextInput = (event) => {
        setPatientInsurance({ ...patientInsurance, [event.target.name]: event.target.value })
    }

    const onChangePatientTextField = (event) => {
        setSelectedPatient({ ...selectedPatient, [event.target.name]: event.target.value })
    }

    const onChangeCinsiyetMenu = (event) => {
        setSelectedCinsiyet(event.target.value)
    }

    const onClickSaveButton = async () => {
        props.store.setLoadingBarStatus(false)

        let updatePatientResponse = await putPatientInfo(props.store.baseUrl, props.store.token, props.store.patient[0].id, selectedPatient)
        if (updatePatientResponse !== null && updatePatientResponse !== undefined) {
            if (updatePatientResponse.status === 200) {
                props.store.setMessage("Danışan bilgileri başarıyla güncellendi. ", true, "success")
            } else {
                props.store.setMessage("Danışan bilgileri güncellenirken hata oluştu. ", true, "error")
            }
        } else {
            props.store.setMessage("Danışan Bilgileri güncellenirken hata oluştu. ", true, "error")
        }

        let postPatientInsuranceResponse = await postPatientInsurance(props.store.baseUrl, props.store.token, props.store.patient[0].id, patientInsurance)
        if (postPatientInsuranceResponse !== null && postPatientInsuranceResponse !== undefined) {
            if (postPatientInsuranceResponse.status === 200) {
                props.store.setMessage("Danışan sigorta bilgileri başarıyla kaydedildi. ", true, "success")
            } else {
                props.store.setMessage("Danışan sigorta bilgileri kaydedilirken hata oluştu. ", true, "error")
            }
        } else {
            props.store.setMessage("Danışan sigorta bilgileri kaydedilirken hata oluştu. ", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    return (
        <div className="register-info-details-image">
            <div>
                <Grid container spacing={0} >
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={8} >
                        <Box sx={{ width: '100%', marginTop: "10vh" }}>
                            <Card style={{ height: "calc(100vh- 200px)", maxHeight: "calc(100vh- 200px)", minHeight: "calc(100vh- 200px)", padding: "50px" }}>
                                <img src='/assets/images/saglikcim-logo.jpg' alt="wholemedics" style={{ width: "250px", marginBottom: "30px" }} />
                                <Stepper activeStep={activeStep}>
                                    {steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepButton color="inherit">
                                                {label}
                                            </StepButton>
                                        </Step>
                                    ))}
                                </Stepper>

                                <CardContent hidden={!temelBilgilerDisplay}>
                                    <Typography style={{ fontWeight: "bold", marginBottom: "20px" }}>İletişim Bilgileri</Typography>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField variant='outlined' label="E-posta Bilgisi" style={{ width: "100%" }}
                                                    value={selectedPatient.email} onChange={onChangePatientTextField}></TextField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="city-select-label">İl Bilgisi</InputLabel>
                                                <Select labelId="city-select-label" label="İl Bilgisi"
                                                    value={selectedPatient.cityId} id="city-select" style={{ background: "#fff" }}
                                                    onChange={onChangeSelectIlMenu} MenuProps={MenuProps}>
                                                    <MenuItem key={"emptyil"} value={0}>İl Seçiniz</MenuItem>
                                                    {ilListesi.map((il, idx) => (
                                                        <MenuItem key={idx} value={il.cityId}>{il.cityName}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="city-select-label">İlçe Bilgisi</InputLabel>
                                                <Select labelId="city-select-label" label="İlçe Bilgisi"
                                                    value={selectedPatient.districtId} id="city-select" style={{ background: "#fff" }}
                                                    onChange={onChangeSelectIlceMenu} MenuProps={MenuProps}>
                                                    <MenuItem key={"emptyilce"} value={0}>İlçe Seçiniz</MenuItem>
                                                    {ilceListesi.map((ilce, idx) => (
                                                        <MenuItem key={idx} value={ilce.districtId}>{ilce.districtName}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="city-select-label">Mahalle Bilgisi</InputLabel>
                                                <Select labelId="city-select-label" label="Mahalle Bilgisi"
                                                    value={selectedPatient.neighbourhoodId} id="city-select" style={{ background: "#fff" }}
                                                    onChange={onChangeSelectMahalleMenu} MenuProps={MenuProps}>
                                                    <MenuItem key={"emptymahalle"} value={0}>Mahalle Seçiniz</MenuItem>
                                                    {mahalleListesi.map((mahalle, idx) => (
                                                        <MenuItem key={idx} value={mahalle.neighbourhoodId}>{mahalle.neighbourhoodName}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <TextField variant='outlined' label="Adres Bilgisi" style={{ width: "100%" }} multiple rows={5}
                                                    value={selectedPatient.address}
                                                    onChange={onChangePatientTextField}></TextField>
                                            </FormControl>

                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardContent hidden={!fizikselOzelliklerDisplay}>
                                    <Typography style={{ fontWeight: "bold", marginBottom: "20px" }}>Fiziksel Özellikler</Typography>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="gender-select-label">Cinsiyet Bilgisi</InputLabel>
                                                <Select labelId="gender-select-label" label="Cinsiyet Bilgisi"
                                                    value={selectedCinsiyet} id="gender-select" style={{ background: "#fff" }}
                                                    onChange={onChangeCinsiyetMenu} MenuProps={MenuProps}>
                                                    <MenuItem key={"default"} value={0}>Belirtmek istemiyorum</MenuItem>
                                                    <MenuItem key={"erkek"} value={1}>Erkek</MenuItem>
                                                    <MenuItem key={"kadin"} value={2}>Kadın</MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField name="height" variant='outlined' type="number" label="Boy(cm)" style={{ width: "100%" }}
                                                value={selectedPatient.height}
                                                onChange={onChangePatientTextField}></TextField>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField name="weight" variant='outlined' type="number" label="Ağırlık(kg)" style={{ width: "100%" }}
                                                value={selectedPatient.weight}
                                                onChange={onChangePatientTextField}></TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth style={{ width: "100%" }}>
                                                <InputLabel id="demo-simple-select-label">Kan Grubu</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={selectedPatient.bloodGroup}
                                                    onChange={(event) => setSelectedPatient({ ...selectedPatient, bloodGroup: event.target.value })}
                                                    label="Kan Grubu" MenuProps={MenuProps}>
                                                    <MenuItem value={0}>Bilmiyorum</MenuItem>
                                                    <MenuItem value={1}>A RH +</MenuItem>
                                                    <MenuItem value={2}>A RH -</MenuItem>
                                                    <MenuItem value={3}>B RH +</MenuItem>
                                                    <MenuItem value={4}>B RH -</MenuItem>
                                                    <MenuItem value={5}>AB RH +</MenuItem>
                                                    <MenuItem value={6}>AB RH -</MenuItem>
                                                    <MenuItem value={7}>0 RH +</MenuItem>
                                                    <MenuItem value={8}>0 RH -</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardContent hidden={!sigortaSirketBilgiDisplay}>
                                    <Typography style={{ fontWeight: "bold", marginBottom: "20px" }}>Özel Sağlık Sigorta Poliçe Bilgileri</Typography>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="city-select-label">Sigorta Şirket Bilgisi</InputLabel>
                                                <Select labelId="city-select-label" label="Sigorta Şirket Bilgisi"
                                                    value={patientInsurance.firmName} id="city-select" style={{ background: "#fff" }}
                                                    onChange={onChangeSigortaSirketSelectMenu} MenuProps={MenuProps}>
                                                    <MenuItem key={"default"} value={""}>Sigorta Şirketi Seçiniz</MenuItem>
                                                    {sigortaSirketListesi.map((sigortaSirket, idx) => (
                                                        <MenuItem key={idx} value={sigortaSirket}>{sigortaSirket}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField name="policyNo" variant='outlined' label="Poliçe No" style={{ width: "100%" }}
                                                value={patientInsurance.policyNo} onChange={onChangeSigortaTextInput}></TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField name="customerNo" variant='outlined' label="Müşteri No" style={{ width: "100%" }}
                                                value={patientInsurance.customerNo} onChange={onChangeSigortaTextInput}></TextField>
                                        </Grid>
                                    </Grid>
                                </CardContent>

                                {
                                    (temelBilgilerDisplay) ?
                                        <CardActions>
                                            <Button color="primary" variant='contained' style={{ marginLeft: "15px" }}
                                                disabled={(activeStep === 0) ? true : false}
                                                onClick={() => onClickGeri("temelbilgiler")}>
                                                Geri
                                            </Button>
                                            <Button color="primary" variant='contained' style={{ marginLeft: "auto", marginRight: "15px" }}
                                                onClick={() => onClickIleri("temelbilgiler")}>
                                                İleri
                                            </Button>
                                        </CardActions> : (fizikselOzelliklerDisplay) ?
                                            <CardActions>
                                                <Button color="primary" variant='contained' style={{ marginLeft: "15px" }}
                                                    disabled={(activeStep === 0) ? true : false}
                                                    onClick={() => onClickGeri("fizikselOzellikler")}>
                                                    Geri
                                                </Button>
                                                <Button color="primary" variant='contained' style={{ marginLeft: "auto", marginRight: "15px" }}
                                                    onClick={() => onClickIleri("fizikselOzellikler")}>
                                                    İleri
                                                </Button>
                                            </CardActions> :
                                            <CardActions>
                                                <Button color="primary" variant='contained' style={{ marginLeft: "15px" }}
                                                    disabled={(activeStep === 0) ? true : false}
                                                    onClick={() => onClickGeri("sigortaBilgileri")}>
                                                    Geri
                                                </Button>
                                                <Button color="primary" variant='contained' style={{ marginLeft: "auto", marginRight: "15px" }}
                                                    onClick={() => onClickSaveButton()}>
                                                    Kaydet ve Devam Et
                                                </Button>
                                            </CardActions>
                                }
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                </Grid>
            </div>

            <MapissoAlert />
        </div>

    );
}))