import axios from "axios";

const getPatientDoctorAppointmentList = async (baseUrl, token, patientId, doctorId, startDate, endDate) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + "/patient/" + patientId + "/appointment/" + doctorId + "/?startdate=" + startDate + "&enddate=" + endDate, config)
        .then((res) => {
            //console.log("res.data: ", res.data)
            getResponse = {
                status: res.data.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    result: error.response.status
                }
            }
        });
    return getResponse
}

export default getPatientDoctorAppointmentList;