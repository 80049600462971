import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import { LeftMenu } from '../components/common/LeftMenu';

import { Box, Card, Container, Grid } from '@mui/material';
import CardContent from '@mui/material/CardContent';

import { ProfileButtonMenu } from '../components/common/ProfileButtonMenu';
import { TopSearchMenu } from '../components/danisan/search/TopSearchMenu';
import { Footer } from '../components/common/Footer';

import { RandevuBilgiMenu } from '../components/danisan/randevularim/RandevuBilgiMenu';


class RandevuBilgiPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dashboardStatics: null,
            passedAppointmentList: [],
            futureAppointmentList: [],
            type: null
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/danisan/kullanici-girisi");
        } 
    }


    render() {

    console.log("this.props.match.params.doctorid: ", this.props.match.params.doctorid)

    
        return (
            <div className="container mps-container">
                <LeftMenu activePage="Randevularım" />
                <Box component="main" sx={{
                    width: { xs: "calc(100% - 90px)", md: "calc(100% - 235px)" }, marginLeft: { xs: "90px", md: "235px" },
                    flexGrow: 1,
                    py: 8
                }} style={{ maxHeight: "100vh", paddingTop: "10px" }} >
                    <Container style={{ padding: "0" }} maxWidth="100%">
                        <Grid container spacing={0} style={{ marginBottom: "0px", flexWrap: "wrap-reverse" }}>
                            <Grid item lg={8} sm={6} xl={8} xs={12}>
                                <TopSearchMenu history={this.props.history} />
                            </Grid>
                            <Grid item lg={4} sm={6} xl={4} xs={12}>
                                <ProfileButtonMenu />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} style={{ padding: "30px" }}>
                            <Grid item lg={12} sm={12} xl={12} xs={12} >
                                <Card>
                                    <CardContent>
                                        <RandevuBilgiMenu doctorId={parseInt(this.props.match.params.doctorid)} appointmentId={parseInt(this.props.match.params.id)} 
                                        history={this.props.history}/>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                    <Footer />
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(RandevuBilgiPage));
